import { HTMLAttributes } from 'react';
import './getIcon.css';
import { ICONS } from '../../../assets/BankIconsData';
/**
 * Propiedades del componente AppGetIcon.
 * 
 * @property {string} name - El nombre del icono que se quiere mostrar.
 * @property {string} [classIcon] - La clase CSS adicional para el contenedor del icono.
 * @property {string} [width] - El ancho del icono (por defecto es "100%").
 * @property {string} [height] - La altura del icono (por defecto es "100%").
 * @property {string} [fillColor] - El color de relleno del icono (por defecto es "none").
 * @property {string} [strokeColor] - El color de borde del icono (por defecto es "none").
 * @property {object} [restProps] - Las propiedades adicionales para el contenedor del icono.
 */
type AppGetIconProps = {
  name: string;
  classIcon?: string;
  width?: string;
  height?: string;
  fillColor?: string;
  strokeColor?: string;
  hoverColor?: string; // Nuevo prop para el color de hover
} & HTMLAttributes<HTMLDivElement>;

/**
 * Componente que muestra un icono SVG.
 * 
 * @param {AppGetIconProps} props - Las propiedades del componente.
 * @returns {JSX.Element} - El componente.
 */
const AppGetIcon: React.FC<AppGetIconProps> = ({ name, classIcon , width = '100%', height = '100%', fillColor = 'none', strokeColor = 'none', hoverColor = '', ...restProps }) => {

  /**
   * Obtiene el código SVG del icono.
   * 
   * @returns {string} - El código SVG del icono.
   */
  const getIcon = (): string => {
    const icon = ICONS[name];
    if (!icon) {
      console.error(`Icon "${name}" not found in ICONS object.`);
      return '';
    }
    // Replace fill, stroke, width, and height values in SVG string with props.
    let svgString = icon.replace(/width="\d+"/, `width="${width}"`).replace(/height="\d+"/, `height="${height}"`);

    if (hoverColor ) {
      svgString = svgString.replace(/fill="[^"]+"/g, `fill="${hoverColor}"`);
      svgString = svgString.replace(/stroke="[^"]+"/g, `stroke="${hoverColor}"`);

    } else if (fillColor !== 'none') {
      svgString = svgString.replace(/fill="[^"]+"/g, `fill="${fillColor}"`);
      svgString = svgString.replace(/stroke="[^"]+"/g, `fill="${strokeColor}"`);
    }

    if (strokeColor !== 'none' && !hoverColor ) {
      svgString = svgString.replace(/stroke="[^"]+"/g, `stroke="${strokeColor}"`);
    }

    return svgString;
  };

  return (
    <div className={`containerIcon  ${classIcon}`} {...restProps} dangerouslySetInnerHTML={{ __html: getIcon() }} />
  );
};

export default AppGetIcon;