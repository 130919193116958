import React from 'react';
import { toast } from 'react-toastify'; // Asegúrate de importar los tipos correctos
import AppGetIcon from '../AppGetIcon';

// Tipos para los props
interface ToastNotificationProps {
  type?: 'error' | 'success' | 'warning' | 'info'; // El tipo de alerta
  iconName?: string; // Nombre del ícono para AppGetIcon
  message: string; // El texto del mensaje
}

const ToastNotification: React.FC<ToastNotificationProps> = ({ type = 'error', iconName, message }) => {
  // Mapear el tipo de alerta con el nombre del ícono predeterminado si no se proporciona uno
  const iconMap = {
    error: 'toastError',
    success: 'toastSuccess',
    warning: 'toastWarning',
    info: 'toastInfo',
  };

  const resolvedIconName = iconName || iconMap[type]; // Usar el ícono por defecto según el tipo

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
      <AppGetIcon name={resolvedIconName} style={{ width: '20px', height: '20px', padding: '0px' }} /> {/* Tamaño del ícono ajustado */}
      <span style={{ fontSize: '14px', lineHeight: '16px' }}>{message}</span> {/* Ajustar el tamaño del texto */}
    </div>
  );
};

export default ToastNotification;
// Función para mostrar el toast
export const showToast = (message: string, type: 'error' | 'success' | 'warning' | 'info' = 'error', iconName?: string) => {
  toast(<ToastNotification type={type} iconName={iconName} message={message} />, {
    icon: false, // Para ocultar el ícono por defecto
    type: type,
  });
};
