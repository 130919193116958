import { createWithEqualityFn } from 'zustand/traditional';

import { persist } from "zustand/middleware";
interface DataPaginate {
  data: any[];
  page?: number;
  perPage?: number;
  total?: number;
}
interface dataTableInterface {
  dataTable: DataPaginate,
  selectedAll: boolean,
  selectedData: any[]; // Nuevo estado para almacenar los datos seleccionados
  updateSelectedAll: (newSelectedAll: boolean) => void;
  updateDataTable: (newTableData: DataPaginate) => void;
  updateSelectedData: (newSelectedData: any[]) => void; // Función para actualizar los datos seleccionados
  clearStore: () => void;
}

export const useDataTableStore = createWithEqualityFn<dataTableInterface>()(

  persist(
    (set) => ({
      dataTable: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
      },
      selectedAll: false,
      selectedData: [], // Inicializamos la lista de datos seleccionados como vacía

      updateDataTable: (newTableData: DataPaginate) => set({ dataTable: newTableData }),
      updateSelectedAll: (newSelectedAll: boolean) => set({ selectedAll: newSelectedAll }),
      updateSelectedData: (newSelectedData: any[]) => set({ selectedData: newSelectedData }), // Función para actualizar los datos seleccionados

      clearStore: () => {
        set({}, true);
      },
    }),
    {
      name: "dataTable",
      getStorage: () => localStorage,
    }
  )
);