import React, { memo, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'; // Importa ReactDOM
import { Tooltip } from 'react-tooltip';
import AppGetIcon from "../../AppGetIcon";
import useDebounce from "../../../hooks/useDebounce";
import { DataSelectDto, InputComponentProps } from "../../../dtos/general.dto";
import style from './inputSearch.module.css';

const InputComponent: React.FC<InputComponentProps & { data?: DataSelectDto[] }> = ({
  textLabel,
  serviceCall,
  onSelect,
  selected,
  required = false,
  onValid,
  formControl,
  isReload,
  errorMessage,
  data = [],
  classNameInput,
  icon,
  tooltipText,
  completedValue = false,
  disabled
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [inputValue, setInputValue] = useState(selected?.label || '');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [selectValue, setSelectValue] = useState<{ label: string; value: string; }[] | any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRequired, setIsRequired] = useState(required);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (isExpanded) {
      if (data && data.length > 0) {
        const filteredData = data.filter((item: any) => item.label.toLowerCase().includes(debouncedInputValue.toLowerCase()));
        setSelectValue(filteredData);
        setIsLoading(false);
      }
      if (serviceCall && data.filter((item: any) => item.label.toLowerCase().includes(debouncedInputValue.toLowerCase())).length === 0) {
        setIsLoading(true);
        serviceCall(debouncedInputValue)
          .then((data: any) => {
            setSelectValue(data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue, isExpanded]);

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (
        selectRef.current && !selectRef.current.contains(event.target as Node) &&
        inputRef.current && !inputRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("scroll", handleOutsideClick, true); // Asegúrate de que 'true' se usa para capturar el evento
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleOutsideClick, true);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleOutsideClick, true);
    };
  }, [isExpanded]);

  const changeIsExpandFalse = () => {
    setIsRequired(!inputValue && required);
    setLoad(true);
    setIsExpanded(false);
    if (inputRef.current) inputRef.current.focus();
  };

  const handleOptionClick = (selected: any) => {
    setInputValue(selected.label);
    onSelect(completedValue ? selected : { label: selected.label, value: selected.value });
    setIsExpanded(false);
  };

  const toggleExpansion = () => {
    if (disabled) return;
    setIsExpanded(!isExpanded);
  };

  const handleTrashClick = () => {
    if (disabled) return;
    setInputValue(''); // Limpiar el valor de entrada
    onSelect({ label: '', value: '' }); // Opcional, dependiendo de tu lógica de selección
    if (inputRef.current) inputRef.current.focus(); // Mover el foco al input
    setIsExpanded(true);
  };

  useEffect(() => {
    if (onValid && !isExpanded && required && load) {
      onValid({ formControl: formControl, valid: !isRequired });
    }
  }, [formControl, isExpanded, isRequired, load, onValid, required]);

  useEffect(() => {
    if (isReload && required) {
      setIsRequired(!inputValue);
    }
  }, [inputValue, isReload, required]);

  const calculatePosition = () => {
    if (isExpanded && inputRef.current && selectRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const gap = 4; // Espacio entre el input y el select
      const viewportHeight = window.innerHeight;
      let top = inputRect.bottom + gap;
      let positionUpwards = false;
      if (top + selectRect.height > viewportHeight) {
        top = inputRect.top - selectRect.height - gap;
        positionUpwards = true;
      }
      // Calculamos la altura total de los chips seleccionados
      let chipsHeight = 0;
      if (inputRef.current) {
        const chipsContainer = inputRef.current.closest(`.${style.app_input_container}`);
        chipsHeight = chipsContainer ? chipsContainer.getBoundingClientRect().height - inputRect.height : 0;
      }
        // Si no estamos en un modal, calcular posición normal
        top = positionUpwards
          ? inputRect.top - selectRect.height - gap
          : inputRect.bottom + gap + chipsHeight;

        // Estilos para el dropdown en la página principal
        selectRef.current.style.position = 'absolute';
        selectRef.current.style.top = `${top}px`;
        selectRef.current.style.left = `${inputRect.left}px`;
        selectRef.current.style.width = `${inputRect.width}px`;
        selectRef.current.style.zIndex = String(1000);
    }
  };
  useEffect(() => {
    if (isExpanded) {
      // Force recalculation after the expansion state has changed
      setTimeout(calculatePosition, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  useEffect(() => {
    // Recalculate position whenever the content of the dropdown changes (after loading data)
    if (isExpanded && !isLoading) {
      calculatePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectValue.length, isExpanded]);
  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    window.addEventListener('scroll', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
      window.removeEventListener('scroll', calculatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Renderizar el dropdown usando ReactDOM.createPortal
  const renderDropdown = () => {
    if (isExpanded) {
      return ReactDOM.createPortal(
        <div ref={selectRef} className={`${style.select} ${isExpanded ? style.expanded : ""}`}>
          {isLoading ? (
            <div className={style.loading}>Cargando...</div>
          ) : (
            <div className={style.options}>
              {selectValue?.length > 0 ? (
                selectValue.map((item, index) => (
                  <div key={index} className={style.option} onClick={() => handleOptionClick(item)}>
                    {item.label}
                  </div>
                ))
              ) : (
                <div className={style.void_items}>
                  <strong>No hay elementos disponibles</strong>
                </div>
              )}
            </div>
          )}
        </div>,
        document.body // Renderiza el dropdown en el body
      );
    }
    return null;
  };

  return (
    <>
      {isExpanded && <div className={style.overlay} onClick={changeIsExpandFalse}></div>}
      <div className={style.container__input}>
        {textLabel && (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <label htmlFor="select-input" className={style.input__label}>
              {textLabel}
            </label>
            {icon && (<>
                <AppGetIcon name={icon} classIcon={style.icon} data-tooltip-id="my-tooltip" data-tooltip-content={tooltipText} />
                <Tooltip id="my-tooltip" style={{
                  wordBreak: 'keep-all',
                  overflowWrap: 'break-word',
                  hyphens: 'manual',
                  backgroundColor: "#41545C", color: "#FFFFFF", maxWidth: '300px', zIndex: 10, fontWeight: 'bold'
                }} />
            </> 
            )}
          </div>
        )}
        <div className={style.app_input_container}>
          <input
            autoComplete="off"
            className={classNameInput ? classNameInput : style.input__input}
            onClick={toggleExpansion}
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Selecciona o digita una opción"
            style={{ paddingRight: '10px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            disabled={disabled}
          />
          <div
            onMouseEnter={() => setIsHoveredTrash(true)}
            onMouseLeave={() => setIsHoveredTrash(false)}
            style={{ position: 'absolute', right: '2px', top: '50%', transform: 'translateY(-50%)' }}
          >
            <AppGetIcon
              name={inputValue?.length > 0 ? 'trash' : "arrow-down"}
              classIcon={style.selectIcon}
              onClick={handleTrashClick}
              strokeColor="#5A5F65"
              hoverColor={inputValue?.length > 0 && isHoveredTrash ? "#D43C3C" : undefined}
            />
          </div>

          {renderDropdown()} {/* Llama a la función para renderizar el dropdown */}

        </div>
        <div className={style.messageError}>
          {(isRequired || errorMessage) ? errorMessage ? errorMessage : 'Selecciona o digita una opción' : ''}
        </div>
      </div>
    </>
  );
};

export default memo(InputComponent);
