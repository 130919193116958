import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IReservationsLimit {
	id: number | null | string,
  name:  null | string,
	active: boolean | null,
  campusId: string | null | any ,
  campusName: string | null | any ,
  creatorUserId: number | null ,
  startDate:  any,
  endDate: any,
}

export class CreateOrUpdateCampusReservationLimitService extends CreateOrUpdateBaseService<IReservationsLimit, any> {
	url = `${services.campusReservationLimit}/createOrUpdateCampusReservationLimit`;
}
