import { useState } from "react";

import AppGetIcon from "../AppGetIcon";
import Search from "../Search";
import CardTitle from "../CardTitle";

import './CardHeader.css'

export const CardHeader = ({
  title,
  buttonToBack,
  handleGoToback,
  buttonToEdit,
  handleGoToEdit,
  backgroundColor,
  titleColor,
  titlePosition,
  titlePadding,
  lineUnderTitle,
  buttomSearch,
  selectOptions,
  handleSelectChange,
  handleSearchChange,
  handleSearchClear,
  searchHeader
}: CardHeaderProps) => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const headerStyle: React.CSSProperties = {
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: lineUnderTitle ? 'column' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: titlePosition || 'start',
    backgroundColor: backgroundColor || 'transparent',
    color: titleColor,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    borderRadius: '4px 4px 0px 0px',
    padding: titlePadding || ''
  };
  const lineStyle: React.CSSProperties = {
    width: '100%',
    borderBottom: '2px solid #EDEDED',
  };
  const handleSearchClose = () => {
    setIsSearch(false);
  };
  return <div style={headerStyle}>
    <div className="header__title" style={{ width: !selectOptions ? '100%' : '40%' }}>
      {
        buttonToBack && <div className="header__title_icon"><AppGetIcon name="goToBack" onClick={handleGoToback} /></div>
      }
      <CardTitle title={title} colorTitle={titleColor} />
    </div>
    {
      selectOptions || buttomSearch || buttonToEdit ?
        <div className="header__actions">
          {(selectOptions && handleSelectChange) &&
            <select id="selectField" className="header__select" onChange={(e) => handleSelectChange(e.target.value)}>
              {selectOptions.map((option: any) => (
                <option key={option.sessionId ?? 0} value={option.sessionId ?? '0'}>
                  {option.title ? option.title : 'Sesión principal'}
                </option>
              ))}
            </select>
          }
          {
            <>
              {
                buttomSearch ?
                  !isSearch ?
                    <div className="header__title_icon">  <AppGetIcon name="searchHeader" onClick={() => setIsSearch(true)} /> </div>
                    :
                    (handleSearchChange && handleSearchClear) &&
                    <Search
                      classNameInput="searchInput"
                      onChange={handleSearchChange}
                      valueInput={searchHeader}
                      onClear={handleSearchClear}
                      closeSearch={handleSearchClose}
                    />
                  : <div></div>
              }
            </>
          }
          {
            buttonToEdit && <div className="header__actions_edit" onClick={handleGoToEdit}>
              <AppGetIcon name="pencil" strokeColor="#212529" /> Editar
            </div>
          }
        </div>
        : <></>
    }

    {lineUnderTitle &&
      <div style={lineStyle}></div>
    }
  </div>;
};