import { create, update } from '../../utils/api';
import { JsonToFormDataService } from '../../utils/jsonToFormData.service';

const jsonToFormDataService = new JsonToFormDataService();

export class CreateOrUpdateBaseService<ICreateOrUpdateContentService, IResponse> {
  url = '';
  isFormData = false;
  params = {};

  async run(data: ICreateOrUpdateContentService, id?: number | null): Promise<any> {
    let newData: any = data;

    if (this.isFormData) {
      newData = jsonToFormDataService.run(data);
    }

    if (id !== null && id !== undefined) {
      return await this.update(newData, id);
    } else {
      return await this.create(newData);
    }
  }

  private async create(data: any): Promise<IResponse> {
    return create(this.url, data).then((response: any) => response);
  }

  private async update(data: any, id: number): Promise<IResponse> {
    return update(this.url, id, data).then((response: any) => response.data);
  }
}
