import React from 'react';
import style from './ProgressLineTime.module.css';

interface ProgressLineProps {
  progress: number; // Define el tipo de `progress` como número
}
const ProgressLineTime: React.FC<ProgressLineProps> = ({ progress }) => {
  return (
    <div className={style.progressLineContainer}>
      <div
        className={style.progressLine}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressLineTime;
