// App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';

import PrivateLayout from './routes/PrivateLayout';
import ProtectedRoute from './routes/ProtectedRoute';

import NotFoundPage from './common/UI/NotFound/NotFound';

import LoginPage from './auth/Login';
import LoginByToken from './auth/loginByToken';
import Unauthorized from './auth/Unauthorized';

import ResourcesPage from './modules/booking/pages/Resources';
import ReservationsPage from './modules/booking/pages/Reservations';
import Users from './modules/systemAdmin/pages/Users';
import Roles from './modules/systemAdmin/pages/Roles';
import CPermissionRoles from './modules/systemAdmin/pages/Roles/PermissionRoles';
import AssistanceTeaching from './modules/reports/pages/AssistanceTeaching';
import GeneralReservationLimitPage from './modules/administrationBooking/pages/GeneralReservationLimit';
import CampusReservationLimitPage from './modules/administrationBooking/pages/CampusReservationLimit';
import EnabledCampusStudentPage from './modules/administrationBooking/pages/EnabledCampusStudent';
import AdministrationRotationScreenPage from './modules/rotationScreen/pages/AdministrationRotationScreen';
import FormAdministrationRotativeScreenPage from './modules/rotationScreen/components/FormAdministrationsRotationScreen';
import RotationScreenViewPage from './modules/rotationScreen/components/RotationScreenView';
import MultimediaFiles from './modules/rotationScreen/pages/MultimediaFiles';
import DashboardScreenView from './modules/rotationScreen/pages/DashboardScreenView';

console.log('version 1.0.1');
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};
const App: React.FC = () => {
  const tokenFromCookie = getCookie('authToken') ?? '';

  return (
    <Router>
      <AuthProvider tokenFromCookie={tokenFromCookie}>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover toastClassName={'Toastify__toast'} />
        <ThemeProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path='/auth/login-token' element={<LoginByToken />} />
            <Route path='/booking/rotationScreen/view' element={<RotationScreenViewPage />} />
            {/* Nueva ruta protegida con un rol diferente */}
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute permissionCode="booking.users" requiredPermission="list">
                  <PrivateLayout>
                    <Users />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/roles"
              element={
                <ProtectedRoute permissionCode="booking.roles" requiredPermission="list">
                  <PrivateLayout>
                    <Roles />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/permissions/role/:roleId/:name?"
              element={
                <ProtectedRoute permissionCode="booking.roles" requiredPermission="edit">
                  <PrivateLayout>
                    <CPermissionRoles />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />



        <Route path='/reports/assistance/teaching'  element={
                <ProtectedRoute permissionCode="reports.assistance.teaching" requiredPermission="list">
                  <PrivateLayout>
                  <AssistanceTeaching />
                  </PrivateLayout>
                </ProtectedRoute>
              } />




            <Route
              path="/booking/resources"
              element={
                <ProtectedRoute permissionCode="booking.resources" requiredPermission="list">
                  <PrivateLayout>
                    <ResourcesPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking/reservations/:reservationId?"
              element={
                <ProtectedRoute permissionCode="booking.reservations" requiredPermission="list">
                  <PrivateLayout>
                    <ReservationsPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/generalReservationLimit/GeneralReservationLimit"
              element={
                <ProtectedRoute permissionCode="administration.booking.limit.general" requiredPermission="list">
                  <PrivateLayout>
                    <GeneralReservationLimitPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/campusReservationLimit/CampusReservationLimit"
              element={
                <ProtectedRoute permissionCode="administration.booking.limit.campus" requiredPermission="list">
                  <PrivateLayout>
                    <CampusReservationLimitPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/campusReservationLimit/EnabledCampusStudent"
              element={
                <ProtectedRoute permissionCode="administration.booking.campus.student" requiredPermission="list">
                  <PrivateLayout>
                    <EnabledCampusStudentPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking/rotationScreen"
              element={
                <ProtectedRoute permissionCode="rotationScreen.administration" requiredPermission="list">
                  <PrivateLayout>
                    <AdministrationRotationScreenPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking/formAdministrationRotativeScreen/rotativeScreenId/:idScreen?"
              element={
                <ProtectedRoute permissionCode="rotationScreen.administration" requiredPermission="create">
                  <PrivateLayout>
                    <FormAdministrationRotativeScreenPage />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking/multimediaFiles"
              element={
                <ProtectedRoute permissionCode="rotationScreen.multimediaFiles" requiredPermission="list">
                  <PrivateLayout>
                    <MultimediaFiles />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking/dashboardScreenView"
              element={
                <ProtectedRoute>
                  <PrivateLayout>
                    <DashboardScreenView />
                  </PrivateLayout>
                </ProtectedRoute>
              }
            />
            <Route path='/not-authorized/:login?' element={<Unauthorized />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
export default App;
