// src/pages/NotFoundPage.tsx

import React from 'react';
import logo from '../../../assets/images/logo_u_andres_bello.png';

import style from '../Unauthorized/Unauthorized.module.css';

const NotFoundPage: React.FC = () => {
  return (
    <div className={style.Unauthorized__page}>
      <div className={style.Unauthorized__contianer_img}>
        <img src={logo} alt='Logo universida' />
      </div>
      <h2>Página no encontrada</h2>
      <p>Lo sentimos, la página que buscas no existe.</p>
    </div>
  );
};

export default NotFoundPage;
