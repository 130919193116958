import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { IParams, ResponseQ } from "../../../../common/dtos/paginate.dto";

export const GetReasonsWithPaginationService = async (params: IParams) => {
    const response = await getByQuery<ResponseQ<any>>(`${services.updatedClass}/getReasons`, params);
    return response;
  };


