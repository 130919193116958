import { format, parse, isValid } from 'date-fns';
import { es } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';

function useFormattedDate() {
  const formatDate = (
    inputDate: string | Date | null,
    outputFormat: string = 'yyyy/MM/dd',
    inputFormat?: string
  ): string => {
    if (!inputDate) {
      return '';
    }

    // Validar casos de fechas no válidas como '0000-00-00 00:00:00' o '0000-00-00'
    const invalidDatePattern = /^0{4}-0{2}-0{2}( 0{2}:0{2}:0{2})?$/;
    if (typeof inputDate === 'string' && invalidDatePattern.test(inputDate)) {
      return '';
    }

    // Convertir años de dos dígitos a cuatro dígitos
    const convertTwoDigitYearToFourDigit = (dateStr: string): string => {
      return dateStr.replace(/(\d{1,2}\/\d{1,2}\/)(\d{2})$/, (match, p1, p2) => {
        const year = parseInt(p2, 10);
        const fullYear = year < 100 ? year + 2000 : year;
        return p1 + fullYear;
      });
    };

    // Normalizar hora parcial
    const normalizeTime = (timeStr: string): string => {
      return timeStr.match(/^\d{1,2}$/) ? `${timeStr}:00` : timeStr;
    };

    if (typeof inputDate === 'string') {
      inputDate = convertTwoDigitYearToFourDigit(inputDate);
      inputDate = normalizeTime(inputDate);
    }

    let date: Date | undefined = undefined;

    if (inputDate instanceof Date) {
      date = inputDate;
    } else {
      if (inputFormat) {
        const parsedDate = parse(inputDate, inputFormat, new Date());
        if (isValid(parsedDate)) {
          date = parsedDate;
        }
      }

      if (!date) {
        const possibleFormats = [
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
          'yyyy-MM-dd HH:mm:ss',
          'yyyy-MM-dd',
          'dd-MM-yyyy',
          'dd/MM/yyyy',
          'MM-dd-yyyy',
          'MM/dd/yyyy',
          'yyyy/MM/dd',
          'MMM dd, yyyy',
          'MMMM dd, yyyy'
        ];

        for (const formatString of possibleFormats) {
          const parsedDate = parse(inputDate, formatString, new Date());
          if (isValid(parsedDate)) {
            date = parsedDate;
            break;
          }
        }
      }

      if (!date) {
        const timestamp = Date.parse(inputDate);
        if (!isNaN(timestamp)) {
          date = new Date(timestamp);
        }
      }
    }

    if (!date || !isValid(date)) {
      return ''; // Retornar vacío si la fecha no es válida
    }

    // Convertir a la hora local si la fecha de entrada está en UTC
    const utcFormats = [
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
    ];
    const isUTC = utcFormats.some(formatString => isValid(parse(inputDate as string, formatString, new Date())));

    if (isUTC) {
      date = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
    }

    // Formatear la fecha final
    return format(date, outputFormat, { locale: es });
  };

  return formatDate;
}

export default useFormattedDate;
