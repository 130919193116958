import React, { useState } from 'react';
import './search.css';
import AppGetIcon from '../AppGetIcon';

interface SearchProps {
  className?: any;
  classNameInput?: any;
  valueInput?: string;
  onChange: (value: string) => void;
  onClear: () => void; // Agregamos la propiedad onClear a la interfaz SearchProps
  closeSearch?: () => void; // Agregamos la propiedad onClear a la interfaz SearchProps
};

const Search: React.FC<SearchProps> = ({ onChange, closeSearch, className, classNameInput, valueInput }) => {
  const [searchText, setSearchText] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    onChange(value);
  };

  const handleClearClick = () => {
    setSearchText('');
    onChange('');
  };

  return (
    <div className={`search-container ${className}`} >
      <div className={`search-input-container ${classNameInput}`}>
        {
          !closeSearch ? <AppGetIcon className="search-icon roteteIcon" name='searchHeader' /> :
            <AppGetIcon className="search-icon" name='close' classIcon='icon__close_search' onClick={closeSearch} />
        }
        <input
          autoFocus
          type="text"
          value={valueInput??''}
          onChange={handleInputChange}
          placeholder="Buscar..."
        />
        {searchText.length > 0 && (
          <AppGetIcon className="search-icon search-icon-right" name='trash'
            strokeColor='#962330' onClick={handleClearClick} />
        )}
      </div>
    </div>
  );
};

export default Search;