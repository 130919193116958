import React, { useState } from 'react';
import style from '../inputs.module.css'

interface SelectFieldProps {
  label: string;
  classNameSelect?: string;
  options: { value: string; label: string }[];
  valueSelected?: string;
  handleChange: (selectedValue: string) => void;
}

const SelectField = ({ label, options, classNameSelect, valueSelected, handleChange }: SelectFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(valueSelected);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    handleChange(newValue);
  };
  return (
    <>
      <label htmlFor="selectField" className={style.input__label}>{label}</label>
      <select id="selectField" style={{ padding: '5px 10px' }} className={style.input__input + ' ' + classNameSelect} value={selectedValue} onChange={handleSelectChange}>
        {/* <option value="">Seleccionar...</option> */}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectField;
