import * as CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY;

if (!secretKey) {
  throw new Error('Secret key is not defined');
}
// Función para encriptar
export const encrypt = (data: any): string => {
  try {
    // Convertir los datos a JSON y luego encriptar
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext; // Devolver el texto cifrado como una cadena
  } catch (error) {
    console.error("Error during encryption:", error);
    return '';
  }
};

// Función para desencriptar
export const decrypt = (cipherText: string): any => {
  try {
    // Desencriptar el texto cifrado usando la clave secreta
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    // Convertir los bytes desencriptados a una cadena UTF-8
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    // Verificar si el texto desencriptado es válido JSON antes de parsearlo
    if (decryptedText) {
      return JSON.parse(decryptedText); // Si es un objeto JSON válido
    }
    throw new Error('Failed to decrypt or parse the data.');
  } catch (error) {
    console.error("Error during decryption:", error);
    return null; // Devolver null o manejar el error como consideres necesario
  }
};
