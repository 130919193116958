import { CreateOrUpdateBaseService } from "../../../../common/services/createOrUpdateBase.service";
import { services } from "../../../../constants/services";

export interface IenabledCampus {
  campusId: string | null;
  enabled: number;
};

export class CreateOrUpdatedcreateOrUpdatedEnableCampusService extends CreateOrUpdateBaseService<IenabledCampus, any> {
  url = `${services.rooms}/enableCampus`;
};