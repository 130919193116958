import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IReservationsLimit {
	id: number | null | string,
  name:  null | string,
	active: boolean | null,
  lastModifiedUserId: number | null | string,
  startDate:  any,
  endDate: any,
}

export class CreateOrUpdateGeneralReservationLimitService extends CreateOrUpdateBaseService<IReservationsLimit, any> {
	url = `${services.generalReservationLimit}/createOrUpdateGeneralReservationLimit`;
}
