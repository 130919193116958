// src/components/PrivateLayout.tsx
import React, { ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

import NavBar from '../common/UI/Nabvar';
import SideBar from '../common/UI/SideBar';
import AppHeader from '../common/UI/Header/AppHeader';
import Footer from '../common/UI/Footer';

import { useItemsStore } from '../store/items.store';

import logo from '../assets/images/logo_u_andres_bello.png'
import userIcon from '../assets/images/userIcon.png'

const PrivateLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const itemsStorage = useItemsStore(
    (state) => ({
      items: state.items,
      setItems: state.setItems
    }),
    shallow
  );
  const style__section_ontainer: React.CSSProperties = {
    width: '100%',
    height: 'calc(100vh - 75px)',
    display: ' flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F0F2F5',
    color: '#3C4248',
    overflow: 'auto',
    padding: '60px 10px 10px',
  }
  return (
    <div className='AppLayout'>
      <NavBar urlLogo={logo} urlIcon={userIcon} />
      <SideBar itemsSideBar={itemsStorage.items} />
      <section className='home-section'>
        <AppHeader />
        <div style={style__section_ontainer}>
          {children}
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default PrivateLayout;
