// authStore.ts
import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from "zustand/middleware";
import { createJSONStorage } from 'zustand/middleware';

import { InitDataDto } from "../common/dtos/initData.dto";

type InitDataState = {
  initData: InitDataDto;
  rolSelected: number | string;
  setRolSelected: (rolSelected: number | string) => void;
  facultySelected: number;
  setFacultySelected: (facultySelected: number) => void;
  setInitData: (newInitData: InitDataDto) => void;
  clearInitData: () => void;
};

// Crear un almacenamiento personalizado
const storage = createJSONStorage(() => localStorage);

export const useInitDataStore = createWithEqualityFn<InitDataState>()(
  persist(
    (set) => {
      return ({
        initData: {
          userRoles: [],
          userFaculty: [],
          userCampus: [],
          items: [],
          user: {
            id: 0,
            firstnames: '',
            secondname: '',
            lastnames: '',
            username: '',
            role: 0,
            email: '',
            phone: '',
            externalUserKey: '',
            createdAt: '',
            updatedAt: '',
            password: '',
            active: 0,
            pidm: '',
            exp: 0
          },
        },
        setInitData: (newInitData: InitDataDto) => set({ initData: newInitData }),
        rolSelected: '', facultySelected: 0,
        setRolSelected: (newRolSelected: number | string) => set({ rolSelected: newRolSelected }),

        setFacultySelected: (newRolSelected: number) => set({ facultySelected: newRolSelected }),
        clearInitData: () => set({
          initData: {
            userRoles: [],
            userFaculty: [],
            userCampus: [],
            items: [],
            user: {
              id: 0,
              firstnames: '',
              secondname: '',
              lastnames: '',
              username: '',
              role: 0,
              email: '',
              phone: '',
              externalUserKey: '',
              createdAt: '',
              updatedAt: '',
              password: '',
              active: 0,
              pidm: '',
              exp: 0
            },
          },
          rolSelected: 0,
          facultySelected: 0,
        })
      });
    },
    {
      name: 'initData-storage', // Nombre de almacenamiento
      storage
    }
  )
);

