// src/constants/timeOptions.ts

export const TimeOptions= [
    { label: "0", value: '0' },
    { label: "15", value: '15' },
    { label: "30", value: '30' },
    { label: "45", value: '45' },
    { label: "60", value: '60' },
  ]

export const NumberRowsOptions = () => {
  return [
    { label: "5", value: '5' },
    { label: "10", value: '10' },
    { label: "15", value: '15' },
    { label: "20", value: '20' },
    { label: "25", value: '25' },
    { label: "30", value: '30' },
  ]
};

export const TimePerPageOptions =[
    { label: "5", value: '5' },
    { label: "10", value: '10' },
    { label: "15", value: '15' },
    { label: "20", value: '20' },
    { label: "25", value: '25' },
    { label: "30", value: '30' },
  ]


export const OrientationEnum: Record<string, string> = {
  complete: 'Completa',
  divided: 'Dividida',
};

export const OriginTypeEnum: Record<string, string> = {
  general: 'General',
  specific: 'Específica',
};