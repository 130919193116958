import { ErrorMessage, Field, useFormikContext } from 'formik';
import style from '../inputs.module.css';

interface TextAreaInputInterface {
  name: string;
  placeholder?: string;
  TextLabel?: string;
  disabled?: boolean;
  rows?: number;
  classNameInput?: string;
  value?: string; // Para manejar el valor fuera de Formik
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Para manejar cambios fuera de Formik
}

const InputTextArea = ({
  name,
  placeholder,
  TextLabel,
  disabled,
  rows = 5,
  classNameInput,
  value,
  onChange,
}: TextAreaInputInterface) => {
  const formik = useFormikContext(); // Accedemos al contexto de Formik si está disponible

  const isFormik = !!formik; // Verificamos si el componente está dentro de Formik

  return (
    <div className={style.container__input}>
      <label htmlFor={name} className={style.input__label}>
        {TextLabel}
      </label>
      {isFormik ? (
        // Si está dentro de Formik, usamos Field y ErrorMessage
        <>
          <Field
            as="textarea"
            className={classNameInput ? classNameInput : style.input__input}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
          />
          <div className={style.containerError}>
            <ErrorMessage className={style.messageError} name={name} component="div" />
          </div>
        </>
      ) : (
        // Si no está dentro de Formik, usamos textarea normal
        <>
          <textarea
            className={classNameInput ? classNameInput : style.input__input}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
            value={value} // Controlado por el estado externo
            onChange={onChange} // Maneja el cambio fuera de Formik
          />
        </>
      )}
    </div>
  );
};

export default InputTextArea;
