import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IResource {
	id: number | null | string;
	numberAttendees: number | null;
	eventName: string | null;
	startDate: string | null;
	endDate: string | null;
	recurrence: string | null;
	status: string | null;
	requestedBy: number | null;
	approvedByDGDE: number | null | any;
	approvedBy: number | null | any;
	approvalDate: string | null | any;
	rejectionComment: string | null | any;
	roomType: string | null;
	responsibles: any;
	responsiblesAddString: any;
	rooms: any[] | [];
	intervals: any[] | [];
	requesterRoleId?: any;
	isModifiedResource?: any;
}

export class CreateOrUpdateReservationsService extends CreateOrUpdateBaseService<IResource, any> {
	url = `${services.booking}/createOrUpdateBooking`;
}
