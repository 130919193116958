// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInitDataStore } from '../store/initData.store';
import { useItemsStore } from '../store/items.store';
import { useUserStore } from '../store/user.store';
import { shallow } from 'zustand/shallow';
import { jwtDecode } from 'jwt-decode';
import { InitDataDto, UserDto } from '../common/dtos/initData.dto';
import { GetInitDataService } from '../common/services/getInitData.service';

interface AuthContextType {
  isAuthenticated: boolean;
  userRole: number;
  loading: boolean;
  login: (token: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<{ children: ReactNode; tokenFromCookie?: string | null }> = ({ children, tokenFromCookie }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenFromURL = searchParams.get('token');

  // Variable de entorno para distinguir entre desarrollo y producción
  const REACT_APP_ENV_DEV = process.env.REACT_APP_ENV_DEV ?? '';

  // Función para leer cookies
  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const result = parts.pop()?.split(';').shift();
      return result || null;
    }
    return null;
  };

  // Función segura para parsear JSON
  const safeParseJSON = (jsonString: string | null) => {
    try {
      if (!jsonString || jsonString === 'undefined') {
        return null;
      }
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Error parsing JSON:', error, jsonString);
      return null;
    }
  };

  // Leer isAuthenticated desde la cookie al inicializar
  const isAuthenticatedFromCookie = getCookie('isAuthenticated') === 'true';

  // Estado de autenticación y carga
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isAuthenticatedFromCookie);
  const [userRole, setUserRole] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const initDataStore = useInitDataStore((state) => state, shallow);
  const itemsStorage = useItemsStore((state) => state, shallow);
  const dataUserStore = useUserStore(
    (state) => ({
      dataUser: state.dataUser,
      updateDataUser: state.updateDataUser,
    }),
    shallow
  );

  useEffect(() => {
    // Verificar si la cookie de autenticación existe
    const token = tokenFromCookie || getCookie('authToken') || tokenFromURL;
    if (token) {
      if (!isAuthenticated) {
        // Si no está autenticado, llamar a login
        login(token);
      } else {
        // Si ya está autenticado, cargar los datos iniciales si no están cargados
        if (!initDataStore.initData) {
          loadInitData(token);
        } else {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromCookie]);

  const loadInitData = async (token: string) => {
    try {
      const isDevEnv = REACT_APP_ENV_DEV === 'true';

      // Leer cookies para obtener los demás datos
      const authUserCookie = getCookie('authUser');
      const userRolesCookie = getCookie('authUserRoles');
      const userCampusCookie = getCookie('authUserCampus');
      const rolSelectedCookie = getCookie('rolSelected');

      const authUser = authUserCookie ? safeParseJSON(authUserCookie) : {};
      const userRoles = userRolesCookie ? safeParseJSON(userRolesCookie) : [];
      const userCampus = userCampusCookie ? safeParseJSON(userCampusCookie) : [];
      const rolSelected = rolSelectedCookie ? safeParseJSON(rolSelectedCookie) : '';

      // Obtener datos iniciales
      let response: any = await GetInitDataService(rolSelected ?? '');

      const initData: InitDataDto = {
        userRoles: isDevEnv ? response.userRoles : userRoles,
        userFaculty: [],
        userCampus: isDevEnv ? response.userCampus : userCampus,
        items: response.items,
        user: isDevEnv ? response.user : authUser,
      };

      // Actualizar Zustand stores con datos de las cookies o response
      dataUserStore.updateDataUser(initData.user);
      initDataStore.setRolSelected(Number(rolSelected) || initData.userRoles[0]?.roleId || 0);
      initDataStore.setInitData(initData);
      itemsStorage.setItems(initData.items);

      setUserRole(Number(rolSelected) || initData.userRoles[0]?.roleId || 0);

      setLoading(false);
    } catch (error) {
      console.error('Error loading init data:', error);
      setLoading(false);
      logout();
    }
  };

  const login = async (token: string) => {
    try {
      const decodedToken: UserDto = jwtDecode(token);
      if (decodedToken.active) {
        // Guardar token y el estado de autenticación en cookies
        document.cookie = `authToken=${token}; path=/; domain=.cyk.com.co; SameSite=None; Secure;`;
        document.cookie = `isAuthenticated=true; path=/; domain=.cyk.com.co; SameSite=None; Secure;`;
        localStorage.setItem('authToken', token);

        // Obtener datos iniciales
        await loadInitData(token);

        // Establecer isAuthenticated en true
        setIsAuthenticated(true);

        // Redirección según los datos
        let domainUrl = initDataStore.initData?.items[0]?.children?.[0]?.domainUrl;
        let route = '/booking/resources';

        const selectedModuleItemCookie = getCookie('selectedModuleItem');
        const selectedModuleItem = selectedModuleItemCookie ? safeParseJSON(selectedModuleItemCookie) : {};

        console.log('selectedModuleItem', selectedModuleItem);

        if (selectedModuleItem && selectedModuleItem.url) {
          try {
            const selectedUrl = new URL(selectedModuleItem.url);
            domainUrl = `${selectedUrl.protocol}//${selectedUrl.hostname}`;
            route = selectedUrl.pathname;
            console.log('Parsed selectedModuleItem URL:', selectedUrl);
            console.log('domainUrl:', domainUrl);
            console.log('route:', route);
          } catch (error) {
            console.error('Error parsing selectedModuleItem URL:', error);
          }
        }

        // Obtener el dominio actual
        const currentDomain = window.location.origin;

        // Lógica de redirección basada en el entorno
        if (domainUrl && route) {
          console.log('route', route);

          const isDevEnv = REACT_APP_ENV_DEV === 'true';
          if (isDevEnv) {
            // Entorno de desarrollo: navegar internamente
            navigate(route, { replace: true });
          } else {
            // Entorno de producción: verificar el dominio
            if (currentDomain === domainUrl) {
              // Ruta interna
              navigate(route, { replace: true });
            } else {
              // Ruta externa
              window.location.href = `${domainUrl}${route}`;
            }
          }
        } else {
          navigate('/booking/resources');
          console.error('Error: No se encontró un dominio o ruta válida.');
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    } catch (error) {
      console.error('Token inválido:', error);
      setIsAuthenticated(false);
      setLoading(false);
      logout(); // Si hay error en el token, hacer logout
    }
  };

  const logout = () => {
    // Borrar la cookie de autenticación y de isAuthenticated
    document.cookie = 'authToken=; path=/; domain=.cyk.com.co; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure;';
    document.cookie = 'isAuthenticated=; path=/; domain=.cyk.com.co; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure;';
    setUserRole(0);
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/login', { replace: true });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
