import React, { useState } from 'react';
import AppGetIcon from './../../AppGetIcon'; // Asegúrate de que la ruta al componente sea correcta.
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";


interface AppButtonProps {
  onClick: () => void;
  iconName: string;
  buttonText: string;
  variant?: 'primary' | 'secondary'; 
  disabled?: boolean,
}

const AppButton: React.FC<AppButtonProps> = ({
  onClick,
  iconName,
  buttonText,
  variant ='primary',
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const buttonClassName = variant === 'primary' ? styleDataTable.button_option : styleDataTable.buttomFooter;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <button
        className={buttonClassName}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled= {disabled}
        style={{
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          backgroundColor: isHovered && variant === 'primary' ? '#962330' : undefined, // Cambia el fondo en hover solo para el `primary`.
          color: isHovered && variant === 'primary' ? '#FFF' : undefined, // Cambia el color del texto en hover solo para el `primary`.
        }}
      >
        <AppGetIcon
          name={iconName}
          classIcon={styleDataTable.sizeIcon}
          strokeColor={variant === 'primary' ? '#962330' : '#fff'}
          hoverColor={isHovered && variant === 'primary' ? "#FFF" : undefined} // Cambia el color del ícono en hover.
        />
        {buttonText}
      </button>
    </div>
  );
};

export default AppButton;
