import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { es } from 'date-fns/locale';
import { utcToZonedTime, format as formatWithTZ } from 'date-fns-tz';

import AppGetIcon from "../../../../common/UI/AppGetIcon";
import ProgressLineTime from "../../../../common/UI/Loaders/ProgressLineTime";

import { decrypt, encrypt } from "../../../../utils/encrypt";

import style from "./rotationScreenView.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";

import { GetRotationScreenViewWithPaginationService } from "../../services/RotationScreenView/getRotationScreenViewWithPagination.service";
import Loading from "../../../../common/UI/Loading";

const getRotationScreenViewWithPaginationService = new GetRotationScreenViewWithPaginationService();

export default function RotationScreenViewPage() {
  const [searchParams] = useSearchParams();
  const [loadingViewData, setLoadingViewData] = useState<boolean>(true);
  const paramsByUrl: string = (searchParams.get("params") ?? '').replace(/ /g, '+');

  const [classes, setClasses] = useState<any[]>([]);
  const [adversitingCompleted, setAdversitingCompleted] = useState<any[]>([]);
  const [adversitingDivided, setAdversitingDivided] = useState<any[]>([]);
  const [configGeneral, setConfigGeneral] = useState<any>({});

  const [currentPageClases, setCurrentPageClases] = useState(1);
  const [perPageClases, setPerPageClases] = useState(5);

  const [totalGeneralClases, setTotalGeneralClases] = useState(0);

  const [countProgressBar, setCountProgressBar] = useState(0);
  const [countProgressBarAdDivided, setCountProgressBarAdDivided] = useState(0);
  const [countProgressBarAd, setCountProgressBarAd] = useState(0);

  const [displayMode, setDisplayMode] = useState('classes'); // 'classes' or 'ad'

  const [currentAdCompleteIndex, setCurrentAdCompleteIndex] = useState(0);
  const [currentAdDividedIndex, setCurrentAdDividedIndex] = useState(0);

  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const dividedAdIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const timeZone = 'America/Santiago';
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedTime, setFormattedTime] = useState('');

  const [loadingPageTransition, setLoadingPageTransition] = useState(false);

  // Variables para indicadores de página
  const totalClassPages = Math.ceil(totalGeneralClases / perPageClases);
  const totalAdPages = adversitingCompleted.length;

  // Actualización de la fecha y hora
  useEffect(() => {
    const updateTime = () => {
      const currentDate = new Date();
      const zonedDate = utcToZonedTime(currentDate, timeZone);

      setFormattedDate(formatWithTZ(zonedDate, "EEE, d 'de' MMMM 'de' yyyy", { locale: es, timeZone }));
      setFormattedTime(formatWithTZ(zonedDate, 'HH:mm:ss', { timeZone }));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [timeZone]);

  // Obtener configuración inicial
  const getDataRotationScreenViewForConfig = async () => {
    if (paramsByUrl) {
      const decryptParamsByUrl: any = decrypt(paramsByUrl);

      let paramsForEncrypt = {
        rotatingScreenId: decryptParamsByUrl?.rotatingScreenId ?? 0,
        page: 1,
        perPage: perPageClases,
      };
      try {
        const requestParams: any = {
          params: encrypt(paramsForEncrypt),
        };
        const response = await getRotationScreenViewWithPaginationService.run(requestParams);
        const data = decrypt(response);

        setConfigGeneral(data[0]?.general ?? {});
        const newPerPageClases = data[0]?.general?.numberRows ?? perPageClases;
        setPerPageClases(newPerPageClases);

        fetchClassesData(1, newPerPageClases);

        setLoadingViewData(false);

      } catch (error) {
        console.log('Error al ejecutar el servicio de reservas', error);
        setLoadingViewData(false);
      };
    };
  };

  // Función para obtener las clases y publicidad
  const fetchClassesData = async (page: number, perPage: number) => {
    setLoadingPageTransition(true);
    const decryptParamsByUrl: any = decrypt(paramsByUrl);
    let paramsForEncrypt = {
      rotatingScreenId: decryptParamsByUrl?.rotatingScreenId ?? 0,
      page: page,
      perPage: perPage,
    };
    try {
      const requestParams: any = { params: encrypt(paramsForEncrypt) };

      const response = await getRotationScreenViewWithPaginationService.run(requestParams);
      const data = decrypt(response);

      setConfigGeneral(data[0]?.general ?? {});
      setTotalGeneralClases(data[0]?.total);
      setClasses(data[0]?.classes ?? []);

      if (data[0]?.general?.showAdvertising === 1) {
        let newAdversitingDivided = [];
        let newAdversitingCompleted = [];

        // Separar publicidad dividida y completa
        newAdversitingDivided = data[0]?.adversiting.filter((item: any) => item?.orientation === "divided") || [];
        newAdversitingCompleted = data[0]?.adversiting.filter((item: any) => item?.orientation === "complete") || [];

        setAdversitingDivided(newAdversitingDivided);
        setCurrentAdDividedIndex(0); // Reiniciar índice al cargar nuevos datos
        setAdversitingCompleted(newAdversitingCompleted);
      } else {
        setAdversitingDivided([]);
        setAdversitingCompleted([]);
      }
    } catch (error) {
      console.log('Error al ejecutar el servicio de reservas', error);
    } finally {
      setLoadingPageTransition(false);
    }
  };

  // Llamada inicial para obtener configuración y datos
  useEffect(() => {
    getDataRotationScreenViewForConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reiniciar página de clases cuando cambia perPageClases
  useEffect(() => {
    setCurrentPageClases(1);
  }, [perPageClases]);

  // Lógica principal de progreso y visualización
  useEffect(() => {
    if (configGeneral?.id) {
      let duration = 0;
      let remainingTime = 0;

      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }

      setCountProgressBar(0);
      setCountProgressBarAd(0);

      if (displayMode === 'classes') {
        duration = configGeneral.durationTimePerPage * 1000;
        remainingTime = duration;

        progressIntervalRef.current = setInterval(() => {
          remainingTime -= 100;

          // Calcular progreso
          let progress = ((duration - remainingTime) / duration) * 100;
          if (progress > 100) progress = 100;
          setCountProgressBar(progress);

          if (remainingTime <= 0) {
            clearInterval(progressIntervalRef.current!);
            progressIntervalRef.current = null;

            if (configGeneral.intersperseAdvertisingActivities === 1 && adversitingCompleted.length > 0) {
              // Intercalado: mostrar publicidad después de cada página de clases
              setDisplayMode('ad');
            } else {
              // No intercalado: avanzar a la siguiente página de clases
              if (currentPageClases < totalClassPages) {
                setCurrentPageClases(prev => prev + 1);
                fetchClassesData(currentPageClases + 1, perPageClases);
              } else {
                // Si ya se mostraron todas las páginas de clases
                if (configGeneral.intersperseAdvertisingActivities === 0 && adversitingCompleted.length > 0) {
                  setDisplayMode('ad');
                  setCurrentAdCompleteIndex(0);
                } else {
                  // Reiniciar a la primera página de clases
                  setCurrentPageClases(1);
                  fetchClassesData(1, perPageClases);
                }
              }
            }
          }
        }, 100);
      } else if (displayMode === 'ad') {
        const currentAd = adversitingCompleted[currentAdCompleteIndex];
        duration = currentAd.time * 1000;
        remainingTime = duration;

        progressIntervalRef.current = setInterval(() => {
          remainingTime -= 100;

          // Calcular progreso
          let progress = ((duration - remainingTime) / duration) * 100;
          if (progress > 100) progress = 100;
          setCountProgressBarAd(progress);

          if (remainingTime <= 0) {
            clearInterval(progressIntervalRef.current!);
            progressIntervalRef.current = null;

            if (configGeneral.intersperseAdvertisingActivities === 1) {
              // Intercalado: volver a mostrar la siguiente página de clases
              setDisplayMode('classes');

              // Avanzar a la siguiente página de clases
              if (currentPageClases < totalClassPages) {
                setCurrentPageClases(prev => prev + 1);
                fetchClassesData(currentPageClases + 1, perPageClases);
              } else {
                // Reiniciar a la primera página de clases
                setCurrentPageClases(1);
                fetchClassesData(1, perPageClases);
              }

              // Avanzar al siguiente anuncio
              if (currentAdCompleteIndex < adversitingCompleted.length - 1) {
                setCurrentAdCompleteIndex(prev => prev + 1);
              } else {
                setCurrentAdCompleteIndex(0);
              }

            } else {
              // No intercalado: mostrar todos los anuncios seguidos
              // Avanzar al siguiente anuncio
              if (currentAdCompleteIndex < adversitingCompleted.length - 1) {
                setCurrentAdCompleteIndex(prev => prev + 1);
              } else {
                // Si ya se mostraron todos los anuncios
                setDisplayMode('classes');
                setCurrentPageClases(1);
                fetchClassesData(1, perPageClases);
              }
            }
          }
        }, 100);
      }

      return () => {
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMode, currentPageClases, currentAdCompleteIndex, configGeneral]);

  // useEffect para manejar la publicidad dividida independientemente
  useEffect(() => {
    if (adversitingDivided.length > 0) {
      if (dividedAdIntervalRef.current) {
        clearInterval(dividedAdIntervalRef.current);
      }

      const currentAd = adversitingDivided[currentAdDividedIndex];

      let dividedAdDuration = parseFloat(currentAd.time) * 1000;
      if (isNaN(dividedAdDuration) || dividedAdDuration <= 0) {
        console.error('Invalid ad time for divided ad:', currentAd);
        dividedAdDuration = configGeneral.durationTimePerPage * 1000; // Fallback to class duration
      }

      let dividedAdRemainingTime = dividedAdDuration;

      setCountProgressBarAdDivided(0);

      dividedAdIntervalRef.current = setInterval(() => {
        dividedAdRemainingTime -= 100;

        let dividedAdProgress = ((dividedAdDuration - dividedAdRemainingTime) / dividedAdDuration) * 100;
        if (dividedAdProgress > 100) dividedAdProgress = 100;
        setCountProgressBarAdDivided(dividedAdProgress);

        if (dividedAdRemainingTime <= 0) {
          clearInterval(dividedAdIntervalRef.current!);
          dividedAdIntervalRef.current = null;

          setCurrentAdDividedIndex((prevIndex) => (prevIndex + 1) % adversitingDivided.length);
        }
      }, 100);

      return () => {
        if (dividedAdIntervalRef.current) {
          clearInterval(dividedAdIntervalRef.current);
        }
      };

    } else {
      if (dividedAdIntervalRef.current) {
        clearInterval(dividedAdIntervalRef.current);
        dividedAdIntervalRef.current = null;
      }
      setCountProgressBarAdDivided(0);
      setCurrentAdDividedIndex(0);
    }
  }, [currentAdDividedIndex, adversitingDivided, configGeneral.durationTimePerPage]);

  // Renderizado del componente
  return (
    <div className={style.container}>
      <header className={style.header}>
        {/* Primera fila: Logo y fecha/hora */}
        <div className={style.row}>
          <div className={style.logo}>
            <img src={'https://www.unab.cl/footer-19/img/logo-blanco.png'} alt="Logo" />
          </div>
          <div className={style.headerRight}>
            <div className={style.date}>
              <AppGetIcon
                name="calendarV2"
                hoverColor={"#FFF"}
              >
              </AppGetIcon>{formattedDate}
            </div>
            <div className={style.time}>
              <AppGetIcon
                name="clockV2"
                hoverColor={"#FFF"}
              >
              </AppGetIcon> {formattedTime}
            </div>
          </div>
        </div>

        <div className={style.rowStatus}>

          <div className={style.footerTypes}>
            <span className={style.typeLabel}>Tipo:</span>
            <span className={style.curricular}>
              <AppGetIcon
                name="campus"
                classIcon={styleDataTable.sizeIcon}
              >
              </AppGetIcon>
            </span>
            Curricular
            <span className={style.extracurricular}>
              <AppGetIcon
                name="digramg"
                classIcon={styleDataTable.sizeIcon}
              >
              </AppGetIcon>
            </span>
            Extracurricular
          </div>
        </div>
      </header>

      <div className={style.body}>
        {
          loadingViewData || loadingPageTransition ?
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: "30px", fontSize: 'clamp(20px, 4vw, 30px)', lineHeight: '40px' }}>
              <div style={{ marginBottom: '10px' }}>  Cargando....</div>
              <Loading />
            </div> :
            <>
              {displayMode === 'classes' && (
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className={style.contentTable} style={{ width: adversitingDivided.length > 0 ? '70%' : '100%' }}>
                    {/* Tabla de clases */}
                    <table className={style.tableReport} style={{ tableLayout: 'auto' }}>

                      <thead>
                        <tr>
                          <th>CAMPUS</th>
                          <th>EDIFICIO</th>
                          <th>SALÓN</th>
                          <th>CURSO</th>
                          <th>INICIO - FIN</th>
                          <th>PROFESOR</th>
                          <th>TIPO</th>
                          <th>ESTADO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classes.length > 0 ? (
                          classes.map((clase: any, index: any) => (
                            <tr key={index} className={clase.reasonId === 10 ? style.rowClaseSuspendida : ''}>
                              <td >
                                {clase.remplacementCampusId ?
                                  <span className={style.shipsChagenRoom}>
                                    {clase.remplacementCampusId}
                                  </span>
                                  :
                                  <span>
                                    {clase.campusId}
                                  </span>

                                }
                              </td>
                              <td>
                                {clase.remplacementBuildingId ?
                                  <span className={style.shipsChagenRoom}>
                                    {clase.remplacementBuildingId}
                                  </span>
                                  :
                                  <span>
                                    {clase.buildingId}
                                  </span>

                                }
                              </td>
                              <td>
                                {clase.remplacementRoomId ?
                                  <span className={style.shipsChagenRoom}>
                                    {clase.remplacementRoomId}
                                  </span>
                                  :
                                  <span>
                                    {clase.roomCode}
                                  </span>
                                }
                              </td>
                              <td>{clase.termCode}</td>
                              <td>{clase.startTime + " - " + clase.endTime}</td>
                              <td>
                                {clase.replacementTeacherName ?
                                  <span className={style.ships}>
                                    {clase.replacementTeacherName}
                                  </span>
                                  :
                                  <span>
                                    {clase.teacherName}
                                  </span>
                                }
                              </td>
                              <td style={{ padding: '5px' }}>
                                <div className={style.iconsTable}>
                                  <span
                                    className={
                                      clase.type === 1 ? style.curricular :
                                        clase.type === 2 ? style.extracurricular :
                                          style.cancelado
                                    }
                                  >
                                    <AppGetIcon
                                      name={
                                        clase.type === 1 ? "campus" : 'digramg'
                                      }
                                      classIcon={styleDataTable.sizeIcon}
                                      hoverColor={"#FFF"}
                                      strokeColor="#FFF"
                                    />
                                  </span>
                                </div>
                              </td>
                              <td style={{ padding: '5px' }}>
                                <div className={style.iconsTable}>
                                  <span
                                    className={
                                      clase.reasonId === 4 ? style.estadoSalidaTerreno :
                                        clase.reasonId === 5 ? style.estadoDocenteAtrasado :
                                          clase.reasonId === 6 ? style.estadoCambioModalidad :
                                            clase.reasonId === 7 ? style.estadoCambioSala :
                                              clase.reasonId === 8 ? style.estadoIniciada :
                                                clase.reasonId === 9 ? style.estadoFinalizada :
                                                  clase.reasonId === 10 ? style.cancelado :
                                                    clase.reasonId === 11 ? style.cambioDocente :
                                                      clase.finished === 0 ? style.estadoIniciada :
                                                        clase.finished === 1 ? style.estadoFinalizada :
                                                          clase.finished === 2 ? style.estadoReabierta :
                                                            clase.timeStatus === 1 ? style.noIniciado :
                                                              clase.timeStatus === 2 ? style.enCurso :
                                                                clase.timeStatus === 3 ? style.finalizado :
                                                                  ''
                                    }
                                  >
                                    <AppGetIcon
                                      name={
                                        clase.reasonId === 4 ? "images" :
                                          clase.reasonId === 5 ? "personCard" :
                                            clase.reasonId === 6 ? "laptop" :
                                              clase.reasonId === 7 ? "buildingGear" :
                                                clase.reasonId === 8 ? "circleCheck" :
                                                  clase.reasonId === 9 ? "clipBoardCheck" :
                                                    clase.reasonId === 10 ? "xCircle" :
                                                      clase.reasonId === 11 ? "personUp" :
                                                        clase.finished === 0 ? "circleCheck" :
                                                          clase.finished === 1 ? "clipBoardCheck" :
                                                            clase.finished === 2 ? "easel" :
                                                              clase.timeStatus === 1 ? "clockV2" :
                                                                clase.timeStatus === 2 ? "infoCircle" :
                                                                  clase.timeStatus === 3 ? "clipBoardCheck" :
                                                                    ''
                                      }
                                      classIcon={styleDataTable.sizeIcon}
                                      hoverColor={"#FFF"}
                                      strokeColor="#FFF"
                                    />
                                  </span>
                                  {clase.reasonId === 4 ? "Salida terreno" :
                                    clase.reasonId === 5 ? "Docente atrasado" :
                                      clase.reasonId === 6 ? "Cambio a modalidad online" :
                                        clase.reasonId === 7 ? "Cambio de sala" :
                                          clase.reasonId === 8 ? "Iniciada" :
                                            clase.reasonId === 9 ? "Finalizada" :
                                              clase.reasonId === 10 ? "Cancelada" :
                                                clase.reasonId === 11 ? "Cambio docente" :
                                                  clase.finished === 0 ? "Iniciada" :
                                                    clase.finished === 1 ? "Finalizada" :
                                                      clase.finished === 2 ? "Reabierta" :
                                                        clase.timeStatus === 1 ? "No iniciada" :
                                                          clase.timeStatus === 2 ? "En curso" :
                                                            clase.timeStatus === 3 ? "Finalizada" :
                                                              ''}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : null}
                      </tbody>

                    </table>
                    <ProgressLineTime progress={countProgressBar} />
                    {/* Indicador de página de clases */}
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                      <div className={style.pageIndicator}>
                        Página {currentPageClases} de {totalClassPages}
                      </div>
                    </div>
                  </div>
                  {
                    adversitingDivided.length > 0 &&
                    <div className={style.AdversitingContainer} style={{ width: '30%' }}>
                      <div className={style.advertising}>
                        {/* Mostrar publicidad dividida */}
                        {adversitingDivided[currentAdDividedIndex]?.type === "image" ? (
                          <img src={adversitingDivided[currentAdDividedIndex].url} alt={adversitingDivided[currentAdDividedIndex].fileName} style={{ width: '100%' }} />
                        ) : (
                          <video src={adversitingDivided[currentAdDividedIndex].url} autoPlay muted style={{ width: '100%' }} />
                        )}
                        <ProgressLineTime progress={countProgressBarAdDivided} />
                      </div>
                    </div>
                  }
                </div>
              )}
              {displayMode === 'ad' && (
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className={style.AdversitingContainer} style={{ width: '100%' }}>
                    <div className={style.advertising}>
                      {/* Mostrar publicidad completa */}
                      {adversitingCompleted[currentAdCompleteIndex]?.type === "image" ? (
                        <img src={adversitingCompleted[currentAdCompleteIndex].url} alt={adversitingCompleted[currentAdCompleteIndex].fileName} style={{ width: '100%' }} />
                      ) : (
                        <video src={adversitingCompleted[currentAdCompleteIndex].url} autoPlay muted style={{ width: '100%' }} />
                      )}
                      <ProgressLineTime progress={countProgressBarAd} />
                      {/* Indicador de página de publicidad */}
                      <div style={{ display: 'flex', justifyContent: 'center', }}>

                        <div className={style.pageIndicator}>
                          Publicidad {currentAdCompleteIndex + 1}/{totalAdPages}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
        }
      </div>
    </div>
  );
}
