// store.ts
import { createWithEqualityFn } from 'zustand/traditional';

import { persist } from 'zustand/middleware';

interface DataPaginate {
  data: any[];  // Considera tipar esto más específicamente si conoces la estructura de los datos
  page?: number;
  perPage?: number;
  total?: number;
}

interface StoreState {
  data: DataPaginate;
  isLoading: boolean;
  search: string;
  selectedItems: any[];  // Nueva variable para guardar los elementos seleccionados

}

interface StoreActions {
  setData: (newData: DataPaginate) => void;
  setIsLoading: (isLoading: boolean) => void;
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  fetchData: (service: any, params: any) => Promise<void>;
  setSelectedItems: (items: any[]) => void;  // Nueva acción para actualizar los elementos seleccionados
  addSelectedItem: (item: any) => void;      // Nueva acción para añadir un elemento seleccionado
  removeSelectedItem: (item: any) => void;   // Nueva acción para eliminar un elemento seleccionado
  clearStore: () => void;
}

export const useDataTableStore = createWithEqualityFn<StoreState & StoreActions>()(

  persist((set, get) => ({
    data: { data: [], page: 1, perPage: 10, total: 0 },
    isLoading: false,
    search: '',
    selectedItems: [],  // Inicializa la variable selectedItems
    setData: (newData) => set({ data: newData }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setSearch: (search) => set({ search }),
    setPage: (page) => set(state => ({ data: { ...state.data, page } })),
    setPerPage: (perPage) => set(state => ({ data: { ...state.data, perPage } })),
    fetchData: async (service, params) => {
      set({ isLoading: true });
      try {
        const response = await service.run({ ...params, page: get().data.page, perPage: get().data.perPage, search: get().search });
        if (response) {
          set({
            data: {
              data: response.data,
              page: response.page,
              perPage: response.perPage,
              total: response.total
            },
            isLoading: false
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        set({ isLoading: false });
      }
    },
    setSelectedItems: (items) => set({ selectedItems: items }),  // Implementa la acción para actualizar selectedItems
    addSelectedItem: (item) => set((state) => ({
      selectedItems: [...state.selectedItems, item]
    })),  // Implementa la acción para añadir un elemento seleccionado
    removeSelectedItem: (item) => set((state) => ({
      selectedItems: state.selectedItems.filter((i) => i.id !== item)
    })),  // Implementa la acción para eliminar un elemento seleccionado
    clearStore: () => set({
      data: { data: [], page: 1, perPage: 10, total: 0 }, isLoading: false, search: '', selectedItems: [],  // Limpia la variable selectedItems
    }, true)
  }), {
    name: "pagination-store",
    getStorage: () => localStorage
  }));

