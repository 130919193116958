import { CardHeader } from "../CardHeader"

const Card = ({
  title,
  buttonToBack,
  handleGoToback,
  buttonToEdit,
  handleGoToEdit,
  titlePosition,
  titlePadding,
  backgroundColor,
  titleColor,
  lineUnderTitle,
  footerContent,
  children,
  buttomSearch,
  handleSearchChange,
  handleSearchClear,
  selectOptions,
  handleSelectChange,
  scroll,
  searchHeader
}: propsCard) => {
  return (
    <>
      <CardHeader
        buttomSearch={buttomSearch}
        buttonToBack={buttonToBack}
        handleGoToback={handleGoToback}
        buttonToEdit={buttonToEdit}
        handleGoToEdit={handleGoToEdit}
        handleSearchChange={handleSearchChange}
        handleSearchClear={handleSearchClear}
        title={title ?? ''}
        lineUnderTitle={lineUnderTitle}
        titlePadding={titlePadding}
        titlePosition={titlePosition}
        backgroundColor={backgroundColor}
        titleColor={titleColor}
        selectOptions={selectOptions}
        handleSelectChange={handleSelectChange}
        searchHeader={searchHeader}
      />
      <div className="card-body" style={{ width: '100%', overflow: scroll ? 'auto' : 'unset' }}>{children}</div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>{footerContent}</div>
    </>
  )
};
export default Card;
