import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IAddFile {
	id: number | null | string,
  fileId: any,
  rotatingScreenId: number | string | null ,
  time: number | null  | string,
}

export class CreateOrUpdateAddFileService extends CreateOrUpdateBaseService<IAddFile, any> {
  url = `${services.rotationScreen}/addFile`;
}
