import axios from 'axios';
const API_ENDPOINT = process.env.REACT_APP_PUBLIC_BASE_API_URL;

export const LogouOffice365Service = async () => {
  const response = await axios.post(`${API_ENDPOINT}/logout`, {}, {
    headers: {
      'Access-Control-Allow-Origin': 'https://login.microsoftonline.com'
    }
  });
  return response.data;
};
