import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AppGetIcon from '../../AppGetIcon';

import style from './filterModal.module.css';
import styleForm from '../../../../common/theme/styles/form.module.css';
import styleDataTable from '../../DataTable/dataTable.module.css';


type FilterModalProps = {
  nameIcon: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  handleDeleteFilter?: () => void;
  handleFilter?: () => void;
};

const ConatinerFilter: React.FC<FilterModalProps> = ({ nameIcon, children, onClick, isOpen, onClose, handleDeleteFilter, handleFilter }) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalDimensions, setModalDimensions] = useState(() => {
    const { clientWidth } = document.documentElement;
    const maxWidth = Math.min(920, clientWidth - 40);
    return { width: maxWidth, height: 0 };
  });
  const [showModal, setShowModal] = useState(isOpen);
  const [isHoveredFilter, setIsHoveredFilter] = useState(false);

  // Función para calcular el ancho máximo del modal en función del tamaño de la ventana del navegador
  const setModalMaxWidth = () => {
    const { clientWidth } = document.documentElement;
    const maxWidth = Math.min(920, clientWidth - 250); // Aquí puede ajustar el ancho máximo según sus necesidades
    setModalDimensions(dimensions => ({ ...dimensions, width: maxWidth }));
  };

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const positionTriangle = () => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    const modalOpen = isOpen;
    if (!buttonRect || !modalOpen) {
      if (buttonRef.current) {
        buttonRef.current.classList.add(styleDataTable.triangle);
      }
      return;
    }
    if (buttonRef.current) {
      buttonRef.current.classList.remove(styleDataTable.triangle);
    }
  };

  useEffect(() => {
    positionTriangle();
    window.addEventListener('resize', positionTriangle);
    return () => {
      window.removeEventListener('resize', positionTriangle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Llame a la función setModalMaxWidth en el hook useLayoutEffect
  useLayoutEffect(() => {
    if (showModal) {
      setModalMaxWidth();
      window.addEventListener('resize', setModalMaxWidth);
    }
    return () => {
      window.removeEventListener('resize', setModalMaxWidth);
    };
  }, [showModal]);

  return (
    <div className={style.filter_container}>
      <div className={style.button_container}>
        <div
          className={`${styleDataTable.buttom_option} ${styleDataTable.filter_button}`}
          onClick={onClick}
          onMouseEnter={() => setIsHoveredFilter(true)}
          onMouseLeave={() => setIsHoveredFilter(false)}
          ref={buttonRef}
        >
          <AppGetIcon
            name={nameIcon}
            classIcon={styleDataTable.sizeIcon}
            hoverColor={isHoveredFilter ? '#FFF' : undefined}
          />
          Filtrar
        </div>
      </div>
      {showModal && (
        <>
          <div className='overlay' onClick={handleClose}></div>
          <div
            className={`${style.modal_container} ${isOpen ? 'open' : ''}`}
            ref={modalRef}
            style={{ width: `${modalDimensions.width}px`, minWidth: '250px' }}
          >
            <div className='modal-title'>
              {/* <h2>Filtrar resultados</h2> */}
            </div>
            <div className={`${style.modal_content} ${style.container__filter}`}>{children}
            </div>
            <div className={style.modal_footer}>
              <button className={styleForm.buttomFooter} onClick={handleClose}>Cerrar</button>
              {
                handleDeleteFilter &&
                <button className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar} onClick={handleDeleteFilter}>Borrar Filtro</button>
              }
              {handleFilter &&
                <button className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar} onClick={handleFilter}>Filtrar</button>
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConatinerFilter;