import React, { useState } from 'react';
import * as XLSX from 'sheetjs-style';

import { useSnackbar } from 'notistack';
import style from './dataTable.module.css';
import AppGetIcon from '../AppGetIcon';
import CardLoading from '../CardLoading';
import { toast } from 'react-toastify';



interface ExcelButtonProps {
  service: any;  // Aquí es donde esperamos la firma correcta
  params: any;
  enabled: boolean;
  nameFile: string;
  nameButton: string;
  getExcelData?: (data: any[]) => any[];
  hiddenColumns?: number[];
  lockedColumns?: number[];
  dateColumns?: { index: number; format: string }[];
  excelButton?: {
    enabled: boolean;
    nameFile: string;
    getExcelData: (data: any[]) => any[];
  };
}
const ExcelButton = ({ service, params, enabled, nameFile, nameButton = 'Excel', getExcelData, hiddenColumns, lockedColumns, dateColumns }: ExcelButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isProgressBar, setIsProgressBar] = useState<boolean>(false);
  const [isHoveredExcel, setIsHoveredExcel] = useState(false);

  const downloadExcel = async () => {
    if (!enabled) return;
    if ((!params.startTime && !params.endTime) || (params.startTime && params.endTime)) {
    } else {
      if (!params.startTime && params.endTime) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <AppGetIcon name="xCircleFill" />
            El bloque de inicio es obligatorio si seleccionas un bloque de fin.
          </div>,
          { icon: false }
        );
      } else if (params.startTime && !params.endTime) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <AppGetIcon name="xCircleFill" />
            El bloque de fin es obligatorio si seleccionas un bloque de inicio.
          </div>,
          { icon: false }
        );
      }
      return;
    }
    setIsProgressBar(true);
    try {
      const response = await service.run(params);
      let dataExcel = response;
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <AppGetIcon name="checkCircleFill" />
          {response}
        </div>,
        { icon: false }
      );
      if (getExcelData) {
        dataExcel = getExcelData(dataExcel);
      }

      if (dataExcel.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dataExcel, {
          cellDates: true,
          dateNF: 'dd-MM-yyyy',
        });

        const rangeRef = worksheet['!ref'] ?? 'A1';
        const range = XLSX.utils.decode_range(rangeRef);

        // Define el mapeo de colores para cada estado
        const estadoColors = {
          "Salida terreno": "d1956a",       // Ejemplo: Amarillo claro
          "Docente atrasado": "fd7e14",     // Ejemplo: Rojo claro
          "Cambio a modalidad online": "20c997",  // Ejemplo: Azul claro
          "Cambio de sala": "17a2b8",       // Ejemplo: Verde claro
          "Iniciada": "17a2b8",             // Ejemplo: Amarillo
          "Finalizada": "ffc107",           // Ejemplo: Verde
          "Cancelada": "6c757d",            // Ejemplo: Rojo
          "Cambio docente": "ffc107",       // Ejemplo: Naranja
          "No iniciada": "ffc107",          // Ejemplo: Gris claro
          "En curso": "28a745",             // Ejemplo: Amarillo claro
          "Reabierta": "ffc107"             // Ejemplo: Azul claro
        };

        // Aplicar estilos a la primera fila (títulos)
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = {
              font: { bold: true, color: { rgb: "000000" } },
              fill: { fgColor: { rgb: "D3D3D3" } },
            };
          }
        }

        // Aplicar colores según el estado en la columna "ESTADO"
        for (let R = 1; R <= range.e.r; ++R) {
          const estadoCell = worksheet[XLSX.utils.encode_cell({ c: 11, r: R })]; // Asume que "ESTADO" está en la columna J (índice 9)

          if (
            estadoCell &&
            estadoCell.v &&
            Object.keys(estadoColors).includes(estadoCell.v)
          ) {
            estadoCell.s = {
              font: { color: { rgb: "000000" } }, // Texto negro
              fill: { fgColor: { rgb: estadoColors[estadoCell.v as keyof typeof estadoColors] } }
            };
          }

        }

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${nameFile}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        enqueueSnackbar('Excel descargado correctamente.', { variant: 'success' });
      } else {
        enqueueSnackbar('No se encontró información para descargar', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
    } finally {
      setIsProgressBar(false);
    }
  };

  return (
    <div>
      <div
        className={style.buttom_option}
        onMouseEnter={() => setIsHoveredExcel(true)}
        onMouseLeave={() => setIsHoveredExcel(false)}
        onClick={downloadExcel}
      >
        <AppGetIcon
          name='excel'
          classIcon={style.sizeIcon}
          hoverColor={isHoveredExcel ? '#FFF' : undefined}
        />
        {nameButton}
      </div>
      {isProgressBar &&
        <div>
          <CardLoading nameIcon='excel' title={`Exportando ${nameFile}`} />
        </div>
      }
    </div>
  );
};

export default ExcelButton;
