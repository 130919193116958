import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from "zustand/middleware";
import { createJSONStorage } from 'zustand/middleware';

interface userInterface {
  sidebarClose: boolean;
  navSelected: number;
  expandedSubMenu: number;
  subItemSelected: number;
  active: number | null;

  updatesidebarClose: (newSidebarClose: boolean) => void;
  updatenavSelected: (newNavSelected: number) => void;
  updateexpandedSubMenu: (newExpandendSubMenu: number) => void;
  updatesubItemSelected: (newSubItemSelected: number) => void;
  updateactive: (newActive: number | null) => void;

  clearStore: () => void;
}
// Crear un almacenamiento personalizado
const storage = createJSONStorage(() => localStorage);

export const useSidebarStore = createWithEqualityFn<userInterface>()(
  persist(
    (set) => ({
      sidebarClose: false,
      navSelected: 0,
      expandedSubMenu: 0,
      subItemSelected: 0,
      active: null,

      updatesidebarClose: (newSidebarClose: boolean) => set({ sidebarClose: newSidebarClose }),
      updatenavSelected: (newNavSelected: number) => set({ navSelected: newNavSelected }),
      updateexpandedSubMenu: (newExpandendSubMenu: number) => set({ expandedSubMenu: newExpandendSubMenu }),
      updatesubItemSelected: (newSubItemSelected: number) => set({ subItemSelected: newSubItemSelected }),
      updateactive: (newActive: number | null) => set({ active: newActive }),

      clearStore: () => {
        set({}, true);
      },

    }),
    {
      name: "sidebar-storage",
      storage
    }
  )
);