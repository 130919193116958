import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IUploadFile {
	id: number | null | string,
  file: any,
  url: string | null,
  orientation: null | string,
  rotatingScreenId: number | string | null ,
  time: number | null  | string,
  type?:string | null,
}

export class CreateOrUpdateUplaodFileService extends CreateOrUpdateBaseService<IUploadFile, any> {
  url = `${services.rotationScreen}/uploadFile`;
  isFormData= true 
}
