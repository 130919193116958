import { ErrorMessage, useField } from "formik";
import style from '../inputs.module.css';

interface TextInputInterface {
  type: string;
  name: string;
  placeholder?: string;
  TextLabel?: string;
  disabled?: boolean;
  handleBlur?: (value: string) => void;
  onValueChange?: (value: string) => void;
  options?: Array<{ key: string, value: string }>;
  classNameInput?: string;
}

const InputText = ({
  type,
  name,
  placeholder,
  TextLabel,
  disabled,
  handleBlur,
  onValueChange,
  options,
  classNameInput
}: TextInputInterface) => {
  const [field, , helpers] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.value;
    helpers.setValue(value);

    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <div className={style.container__input}>
      <label htmlFor={name} className={style.input__label}>
        {TextLabel}
      </label>
      {type === 'select' ? (
        <select
          className={classNameInput ? classNameInput : style.input__input}
          name={name}
          disabled={disabled}
          value={field.value}
          onChange={handleChange}
          onBlur={() => {
            helpers.setTouched(true);
            if (handleBlur) {
              handleBlur(field.value);
            }
          }}
        >
          <option value="">{placeholder}</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          ))}
        </select>
      ) : (
        <input
          className={`${classNameInput ? classNameInput : style.input__input} ${disabled ? style.Disabled__input : ''}`}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={field.value}
          onChange={handleChange}
          onBlur={() => {
            helpers.setTouched(true);
            if (handleBlur) {
              handleBlur(field.value);
            }
          }}
        />
      )}
      <div className={style.containerError}>
        <ErrorMessage
          className={style.messageError}
          name={name}
          component="div"
        />
      </div>
    </div>
  );
};

export default InputText;
