import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppGetIcon from '../AppGetIcon';
import style from './outlineButtom.module.css';

export interface PropsButton {
  textButton: string;
  color?: string;
  textColor?: string;
  hoverColor?: string;
  iconName?: string;
  classNameButton?: string;
  goToRedirect?: string;
  onClick?: (value?: any) => void;
};

export const OutlineButton = ({
  textButton,
  color,
  textColor,
  hoverColor,
  iconName,
  classNameButton,
  goToRedirect,
  onClick
}: PropsButton) => {
  const [isHovered, setIsHovered] = useState(false);
  const myStyle = {
    backgroundColor: isHovered ? hoverColor : color,
    color: textColor,
    border: `1px solid ${textColor}`
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  if (goToRedirect) {
    return (
      <Link
        className={style.buttonContainer + " " + classNameButton}
        style={myStyle}
        to={goToRedirect}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {
          iconName &&
          // <AppGetIcon name={iconName} hoverColor={isHovered ? '#FFFFFF' : undefined} />
          <AppGetIcon name={iconName} classIcon={style.iconButon} strokeColor={textColor}  />
        }
        {textButton}
      </Link>
    );
  } else {
    return (
      <button
        className={style.buttonContainer + " " + classNameButton}
        style={myStyle}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {
          iconName &&
          <AppGetIcon name={iconName} classIcon={style.iconButon} strokeColor={textColor}/>
        }

        {textButton}
      </button>
    );
  }
};