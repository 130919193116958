import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IAdministrationRotation {
	id: number | null | string,
  name: null | string,
    campusId: null | string,
    buildingIds?: null | any | [],
    visualizationActivityBefore:number | null | string,
    visualizationActivityAfter:number | null | string,
    numberRows:number | null | string,
    durationTimePerPage:number | null | string,
    showAdvertising: number | null | boolean,
    intersperseAdvertisingActivities: number | null | boolean,
    splitScreenAdvertising: number | null | boolean,
    createdById: any,
    updatedById: number | null | string,
    url: any,
}

export class CreateOrUpdateAdministrationRotationScreenService extends CreateOrUpdateBaseService<IAdministrationRotation, any> {
  url = `${services.rotationScreen}/createOrUpdaterotationScreen`; 
}
