import { useState } from "react";
import style from "./card.module.css"
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import Card from "../../../../common/UI/Card";
import { Tooltip } from "react-tooltip";
import usePermission from "../../../../common/hooks/usePermission";
const CardRoles = ({
  title,
  titlePosition,
  description,
  backgroundColor,
  titleColor,
  handleEdit,
  handlePermissions
}: PropsCardRoles) => {
  const canEditRole = usePermission('booking.roles', 'edit');
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  return (
    <div className={style.card}>
      <Card
        title={title}
        titlePosition={titlePosition}
        titlePadding="10px"
        backgroundColor={backgroundColor}
        titleColor={titleColor}
        footerContent={
          <div className={style.container_butoms}>
            {
              canEditRole &&
              <>
                <button
                  className={`${style.footer_butom} ${+ (title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante')) ? style.blocked : ''}`}
                  onClick={handlePermissions}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  disabled={title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante')}
                  data-tooltip-id={'my-tooltip-trash'} data-tooltip-content={`Gestionar permisos`}
                >
                  <Tooltip id="my-tooltip-trash" style={{
                    wordBreak: 'keep-all',
                    overflowWrap: 'break-word', /* Permite que las palabras se rompan y pasen a la siguiente línea si es necesario */
                    hyphens: 'manual', /* Evita los guiones automáticos */
                    backgroundColor: "#41545C", color: "#FFFFFF", maxWidth: '500px', zIndex: 10, fontWeight: 'bold'
                  }} />
                  <AppGetIcon
                    name="clipboard-check"
                    fillColor={title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante') ? "#CFCFCF" : "#962330"}
                    hoverColor={isHovered ? '#FFFFFF' : undefined}
                  />
                </button>

                <button
                  className={`${style.footer_butom} ${+ (title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante')) ? style.blocked : ''}`}
                  onClick={handleEdit}
                  onMouseEnter={() => setIsHoveredEdit(title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante') ? false : true)}
                  onMouseLeave={() => setIsHoveredEdit(false)}
                  disabled={title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante')}
                  data-tooltip-id={'my-tooltip'} data-tooltip-content={`Editar`}
                >
                  <AppGetIcon
                    name="pencil"
                    strokeColor={title.toLowerCase().includes('administrador') || title.toLowerCase().includes('docente') || title.toLowerCase().includes('estudiante') ? "#CFCFCF" : "#962330"}
                    hoverColor={isHoveredEdit ? '#FFFFFF' : undefined}
                  />
                  <Tooltip id="my-tooltip" style={{
                    wordBreak: 'keep-all',
                    overflowWrap: 'break-word', /* Permite que las palabras se rompan y pasen a la siguiente línea si es necesario */
                    hyphens: 'manual', /* Evita los guiones automáticos */
                    backgroundColor: "#41545C", color: "#FFFFFF", maxWidth: '500px', zIndex: 10, fontWeight: 'bold'
                  }} />
                </button>
              </>
            }

          </div>
        }
      >
        <div className={style.container_body}>
          {description}
        </div>
      </Card>
    </div>
  )
};
export default CardRoles;
