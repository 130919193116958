import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingInformation from "../../common/UI/LoadingInformation";

export default function LoginByToken() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { login, isAuthenticated, loading } = useAuth();

  const [messageDescription, setMessageDescription] = useState('');
  const [, setOpenModal] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<number | null>(null);

  // Obtener el token de los parámetros de la URL
  const token: string = searchParams.get("token") ?? '';
  const REACT_APP_ENV_DEV = process.env.REACT_APP_ENV_DEV ?? '';

  useEffect(() => {
    if (REACT_APP_ENV_DEV && !isAuthenticated && token) {
      // login(token);
      localStorage.setItem('authToken', token);
      document.cookie = `isAuthenticated=true; path=/; SameSite=None; Secure;`;
      document.cookie = `authToken=${token}; path=/; SameSite=None; Secure`;
    } else if (!token) {
      // navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorType(null);
    setMessageDescription('');
  };

  return (
    <div className="login-by-token-page">
      <LoadingInformation
        name={''}
        description={messageDescription}
        isOpen={true}
        onClose={handleCloseModal}
        isLoading={loading}
        responseStatus={errorType}
      />
    </div>
  );
}
