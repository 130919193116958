
import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";

export interface paramsReport {
  campusId: string,
  buildingId: string,
  roomCode: string,
  date: string,
  type: number,
  startTime: number,
  endTime: number,
  NRC: string,
  termCode: number | null
}

export class GetReportAsistanceTeachingViewService {
  async run(params: paramsReport) {
    const response = await getByQuery<any>(`${services.bookingReport}/getTeachersAttendance`, params);
    return response;

  };
}

