// theme.ts
export interface Theme {
  palette: {
    primary: {
      main: string;
      light: string;
    };
    secondary: {
      main: string;
      light: string;
    };
    // Considera agregar tipos para otros colores si son necesarios
  };
  typography?: {
    fontSize: string;
    fontFamily: string;
    // Agrega aquí otras propiedades de tipografía según sea necesario
  };
  // Agrega aquí otras propiedades del tema según sea necesario
}


export const defaultTheme = {
  palette: {
    primary: {
      main: '#007bff',
      light: '#42a5f5',
    },
    secondary: {
      main: '#f44336',
      light: '#e57373',
    },
    // Más colores...
  },
  typography: {
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    // Más propiedades de tipografía...
  },
  // Más propiedades del tema...
};