import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo_u_andres_bello.png';
import style from './Login.module.css'
import AppGetIcon from '../../common/UI/AppGetIcon';

const LoginPage = () => {
  const API_ENDPOINT = process.env.REACT_APP_PUBLIC_BASE_API_QR_URL;
  return (
    <div className={style.container}>
      <div className={style.adminLogin__container}>
        <div className={style.login_container}>
          <div className={style.card}>
            <div className={style.containerLogo}>
              <img src={logo} alt="Logo of the app" />
            </div>
            <div className={style.title}>¡Bienvenido(a)!</div>
            <div className={style.selected_user}>Selecciona tu Usuario</div>

            <div className={style.cardBody}>
              <Link
                to={`${API_ENDPOINT}/office365/login/portal`}
                className={style.buttomSend}
              >
                <span>
                  <AppGetIcon name='teacher' classIcon={style.login__form} fillColor="#962330" />
                </span>Ingreso Profesores y Estudiantes
              </Link>
              <Link
                to={`${API_ENDPOINT}/office365/admin/login`}
                className={style.buttomSend}
              >
                <span>
                  <AppGetIcon name='userOffice' classIcon={style.login__form} fillColor="#962330" />
                </span>Ingreso Administrativo
              </Link>
            </div>
          </div>
        </div>

        <div className={style.image_container}>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
