import React from 'react';
import style from '../inputs.module.css';

interface SearchInputInterface {
  name: string;
  value?: string;  // Nuevo prop para el valor inicial
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  onSearchChange?: (value: string) => void;  // Callback para manejar el cambio
  classNameInput?: string;
}

const InputSearchFilter = ({
  name,
  value,  // Nuevo valor recibido del estado
  placeholder,
  label,
  disabled,
  onSearchChange,
  classNameInput
}: SearchInputInterface) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (onSearchChange) {
      onSearchChange(newValue); // Actualiza el estado con el nuevo valor
    }
  };

  return (
    <div className={style.container__input}>
      {label && (
        <label htmlFor={name} className={style.input__label}>
          {label}
        </label>
      )}
      <input
        className={`${classNameInput ? classNameInput : style.input__input} ${disabled ? style.Disabled__input : ''}`}
        type="text"
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}  // Asigna el valor actual del estado
        onChange={handleChange}
      />
    </div>
  );
};

export default InputSearchFilter;

