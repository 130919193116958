import { useEffect, useState } from "react";
import { useDataTableStore } from "../../../../store/appDataItems.storage";

import AppGetIcon from "../../../../common/UI/AppGetIcon";
import Card from "../../../../common/UI/Card";

import style from "./card.module.css";

import { CreateOrUpdatedcreateOrUpdatedEnableCampusService } from "../../services/EnabledCampus/createOrUpdatedEnableCampus.service";

const createOrUpdatedcreateOrUpdatedEnableCampusService = new CreateOrUpdatedcreateOrUpdatedEnableCampusService();

interface CardEnabledCampusProps extends PropsEnabledCampus {
  forceUpdate?: boolean;
  showCheckbox?: boolean;
  refreshKey?: (value: any) => void;
}

const CardEnabledCampus = ({
  titlePosition,
  backgroundColor,
  titleColor,
  campusId,
  enabled,
  showCheckbox,
  refreshKey
}: CardEnabledCampusProps) => {

  const {addSelectedItem, removeSelectedItem } = useDataTableStore();

  // Estado del checkbox para reflejar enabled inicial y cambios del usuario
  const [isChecked, setIsChecked] = useState<boolean>(enabled === 1);

  useEffect(() => {
    setIsChecked(enabled === 1);
  }, [enabled]);

  const handleSave = async (newStatus: number) => {
    const dataCreated: any = [
      {
        campusId: campusId,
        enabled: newStatus,
      }
    ];
    await createOrUpdatedcreateOrUpdatedEnableCampusService.run(dataCreated);
    refreshKey && refreshKey(Math.random());
  };

  const handleCheckboxChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);

    if (newChecked) {
      addSelectedItem({ id: campusId, enabled: 1 });
      handleSave(1); // Envía `enabled: 1` cuando se activa
    } else {
      removeSelectedItem(campusId);
      handleSave(0); // Envía `enabled: 0` cuando se desactiva
    }
  };

  return (
    <div className={style.card}>
      <Card 
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
              <div>{`Campus: ${campusId}`}</div>


            </div>
            {showCheckbox && (
              <input
                type="checkbox"
                className={style.checkbox}
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
            )}
          </div>
        }
        titlePosition={titlePosition}
        titlePadding="10px"
        backgroundColor={backgroundColor}
        titleColor={titleColor}
      >
        <div className={style.container_body}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <p>
              <div style={{ display: 'flex',flexDirection:'column' , justifyContent: 'space-between', alignItems: 'center', gap: '5px' }}>
                {isChecked ? (
                  <>
                  <AppGetIcon
                      name="checkCircleFill"
                    />
                    <div>
                      El campus {campusId} está <strong>habilitado</strong> y disponible para reservas de estudiantes.
                    </div>
                    
                  </>
                ) : (
                  <>
                  <AppGetIcon
                      name="xCircleFill"
                      strokeColor="rgb(150, 35, 48)"
                      fillColor="rgb(150, 35, 48)"
                    />
                    <div>
                      El campus {campusId} está temporalmente <strong>deshabilitado</strong> y no está disponible para nuevas reservas en este momento.
                    </div>
                    
                  </>
                )}
              </div>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );

};

export default CardEnabledCampus;
