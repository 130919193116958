import { shallow } from 'zustand/shallow';
import { useSidebarStore } from '../../../store/sidebar.store';
import './sideBar.css';
import { Link, useLocation } from 'react-router-dom';
import { useHeaderStore } from '../../../store/header.store';
import AppGetIcon from '../AppGetIcon';
import { ItemDto } from '../../dtos/initData.dto';
import { useEffect } from 'react';

interface sidebarProps {
  itemsSideBar: ItemDto[];
}

export default function SideBar({ itemsSideBar }: sidebarProps) {
  const location = useLocation();

  const REACT_APP_ENV_DEV = process.env.REACT_APP_ENV_DEV ?? '';

  const headerStore = useHeaderStore(
    (state) => ({
      module: state.module,
      moduleItem: state.moduleItem,
      actionFilter: state.actionFilter,
      setModule: state.setModule,
      setModuleItem: state.setModuleItem,
    }),
    shallow
  );

  const sidebarStorage = useSidebarStore(
    (state) => ({
      sidebarClose: state.sidebarClose,
      navSelected: state.navSelected,
      expandedSubMenu: state.expandedSubMenu,
      subItemSelected: state.subItemSelected,
      active: state.active,
      updatesidebarClose: state.updatesidebarClose,
      updatenavSelected: state.updatenavSelected,
      updateexpandedSubMenu: state.updateexpandedSubMenu,
      updatesubItemSelected: state.updatesubItemSelected,
      updateactive: state.updateactive,
    }),
    shallow
  );

  // Updated getRoute function to match the logic in the first component
  const getRoute = (item: ItemDto) => {
    const isDev = REACT_APP_ENV_DEV === 'true';
    const route = item.route ?? '';
    const domain = item.domainUrl ?? '';

    if (isDev) {
      // In development, we use only the relative route
      return route;
    } else {
      // In production, we use the domain and the route
      return `${domain}${route}`;
    }
  };

  // Updated useEffect to match the logic in the first component
  useEffect(() => {
    let foundMatchingItem = false;

    itemsSideBar.forEach((module) => {
      module.children?.forEach((subItem) => {
        const itemRoute = subItem.route;
        const domainUrl = subItem.domainUrl;

        // Extract only the relative path in production
        let routeToCompare = '';

        if (REACT_APP_ENV_DEV === 'true') {
          routeToCompare = itemRoute ?? '';
        } else {
          // In production, extract the relative path from the full URL
          const fullUrl = `${domainUrl ?? ''}${itemRoute ?? ''}`;
          try {
            const url = new URL(fullUrl);
            routeToCompare = url.pathname;
          } catch (error) {
            // If it's not a valid URL, use the route as is
            routeToCompare = itemRoute ?? '';
          }
        }

        if (location.pathname === routeToCompare) {
          // Select the corresponding subitem by id
          sidebarStorage.updatesubItemSelected(subItem.id);
          headerStore.setModuleItem(subItem.name, subItem.route ?? '', subItem.domainUrl);

          // Select and expand the parent module
          sidebarStorage.updatenavSelected(module.id);

          if (!sidebarStorage.sidebarClose) {
            // Expand submenu if sidebar is open
            sidebarStorage.updateexpandedSubMenu(module.id);
          } else {
            // Do not expand submenu if sidebar is closed
            sidebarStorage.updateexpandedSubMenu(-1);
          }

          headerStore.setModule(module.name, module.route ?? '');

          foundMatchingItem = true;
        }
      });
    });

    if (!foundMatchingItem) {
      sidebarStorage.updatenavSelected(-1);
      sidebarStorage.updateexpandedSubMenu(-1);
      sidebarStorage.updatesubItemSelected(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, itemsSideBar, sidebarStorage.sidebarClose]);

  return (
    <>
      {sidebarStorage.active && sidebarStorage.sidebarClose && (
        <div className="overlay" onClick={() => sidebarStorage.updateactive(null)}></div>
      )}
      <div className={`sidebar ${sidebarStorage.sidebarClose ? 'sidebar--close' : ''}`}>
        <div className={`buttons ${sidebarStorage.sidebarClose ? 'buttons--close' : ''}`}>
          <button
            className="button"
            onClick={() => {
              sidebarStorage.updatesidebarClose(!sidebarStorage.sidebarClose);
              sidebarStorage.updateactive(null);
            }}
          >
            <AppGetIcon name="arrow-right" strokeColor="#FFFFFF" />
          </button>
        </div>

        <ul className="nav-links">
          {itemsSideBar
            .filter((item: ItemDto) => item.showMenu === 1)
            .map((nav: ItemDto) => (
              <li className="nav-sub-menu-li" key={nav.id}>
                {sidebarStorage.sidebarClose && (
                  <>
                    <div className="nav-hover">{nav.name}</div>
                    <ul
                      className={`nav-hover-view-menu ${sidebarStorage.active === nav.id ? 'nav-hover-view-menu--active' : ''
                        }`}
                    >
                      {nav.children
                        ?.filter((item) => item.showMenu === 1)
                        .map((item: ItemDto) => (
                          <li key={item.id}>
                            <Link
                              to={getRoute(item)}
                              className={`sub-menu-item ${sidebarStorage.subItemSelected === item.id ? 'sub-menu-item--selected' : ''
                                }`}
                              onClick={() => {
                                sidebarStorage.updatesubItemSelected(item.id);
                                sidebarStorage.updateactive(null);
                                headerStore.setModuleItem(
                                  item.name,
                                  item.route ? getRoute(item) : '',
                                  item.domainUrl
                                );
                              }}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </>
                )}

                <div
                  className={`nav-item ${sidebarStorage.navSelected === nav.id ? 'nav--selected' : ''}`}
                  onClick={() => {
                    sidebarStorage.updatenavSelected(nav.id);
                    sidebarStorage.updateactive(nav.id);
                    sidebarStorage.updateexpandedSubMenu(nav.id);
                    headerStore.setModule(nav.name, nav.route ? getRoute(nav) : '');
                  }}
                >
                  <div className="item-container-name">
                    <AppGetIcon
                      name={nav.icon}
                      classIcon="size-icon-sidebar"
                      fillColor={`${sidebarStorage.navSelected === nav.id ? '#FFFFFF' : '#5B5F62'}`}
                    />
                    <div className="item-name">{nav.name}</div>
                  </div>
                  {nav.children && nav.children.length > 0 && (
                    <Link
                      to={getRoute(nav)}
                      className="container-icon--arrow"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from propagating to parent div
                        headerStore.setModuleItem(
                          nav.name,
                          nav.route ? getRoute(nav) : '',
                          nav.domainUrl
                        );
                      }}
                    >
                      <AppGetIcon
                        className={`arrow ${sidebarStorage.expandedSubMenu === nav.id ? 'arrow--expanded' : ''
                          } size-icon-sidebar`}
                        name="arrow-right"
                      />
                    </Link>
                  )}
                </div>

                {!sidebarStorage.sidebarClose && (
                  <ul
                    className={`sub-menu ${sidebarStorage.expandedSubMenu === nav.id ? 'sub-menu--expanded' : ''
                      }`}
                  >
                    {nav.children
                      ?.filter((item) => item.showMenu === 1)
                      .map((item: ItemDto) => (
                        <li key={item.id}>
                          <Link
                            to={getRoute(item)}
                            className={`sub-menu-item ${sidebarStorage.subItemSelected === item.id ? 'sub-menu-item--selected' : ''
                              }`}
                            onClick={() => {
                              sidebarStorage.updatesubItemSelected(item.id);
                              headerStore.setModuleItem(
                                item.name,
                                item.route ? getRoute(item) : '',
                                item.domainUrl
                              );
                            }}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
