import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IClass {
  id: number | string | null;
  reasonId: any;
  reasonName: string | any;
  creatorUserId: number | null;
  attend: number | null;
  finished: number | null;
  replacementTeacherId: number | any;
  teacherNameString: string | any;
  replacementTeacherName: string | any;
  minutesLate: any;
  timeLate: any;
  minutesEarly: any;
  timeEarly: any;
  editorUserId: number | null;
  NRC: number | null;
  roomId: string | null;
  roomCode?: string | null;
  buildingId: string | null;
  campusId: string | null;
  termCode: string | null;
  dateSince: any | null;
  dateUntil: any | null;
  startTime: string | null;
  endTime: string | null;
  remplacementCampusId: string | any;
  remplacementBuildingId: string | any;
  remplacementRoomId: string | any;
  observations: string | null;
  type: any | null;
  updatedClassId: number | null;
}


export class CreateOrUpdateDashboardScreendViewService extends CreateOrUpdateBaseService<IClass, any> {
	url = `${services.updatedClass}/createOrUpdateUpdatedClass`;
}
