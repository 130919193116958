import React from 'react';
import AppGetIcon from '../../AppGetIcon';

import style from './modal.module.css';

interface ModalProps {
  title: string;
  isOpen: boolean;
  size?: {
    width: string;
    height: string;
    maxWidth?: string;
  };
  onClose: () => void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;

}
const Modal: React.FC<ModalProps> = ({ title, isOpen, size, onClose, children, footerContent }) => {
  if (!isOpen) return null;
  return (
    <div className={style.modal_overlay}>
      <div className={style.modal} style={{ width: size?.width, height: size?.height, maxWidth: size?.maxWidth }}>
        <div className={style.title_modal}>
          <div>
            {title}
          </div>
          <button className={style.modal_close} onClick={onClose}>
            <AppGetIcon name='close' classIcon={style.widthIconClose} strokeColor='#FFFFFF' />
          </button>
        </div>
        <div className={style.modal__content}>{children}</div>
        {
          footerContent &&
          <div className={style.modal__footer}>{footerContent}</div>
        }
      </div>
    </div>
  );
};

export default Modal;