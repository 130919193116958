import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Field, FieldProps } from "formik";
import style from '../InputDate/inputs.module.css';
import { es } from 'date-fns/locale';

interface DateInputInterface {
  name: string;
  placeholder?: string;
  TextLabel?: string;
  disabled?: boolean;
  onChange?: (date: Date | [Date, Date] | null) => void; // Nueva prop para manejar el cambio
  handleBlur?: (date: Date) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  range?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  classNameInput?: string;
  errorMessage?: string;
  timeIntervals?: number;
}

const DateInputFormik = ({
  name,
  placeholder,
  TextLabel,
  disabled,
  onChange,
  handleBlur,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  range = false,
  dateFormat = "dd/MM/yyyy",
  minDate,
  maxDate,
  classNameInput,
  errorMessage,
  timeIntervals = 1,
}: DateInputInterface) => {
  // Función para convertir 'HH:mm' a una instancia de Date
  return (
    <div className={style.container__input}>
      <label htmlFor={name} className={style.input__label}>
        {TextLabel}
      </label>
      <Field name={name}>
        {({ form, field }: FieldProps) => {
          const handleChange = (date: Date | [Date, Date] | null) => {
            if (Array.isArray(date)) {
              form.setFieldValue(name, date);
            } else {
              // Asegúrate de que se guarde como ISO para el campo de formulario
              form.setFieldValue(name, date ? date.toISOString() : null);
            }
            if (handleBlur) {
              handleBlur(date as Date);
            }
            if (onChange) {
              onChange(date); // Llamar a `onChange` si está definida
            }
          };

          // Validación antes de renderizar el DatePicker
          const selectedDate = field.value ? new Date(field.value) : null;
          const isDateValid = selectedDate && !isNaN(selectedDate.getTime());

          // Si necesitas una hora específica al iniciar, ajusta la hora aquí

          return (
            <>
              <DatePicker
                selected={isDateValid ? selectedDate : null}
                onChange={handleChange}
                startDate={range && Array.isArray(field.value) ? new Date(field.value[0]) : null}
                endDate={range && Array.isArray(field.value) ? new Date(field.value[1]) : null}
                selectsRange={range}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeFormat="HH:mm"
                dateFormat={showTimeSelectOnly ? "HH:mm" : dateFormat}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                placeholderText={placeholder}
                className={classNameInput ? `${classNameInput} ${range ? style.date__range : style.datepicker}` : `${style.input__input} ${range ? style.date__range : style.datepicker}`}
                isClearable
                locale={es}
                showYearDropdown
                showMonthDropdown
                timeIntervals={timeIntervals}
              />
              {errorMessage && (
                <div className={style.containerError}>
                  <div className={style.messageError}>{errorMessage}</div>
                </div>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default DateInputFormik;
