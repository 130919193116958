import React from 'react';
import { shallow } from 'zustand/shallow';
import { useDataTableStore } from '../../../store/dataTable.store';

export interface ColumnProps {
  header: string | React.ReactNode;
  field: string;
  render?: (value: any, index: number) => React.ReactNode;
  onCellClick?: (rowIndex: number) => void;
  background?: string;
  checkAllId?: any;
  filterCondition?: (item: any) => boolean;  // Nueva prop opcional
};

export const DataTableColumn: React.FC<ColumnProps> = ({ header, field, render, background, checkAllId, filterCondition }) => {
  const dataTableStorage = useDataTableStore(
    (state) => ({
      dataTable: state.dataTable,
      selectedAll: state.selectedAll,
      selectedData: state.selectedData,
      updateSelectedAll: state.updateSelectedAll,
      updateSelectedData: state.updateSelectedData,
    }),
    shallow
  );
  const handleSelectAll = () => {
    const { selectedAll, updateSelectedAll, selectedData, updateSelectedData } = dataTableStorage;

    isAllSelected()
    if (!selectedAll) {
      const newData = dataTableStorage.dataTable.data.filter(item => {
        const isSelected = selectedData.some(selectedItem => selectedItem[checkAllId] === item[checkAllId]);
        const doesNotMeetCondition = filterCondition ? !filterCondition(item) : true; // Invertir la condición
        return !isSelected && doesNotMeetCondition;
      });
      const updatedSelectedData = [...selectedData, ...newData];
      updateSelectedData(updatedSelectedData);
    } else {
      const newData = selectedData.filter(item => {
        const isInTable = dataTableStorage.dataTable.data.some(dataItem => dataItem[checkAllId] === item[checkAllId]);
        const doesNotMeetCondition = filterCondition ? !filterCondition(item) : true; // Invertir la condición
        return !isInTable && doesNotMeetCondition;
      });
      updateSelectedData(newData);
    }
    // Invierte el estado de selectedAll y actualiza el estado
    updateSelectedAll(!selectedAll);

  };

  // Función para verificar si todos los elementos de la tabla están seleccionados
  const isEqualObjects = (obj1: any, obj2: any) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    return obj1Keys.every(key => obj1[key] === obj2[key]);
  };
  const isAllSelected = () => {
    const { dataTable, selectedData } = dataTableStorage;
    return (
      dataTable.data.length > 0 &&
      dataTable.data.every(dataItem => {
        return selectedData.some(selectedItem => isEqualObjects(dataItem, selectedItem));
      })
    );
  };


  return <th className={background}>{header !== 'checkbox' ? header : <>
    <input
      type="checkbox"
      onChange={() => { }}  // Añade esta línea
      onClick={handleSelectAll}
      checked={isAllSelected()}
    />
  </>}
  </th>;
};
