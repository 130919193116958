import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import AppGetIcon from '../../../../common/UI/AppGetIcon';

import { ItemDto } from '../../../../common/dtos/initData.dto';
import InputComponent from "../../../../common/UI/DataTable/inputSearch";
import ProgressBar from '../../../../common/UI/ProgressBar';

import { DeleteItemRolePermissionsService } from '../../services/admin/permissionRoles/deleteItemRolePermissions.service';
import { GetRelatedDataFormPermissionsService } from '../../services/admin/permissionRoles/getRelatedDataFormPermissions.service';
import { GetItemsWithPermissionsService } from '../../services/admin/permissionRoles/getItemsWithPermissions.service';
import { CreateOrUpdateItemRolePermissionsService } from '../../services/admin/permissionRoles/createOrUpdateItemRolePermissions.service';

import style from './permissionRoles.module.css'
import styleForm from '../../../../common/theme/styles/form.module.css';
import { useHeaderStore } from '../../../../store/header.store';
import { shallow } from 'zustand/shallow';
import { DataSelectDto } from '../../../../common/dtos/general.dto';

const deleteItemRolePermissionsService = new DeleteItemRolePermissionsService();

export default function CPermissionRoles() {
  const paramsUrl = useParams();
  const headerStore = useHeaderStore(
    (state) => ({
      setModule: state.setModule,
      setModuleItem: state.setModuleItem,
      setItemChildren: state.setItemChildren,
    }),
    shallow
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [dataModules, setDataModules] = useState<any[]>([]);
  const [moduleId, setModuleId] = useState<DataSelectDto>({
    label: 'Administración',
    value: '1'
  });
  const [items, setItems] = useState<ItemDto[]>([]);
  const permissions = [
    'list',
    'create',
    'edit',
    'delete',
  ];

  useEffect(() => {
    headerStore.setModule('Administración', '');
    headerStore.setModuleItem('Roles', '/admin/roles');
    headerStore.setItemChildren('Permisos', '');
    getInitDataView(Number(paramsUrl.roleId));
    getItemsWithPermissions();
    return (() => {
      headerStore.setItemChildren('', '');
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitDataView = async (role: number) => {
    let response: any = await GetRelatedDataFormPermissionsService(role);
    setDataModules(response.modules.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    }));
  };


  const getItemsWithPermissions = async () => {
    setLoading(true);
    try {
      const response = await GetItemsWithPermissionsService({
        moduleId: Number(moduleId.value),
        roleId: Number(paramsUrl.roleId) ?? 1,
      });
      const newData: ItemDto[] = [];
      response.forEach((item) => {
        if (!item.itemParentId) {
          newData.push(item);
        }
        item.children.forEach((child) => {
          newData.push(child);
        });
      });
      newData.forEach((item) => {
        if (item.rolePermissions) {
          const keys = Object.keys(item.rolePermissions);
          keys.forEach((key) => {
            item.rolePermissions[key].selectedPermissionId = item.rolePermissions[key].selectedPermissionId ?? 0;
          });
        }
      });
      setItems(newData);
      setLoading(false);

    } catch (e) {
      console.log('error', e);
      setLoading(false);

    }
  };

  useEffect(() => {
    getItemsWithPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId]);

  const createOrUpdatePermission = async (data: any, id: number) => {
    try {
      await CreateOrUpdateItemRolePermissionsService(data);
    } catch (e) {
      console.log('This error createOrUpdatePermission', e);
    };
  };
  const deletePermission = async (id: number) => {
    try {
      await deleteItemRolePermissionsService.run(id);
    } catch (e) {
      console.log('This error deletePermission', e);
    };
  };
  const permissionMappings: any = {
    create: 16, // ID del permiso "Crear"
    edit: 18,   // ID del permiso "Editar"
    list: 15,   // ID del permiso "Listar"
    delete: 17  // ID del permiso "Eliminar"
  };

  const changePermissionCheckbox = async (item: ItemDto, permission: string, isChecked: boolean) => {
    setLoading(true);
    let permissionData = item.rolePermissions && item.rolePermissions[permission];
    const permissionId = permissionMappings[permission];  // Obtiene el ID del permiso desde el mapeo

    if (!permissionData) {
      // Si no hay permisos existentes, configura los datos base para crear un nuevo permiso
      permissionData = {
        itemRolePermissionId: null,  // Sin ID de permiso existente
        selectedPermissionId: isChecked ? permissionId : 0,
        data: [{
          id: permissionId
        }]
      };
    }

    const selectedPermissionId = isChecked ? permissionId : 0;
    const data = {
      roleId: paramsUrl.roleId,
      itemId: item.id,
      permissionId: selectedPermissionId,
    };

    // Crear o actualizar el permiso según si está checkeado
    if (!permissionData.itemRolePermissionId && isChecked) {
      // Llama a una función para crear el permiso
      await createOrUpdatePermission(data, 0);
    } else if (permissionData.itemRolePermissionId && !isChecked) {
      // Llama a una función para eliminar el permiso
      await deletePermission(permissionData.itemRolePermissionId);
    } else {
      // Actualiza el permiso existente
      await createOrUpdatePermission(data, permissionData.itemRolePermissionId);
    }

    // Actualiza el estado con la información más reciente
    const updatedItem = {
      ...item,
      rolePermissions: {
        ...item.rolePermissions,
        [permission]: {
          ...permissionData,
          selectedPermissionId: selectedPermissionId
        }
      }
    };

    setItems(prevItems => prevItems.map(prevItem => prevItem.id === updatedItem.id ? updatedItem : prevItem));
    setLoading(false);

  };
  return (
    <div className={style.container_permissions}>
      <div className='header__title_table' style={{ fontWeight: 'bold' }}>{`Gestionar los permisos para el rol - ${paramsUrl.roleName}`}</div>
      <br />
      <p > <AppGetIcon style={{ display: 'inline-block', position: 'relative', top: '5px', padding: '0px', width: '25px', height: '25px' }} name='infoCircle' /> En esta interfaz, puedes gestionar los permisos asociados a diferentes roles dentro de la aplicación. Empieza seleccionando un módulo, esto filtrará los ítems disponibles que pertenecen a este módulo específico.  </p>
      <br />
      <div className={style.permissions_header}>
        <InputComponent
          classNameInput={styleForm.input__input}
          textLabel="Seleccionar módulo"
          data={dataModules}
          onSelect={(selected) => { setModuleId(selected) }}
          selected={moduleId}
        />
      </div>
      <div className={style.permissions_body}>
        {
          loading && <ProgressBar width='calc(100% - 0px)' height='calc(100vh - 57px)' />}
        <>
          <table>
            <thead>
              <tr>
                <th style={{ flexBasis: '40%' }}>Aplicación</th>
                <th style={{ width: '10%' }}>Listar</th>
                <th style={{ width: '10%' }}>Crear</th>
                <th style={{ width: '10%' }}>Editar</th>
                <th style={{ width: '10%' }}>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) =>
                <tr key={item.id}>
                  <td className={style.aplication} colSpan={5}>
                    <AppGetIcon name={item.icon} />
                    <span>{item.name}</span>
                  </td>
                  {permissions.map((permission) =>
                    <td key={permission + "_" + item.id} >
                      {(item.itemParentId || !item.children?.length) && (
                        <>
                          {
                            <input
                              type="checkbox"
                              checked={item.rolePermissions[permission]?.selectedPermissionId > 0}
                              onChange={(e) => changePermissionCheckbox(item, permission, e.target.checked)}
                            />
                          }
                        </>
                      )}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <div className={style.contaimer__status}>
            <div className={style.title__status}>  Opciones    </div>
            <div className={style.status__list}>
              <div className={style.container__row}><strong style={{ fontWeight: 'bold' }}>Listar: </strong> Permite al rol ver y listar la información del ítem. </div>
              <div className={style.container__row}><strong style={{ fontWeight: 'bold' }}>Crear: </strong> Autoriza al rol para crear nuevos registros o elementos dentro de ese ítem.</div>
              <div className={style.container__row}><strong style={{ fontWeight: 'bold' }}>Editar: </strong> Permite al rol modificar registros o elementos existentes.</div>
              <div className={style.container__row}><strong style={{ fontWeight: 'bold' }}> Eliminar: </strong> Otorga permiso al rol para eliminar registros o elementos.</div>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}
