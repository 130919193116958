import { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import style from './inputs.module.css';
import AppGetIcon from '../../AppGetIcon';
// import AppGetIcon from '../../atoms/getIcon';
import { es } from 'date-fns/locale';

interface DateInputInterface {
  name: string;
  placeholder?: string;
  textLabel?: string;
  disabled?: boolean;
  onChange?: (date: Date | [Date, Date] | null) => void;
  showTimeSelect?: boolean;
  range?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  classNameInput?: string;
  initialValue?: any | [Date, Date]; // Prop para el valor inicial
  clearable?: boolean; // Nueva prop para controlar si se muestra la X de limpieza
}
const DateInput = ({
  name,
  placeholder,
  textLabel,
  disabled,
  onChange,
  showTimeSelect = false,
  range = false,
  dateFormat = "dd-MM-yyyy",
  minDate,
  maxDate,
  classNameInput,
  initialValue, // Recibe el valor inicial
  clearable = true // Valor por defecto: true para mostrar la X de limpieza
}: DateInputInterface) => {
  const [selectedDate, setSelectedDate] = useState<Date | [Date, Date] | null>(initialValue ?? null);

  const handleChange = (date: Date | [Date, Date] | null) => {
    setSelectedDate(date);
    if (date && onChange) {
      onChange(date); // Sólo llamar onChange si date no es null.
    }
  };

  return (
    <div className={style.container__input}>

      {textLabel && <label htmlFor={name} className={style.input__label}>{textLabel}</label>}

      <DatePicker
        selected={(range && Array.isArray(selectedDate) ? selectedDate[0] : selectedDate) as Date | null}
        onChange={handleChange}
        startDate={range && Array.isArray(selectedDate) ? selectedDate[0] ?? null : null}
        endDate={range && Array.isArray(selectedDate) ? selectedDate[1] ?? null : null}
        selectsRange={range}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        placeholderText={placeholder}
        className={classNameInput ? `${classNameInput} ${range ? style.date__range : style.datepicker}` : `${style.input__input} ${range ? style.date__range : style.datepicker}`}
        showIcon
        icon={<AppGetIcon name='calendar' />}  // Esto debería funcionar ahora correctamente
        isClearable={clearable}
        locale={es}
        showYearDropdown
        showMonthDropdown
      />
    </div>
  );
};

export default DateInput;