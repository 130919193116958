import { Loading } from "../atoms/loading"
import { Title } from "../atoms/title"

export const MessageLoading = ({ title }: propsTitle) => {
  return (
    <>
      <Title title={title}/>
      <Loading/>
    </>
  )
}
