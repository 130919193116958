import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from "zustand/middleware";
import { createJSONStorage } from 'zustand/middleware';

type dataUserDto = {
  id?: number | null;
  firstnames?: string;
  secondname: string;
  lastnames?: string;
  username?: string;
  email: string;
  phone?: string;
  externalUserKey?: string;
  createdAt?: string;
  updatedAt?: string;
  iat?: number;
  exp?: number;
}
type userInterface = {
  dataUser: dataUserDto,
  updateDataUser: (newUserData: dataUserDto) => void;
  clearStore: () => void;
}
// Crear un almacenamiento personalizado
const storage = createJSONStorage(() => localStorage);


export const useUserStore = createWithEqualityFn<userInterface>()(
  persist(
    (set) => ({
      dataUser: {
        id: 0,
        firstnames: '',
        secondname: '',
        lastnames: '',
        username: '',
        email: '',
        phone: '',
        externalUserKey: '',
        createdAt: '',
        updatedAt: '',
        iat: 0,
        exp: 0,
      },

      updateDataUser: (newUserData: dataUserDto) => set({ dataUser: newUserData }),

      clearStore: () => {
        set({
          dataUser: {
            id: 0,
            firstnames: '',
            secondname: '',
            lastnames: '',
            username: '',
            email: '',
            phone: '',
            externalUserKey: '',
            createdAt: '',
            updatedAt: '',
            iat: 0,
            exp: 0,
          },
        });
      },
    }),
    {
      name: "user-storage",
      storage
    }
  )

);