import { shallow } from 'zustand/shallow';
import { useHeaderStore } from '../../../store/header.store';
import './header.css'
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { OutlineButton } from '../OutlineButton';
export default function AppHeader() {
  const location = useLocation();
  const currentUrl = location.pathname;

  const headerStore = useHeaderStore(
    (state) => ({
      module: state.module,
      moduleItem: state.moduleItem,
      itemChildren: state.itemChildren,
      actionFilter: state.actionFilter,
      setQrSession: state.setQrSession
    }),
    shallow
  );

  return (
    <header className="header">
      <div className='header-container'>

        <div className="header-content">
          {/* <!-- <AppGetIcon name="arrow-back" strokeColor="#FFFFFF" @click="() => goBack()" /> --> */}
          <div className="content-text">
            <div>
              {headerStore.module.name ? headerStore.module.name : ''}
            </div>
            {
              headerStore.moduleItem.name &&
              <>
              /
              <NavLink to={headerStore.moduleItem.url ?? ''}>
                {`${headerStore.moduleItem.name}`}
              </NavLink>
              </>
            }
            {
              headerStore.itemChildren.name &&
              <>
              /
              <NavLink to={headerStore.itemChildren.url ?? ''}>
                {`${headerStore.itemChildren.name}`}
              </NavLink>
              </>
            }
          </div>
        </div>
        {
          currentUrl.includes('/teacher/assistance/sesionid') &&
          <div className="header-actions">
            {
              currentUrl.includes('/teacher/assistance/sesionid') &&
              <OutlineButton
                textButton='Obtener Qr de actividades'
                iconName='qr'
                color='transparent'
                textColor='#FFFFFF'
                hoverColor='#3b6c96'
                onClick={() => headerStore.setQrSession(true)}
              />
            }
          </div>
        }
      </div>
    </header>

  )
}
