

import style from './LoadingInformation.module.css';
import styleButton from '../../../common/theme/styles/button.module.css';
import logoUnab from '../../../assets/images/logo__unab.svg';
import Loading from '../Loading';

interface IProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  description: string;
  size?: {
    width: string;
    height: string;
  };
  responseStatus: number | null;
}
const LoadingInformation = ({ isLoading, onClose, name, description, isOpen, size, responseStatus }: IProps) => {
  if (!isOpen) return null;
  return (
    <div className={style.modal_overlay}>
      <div className={style.modal} style={{ width: size?.width, height: size?.height }}>
        <div className={style.modal__content}>
          <div className={style.content__logo}>
            <img src={logoUnab} alt="logo unab" />
          </div>
          {
            name &&
            <div className={style.content__name}>
              <h4> {name.length > 1 ? `Bienvenido(a)` : 'Iniciando sesión...'} </h4>
              <h4 className={style.capitalize}> {name.length > 1 ? `${name.toLowerCase()}` : ''} </h4>
            </div>
          }
          {isLoading &&
            <div style={{ paddingTop: '20px' }}>
              <Loading />
            </div>
          }

          <div className={style.content__text}>
            {
              description
            }
            {
              responseStatus &&
              <div className={style.content__help}>
                {responseStatus !== 200 ?
                  <>
                    <div className={styleButton.ButtomFooter + ' ' + styleButton.Ceremonies__view} onClick={onClose}>
                      Cerrar
                    </div>
                  </> :
                  <div style={{ paddingTop: '20px' }}>
                    <div className={styleButton.ButtomFooter + ' ' + styleButton.ButtomFooter__save} onClick={onClose}>
                      Cerrar
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingInformation;