import { CreateOrUpdateBaseService } from "../../../../common/services/createOrUpdateBase.service";
import { services } from "../../../../constants/services";

interface userData {
  isUserCreate: false;
  user: {
    id: number | null;
    username: string;
  };
  roles: number[];
}

export class CreateOrUpdatedUserService extends CreateOrUpdateBaseService<userData, any> {
  url = `${services.users}/access/users/saveUser`;
};

