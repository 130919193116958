import { getAll } from "../../../../../utils/api";
import { services } from "../../../../../constants/services";
import { ItemDto } from "../../../../../common/dtos/initData.dto";

interface IParams {
    moduleId: number;
    roleId: number;
}

export const GetItemsWithPermissionsService = async(params: IParams)=> {
    const response = await getAll<ItemDto[]>(`${services.users}/access/items-with-permissions?moduleId=${params.moduleId}&roleId=${params.roleId}`);
    return response;
    
}