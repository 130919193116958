import AppGetIcon from "../AppGetIcon";
import { MessageLoading } from "../MessageLoading";
import style from "./cardLoading.module.css";

const CardLoading = ({title, nameIcon}:{title:string,nameIcon:string }) => {
  return (
    <div className={style.overlay}> {/* Agrega el contenedor de superposición */}
      <div className={style.card}>
      <AppGetIcon
          name={nameIcon}
          classIcon={style.sizeIcon}
        />
        <MessageLoading title={title} />
      </div>
    </div>
  );
};

export default CardLoading;
