import { CreateOrUpdateBaseService } from "../../../../common/services/createOrUpdateBase.service";
import { services } from "../../../../constants/services";

export interface userCampusData {
  id: number | null;
  userId: number;
  campusId: string;
  campusName: string;
}

export class CreateOrUpdatedUserCampusService extends CreateOrUpdateBaseService<userCampusData, any> {
  url = `${services.userCampus}/createOrUpdateUserCampus`;
};

