import * as yup from "yup";
import { useState, useEffect, useCallback } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { InputText } from "../../../../common/UI/Inputs";
import { showToast } from "../../../../common/UI/ToastNotification";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";
import Modal from "../../../../common/UI/Modal";
import InputTypeFile from "../../../../common/UI/Inputs/InputTypeFile";
import AppGetIcon from "../../../../common/UI/AppGetIcon";
import ProgressBar from "../../../../common/UI/ProgressBar";

import { NumberRowsOptions, TimeOptions, TimePerPageOptions } from "../../constants/administationRotationScreen.const";

import { useUserStore } from "../../../../store/user.store";

import { DataSelectDto } from "../../../../common/dtos/general.dto";
import { IFile } from '../../dtos/administationRotationScreen.dto';

import style from "./FormAdministrationsRotationScreen.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";
import styleButton from "../../../../common/theme/styles/button.module.css";

import { GetCampusWithPaginationService } from "../../services/getCampusWithPagination.service";
import { GetBuildingWithPaginationService } from "../../services/getBuildingWithPagination.service";
import { GetAdministrationRotationScreenWithPaginationService } from "../../services/AdministrationRotationScreen/getAdministrationRotationScreenWithPagination.service";
import { GetFilesWithPaginationService } from "../../services/getFilesWithPagination.service";
import { CreateOrUpdateAdministrationRotationScreenService, IAdministrationRotation } from "../../services/AdministrationRotationScreen/createOrUpdateAdministrationRotationScreen.service";
import { CreateOrUpdateUplaodFileService, IUploadFile } from "../../services/AdministrationRotationScreen/createOrUpdateUploadFile.service";
import { CreateOrUpdateAddFileService } from "../../services/AdministrationRotationScreen/createOrUpdateAddFile.service";
import { CreateOrUpdateFileOrderService } from "../../services/AdministrationRotationScreen/createOrUpdateFileOrder.service";
import { DeleteFileService } from "../../services/AdministrationRotationScreen/deleteFile.service";
import AppInputSearch from "../../../../common/UI/Inputs/appInputSearch";

const getAdministrationRotationScreenWithPaginationService = new GetAdministrationRotationScreenWithPaginationService();
const getFilesWithPaginationService = new GetFilesWithPaginationService();
const createOrUpdateAdministrationRotationScreenService = new CreateOrUpdateAdministrationRotationScreenService();
const createOrUpdateUplaodFileService = new CreateOrUpdateUplaodFileService();
const createOrUpdateAddFileService = new CreateOrUpdateAddFileService();
const createOrUpdateFileOrderService = new CreateOrUpdateFileOrderService();
const deleteFile = new DeleteFileService();

export default function FormAdministrationRotativeScreenPage() {
  const navigate = useNavigate();
  const { idScreen } = useParams();
  const dataUserStore = useUserStore(
    state => ({
      userId: state.dataUser
    }),
    shallow
  );

  const [loadingView, setLoadingView] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loadingPublic, setLoadingPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReloadData, setIsReloadData] = useState(false);

  // inicializar data de la pantalla
  const [initialData, setInitialData] = useState<IAdministrationRotation | null>(null);
  const [nameValue, setNameValue] = useState<any>('');
  const [advertising, setAdvertising] = useState<any>(null);
  const [advertisingActivities, setAdvertisingActivities] = useState<any>(null);
  const [campus, setCampus] = useState<DataSelectDto>({ label: "", value: "" });
  const [buildingId, setBuildingId] = useState<any>(null);
  // inicializar data de la publicidad
  const [initialDataUploadFile, setInitialDataUploadFile] = useState<IUploadFile | null>(null);
  // drag and drop
  const [rows, setRows] = useState([]);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isSelectExisting, setIsSelectExisting] = useState(false);
  const [isEditAdvertising, setIsEditAdvertising] = useState(false);

  const [screenOption, setScreenOption] = useState('general');
  const [uploadedFileData, setUploadedFileData] = useState<any>({});
  const [showPreview, setShowPreview] = useState(true);
  const [isHoveredDelete, setIsHoveredDelete] = useState<number | null>(null);
  const [isHoveredEye, setIsHoveredEye] = useState<number | null>(null);

  const [timeScreen, setTime] = useState<DataSelectDto>({ label: "", value: "" });
  const [attachment, setAttachment] = useState<DataSelectDto>({ label: "", value: "" });
  const [typeFilter, setTypeFilter] = useState('image');
  const [orientationFilter, setOrientationFilter] = useState('complete');

  const [isOpenModalAddAdvertising, setIsOpenModalAddAdvertising] = useState(false);
  const [isOpenModalConfirmCancel, setIsOpenModalConfirmCancel] = useState(false);
  const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);
  const [isOpenPreview, setIsOpenPreview] = useState<any>(null);
  const [isHoveredEdit, setIsHoveredEdit] = useState<number | null>(null);

  const uploadFileSchema = yup.object().shape({
    screenOption: isCreateNew ? yup.string().required('Pantalla de destino es obligatoria.') : yup.string().notRequired(),
    adType: yup.string().required('Tipo de publicidad es obligatorio.'),
    orientation: yup.string().required('Orientación es obligatoria.'),
    time: yup.number().required('Tiempo es obligatorio.'),
    file: (isCreateNew && !isEditAdvertising && !uploadedFileData?.uploadedFileUrl) ? yup.string().required('El archivo es obligatorio.') : yup.string().notRequired(),
    fileId: isSelectExisting ? yup.string().required('El archivo es obligatorio.') : yup.string().notRequired(),
  });

  const getData = useCallback(async () => {
    if (idScreen) {
      try {
        setLoadingView(true);
        const response = await getAdministrationRotationScreenWithPaginationService.run({ id: idScreen });
        const data = response.data[0] || {};
        setRows(response.data[0].files ?? []);
        console.log('dataTadaaa', data);
        const buildingIdsArray = data.buildingIds ? data.buildingIds.split(',') : [];
        console.log("buildingIdsArray", buildingIdsArray.map((id: any) => ({
          label: id,
          value: id,
        })));
        setNameValue(data?.name)
        setAdvertising(data?.showAdvertising)
        setAdvertisingActivities(data?.intersperseAdvertisingActivities)
        setInitialData({
          id: data?.id ?? null,
          name: data?.name ?? '',
          campusId: data?.campusId ?? '',
          buildingIds: buildingIdsArray.map((id: any) => ({
            label: id,
            value: id,
          })) ?? '',
          visualizationActivityBefore: String(data?.visualizationActivityBefore) ?? '',
          visualizationActivityAfter: String(data?.visualizationActivityAfter) ?? '',
          numberRows: String(data?.numberRows) ?? '',
          durationTimePerPage: String(data?.durationTimePerPage) ?? '',
          showAdvertising: data?.showAdvertising ?? false,
          intersperseAdvertisingActivities: data?.intersperseAdvertisingActivities ?? false,
          splitScreenAdvertising: data?.splitScreenAdvertising ?? false,
          url: data?.url ?? '',
          createdById: data?.createdById ?? dataUserStore.userId.id,
          updatedById: data?.updatedById ?? null,
        });
        setCampus({ label: data.campusId, value: data.campusId });
        // Separar y mapear buildingIds
        setBuildingId(buildingIdsArray.map((id: any) => ({
          label: id,
          value: id,
        })));
        console.log("buildingIds", buildingId);
        setOrientationFilter('complete')


      } catch (error) {
        console.log('Error al ejecutar el servicio de reservas', error);
      } finally {
        setLoadingView(false);
      };
    } else {
      setLoadingView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idScreen, isReloadData]);
  const handleSubmit = async (values: IAdministrationRotation, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };
    let dataCreated: IAdministrationRotation = {
      ...values,
      // createdById: 123,
      buildingIds: values.buildingIds
        ? values.buildingIds.map((item: any) => item.value).join(",")
        : "",
    };
    setLoadingProgress(true);
    try {
      const response = await createOrUpdateAdministrationRotationScreenService.run(dataCreated);
      showToast('Información guardada correctamente', 'success');
      const newIdScreen = response;
      if (newIdScreen) {
        navigate(`/booking/formAdministrationRotativeScreen/rotativeScreenId/${newIdScreen}`);
      };
      setIsReloadData(!isReloadData);

      setLoadingProgress(false);
    } catch (error) {
      showToast('Ocurrió un error al guardar la información', 'error');
      console.error("Error guardando el recurso", error);
      setLoadingProgress(false);
    } finally {
      actions.setSubmitting(false);
    };
  };
  const serviceCallCampus = useCallback(async (value: string) => {
    const response: any = await GetCampusWithPaginationService({
      search: value,
      perPage: 100
    });
    return response.data.map((item: any) => ({
      label: item.campusId,
      value: item.campusId,
    }));
  }, []);
  const serviceCallBuilding = useCallback(async (dataSearch: any) => {
    let campusSelected = campus.value;
    const response: any = await GetBuildingWithPaginationService({
      search: dataSearch,
      campusId: campusSelected,
      perPage: 100,
      sort: 'id',
      order: 'DESC'
    });
    return response.data.map((item: any) => ({
      label: item.buildingId,
      value: item.buildingId,
    }));
  }, [campus.value]);
  const serviceGetFiles = useCallback(async (dataSearch: any) => {
    const requestParams: any = {
      search: dataSearch,
      perPage: 100,
      sort: 'id',
      order: 'DESC',
      originType: 'general',
      notScreenId: 1,
      type: isSelectExisting ? typeFilter : null,
      orientation: isSelectExisting ? orientationFilter : null,
    };
    const response: any = await getFilesWithPaginationService.run(requestParams);
    return response.data.map((item: any) => ({
      ...item,
      label: item.fileName,
      value: item.id,

    }));
  }, [isSelectExisting, typeFilter, orientationFilter,]);


  // Función para manejar el fin del drag-and-drop
  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      // Encuentra los índices de los elementos arrastrados y soltados
      const oldIndex = rows.findIndex((file: IFile) => file.id === active.id);
      const newIndex = rows.findIndex((file: IFile) => file.id === over.id);

      // Actualiza el estado de las filas
      const updatedRows = arrayMove(rows, oldIndex, newIndex);
      setRows(updatedRows);

      // Capturar el nuevo orden en un array de objetos { id, order }
      const updatedOrderFiles = updatedRows.map((file: IFile, index: number) => ({
        id: file.fileScreenId,
        order: index + 1,  // El índice + 1 será el nuevo orden
      }));

      // Actualiza el estado con el array de objetos con el id y el nuevo orden
      // Prepara la data para enviarla al servicio
      const dataFileOrder = updatedOrderFiles.map(file => ({
        fileScreenId: Number(file.id),
        order: file.order
      }));
      try {
        // Llama al servicio con los datos preparados
        await createOrUpdateFileOrderService.run(dataFileOrder);
        console.log("Orden de archivos actualizado correctamente", dataFileOrder);
      } catch (error) {
        console.error("Error al actualizar el orden de archivos", error);
      }
    }
  };
  // Componente para cada fila de la tabla
  const TableRow = ({ file }: { file: IFile }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: file.id });

    // Usamos estilos dinámicos de CSS para el estado de transformación y transición
    const inlineStyle = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    return (
      <tr
        ref={setNodeRef}
        className={`${isDragging ? style.draggingRow : ''}`}  // Aplica la clase CSS modular condicionalmente
        style={inlineStyle}  // Aplica los estilos dinámicos de DnD
        {...attributes}
      >
        <td>
          {/* Aquí solo aplicamos los listeners al ícono de "edit" */}
          <AppGetIcon
            {...listeners}
            style={{ cursor: 'move' }}
            name="drag"
            classIcon={styleDataTable.sizeIcon}
          >

          </AppGetIcon>
        </td>
        <td>{file.fileName || 'Sin nombre'}</td>
        <td>{file.type === 'image' ? 'Imagen' : 'Video'}</td>
        <td>{file.originType === 'specific' ? 'Especifico' : 'General'}</td>
        <td>{file.time} segundos</td>
        <td>{file.orientation === 'complete' ? 'Completa' : 'Dividida'}</td>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '5px' }}>
            <button
              className={styleDataTable.buttom_option}
              onMouseEnter={(e) => {
                e.preventDefault();
              }}
              onClick={() => handleOpenPreview(file)}
              onMouseLeave={(e) => {
                e.preventDefault();
                setIsHoveredEye(null);
              }}
            >
              <AppGetIcon
                name="eye"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredEye ? "#FFF" : ""}
              />{" "}
              Ver
            </button>
            <button
              className={styleDataTable.buttom_option}
              onClick={() => handleEditRotationScreen(file)}
              onMouseEnter={(e) => { setIsHoveredEdit(Number(file.id)); }}
              onMouseLeave={(e) => { setIsHoveredEdit(null); }}
            >
              <AppGetIcon classIcon={styleDataTable.sizeIcon} name="edit" strokeColor="#962330" hoverColor={isHoveredEdit === Number(file.id) ? '#FFF' : undefined} /> Editar
            </button>
            <button
              className={styleDataTable.buttom_option}
              onMouseEnter={(e) => {
                e.preventDefault();
                setIsHoveredDelete(Number(file.id));
              }}
              onClick={() => setIsOpenCurrentDelete(file)}
              onMouseLeave={(e) => {
                e.preventDefault();
                setIsHoveredDelete(null);
              }}
            >
              <AppGetIcon
                name="trash"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredDelete === Number(file.id) ? "#FFF" : ""}
              />{" "}
              Eliminar
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const handleClearStates = () => {
    setIsCreateNew(false);
    setIsSelectExisting(false);
    setInitialDataUploadFile(null);
    setTime({ label: '', value: '' });
    setUploadedFileData({});
    setShowPreview(true);
    setAttachment({ label: '', value: '' });
    setIsOpenModalAddAdvertising(false);
    setIsEditAdvertising(false)
    setLoading(false);
    setScreenOption('general');
    setTypeFilter('image');
    setOrientationFilter('complete')
  };

  const handleSubmitCreateOrUpdateUplaodFile = async (values: any, filedData?: any) => {
    setLoadingPublic(true);
    let dataCreated: IUploadFile = {
      id: uploadedFileData?.uploadedFileId ?? null,
      file: filedData ?? values?.file,
      orientation: values.orientation,
      rotatingScreenId: values.screenOption === 'specific' ? idScreen ?? null : null,
      time: values.screenOption === 'specific' ? values.time : null,
      url: values.screenOption === 'specific' ? uploadedFileData?.uploadedFileUrl : null,
    };
    try {
      let response = await createOrUpdateUplaodFileService.run(dataCreated);
      if (filedData) {
        setUploadedFileData({ uploadedFileId: response.id, uploadedFileUrl: response.url });
      };
      setLoadingPublic(false);
      return { uploadedFileId: response.id, uploadedFileUrl: response.url }
    } catch (error) {
      console.error("Error guardando el recursosss", error);
      setLoadingPublic(false);
    };
  };

  const handleSubmitCreateOrUpdateAddFile = async (values: any, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    };
    setLoadingPublic(true);
    let dataCreated: any = {
      id: uploadedFileData?.uploadedFileId ?? null,
      file: uploadedFileData?.uploadedFileId ? null : values.file,
      orientation: values.orientation, // Orientación de la pantalla (completa/dividida)
      rotatingScreenId: values.screenOption === 'specific' ? idScreen : null,
      time: values.time,
      url: uploadedFileData?.uploadedFileUrl
    };
    try {
      if (values.screenOption === 'specific') {
        await createOrUpdateUplaodFileService.run(dataCreated);
      } else if (values.screenOption === 'general' || isSelectExisting) {
        let responseCreateOrUpdateUplaodFile: any = {};
        if (!uploadedFileData?.uploadedFileId) {
          responseCreateOrUpdateUplaodFile = await handleSubmitCreateOrUpdateUplaodFile({ ...dataCreated, file: values.file })
        };
        let dataCreatedAddFile: any = {
          fileId: uploadedFileData?.uploadedFileId || attachment.value || responseCreateOrUpdateUplaodFile?.uploadedFileId,
          rotatingScreenId: Number(idScreen),
          orientation: values.orientation, // Orientación de la pantalla (completa/dividida)
          time: Number(values.time),
        };
        await createOrUpdateAddFileService.run(dataCreatedAddFile)
      } else {
        actions.setSubmitting(false);
      };
      setIsReloadData(!isReloadData);
      handleClearStates();
      showToast('Se guardo el archivo correctamente', 'success');
    } catch (error) {
      console.error("Error al guardar el archivo", error);
      showToast('Error al guardar el archivo  ', 'error');
    } finally {
      actions.setSubmitting(false);
      setLoadingPublic(false);
    };
  };

  const handleOpenModalCancelUploadAdvertising = () => {
    setIsOpenModalAddAdvertising(false);
    if (isEditAdvertising) {
      setIsOpenModalConfirmCancel(false);
      handleClearStates();

    } else if (isCreateNew && uploadedFileData?.uploadedFileUrl) {
      setIsOpenModalConfirmCancel(true);
      console.log("hola");

    } else {
      setIsOpenModalConfirmCancel(false);
      handleClearStates();
      console.log("hola jose");

    };

  };

  // Funciones para manejar los clicks
  const handleCrearNuevo = () => {
    setIsCreateNew(true);
    setIsSelectExisting(false);
  };
  const handleSeleccionarExistente = () => {
    setIsSelectExisting(true);
    setIsCreateNew(false);
  };

  // cuando confirma el salir del modal
  const handleConfirmCancel = () => {
    setLoadingPublic(true);
    handleDelete();
    handleClearStates();
    setIsOpenModalConfirmCancel(false);
    setLoadingPublic(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    let params: any = {
      rotatingScreenId: idScreen,
      fileId: uploadedFileData?.uploadedFileId,
    };
    if (uploadedFileData?.uploadedFileId) {
      setUploadedFileData({});
      try {
        await deleteFile.run(params);
        setIsReloadData(!isReloadData);
        setLoading(false);
      } catch (error) {
        console.error("Error al eliminar la reserva:", error);
        setLoading(false);
      }
    };
  };
  // funcion de cerrar de modal confirmacion de eliminacion 
  const handleCloseModalDelete = () => {
    setIsOpenCurrentDelete(null);
  };
  // funcion de eliminar de modal confirmacion de eliminacion 
  const handleDeleteFile = async () => {
    setLoading(true);
    let params = {
      rotatingScreenId: idScreen,
      fileId: isOpenCurrentDelete.id
    };
    try {
      await deleteFile.run(params);
      setIsOpenCurrentDelete(null);
      setIsReloadData(!isReloadData);
      showToast('Se elimino el archivo correctamente', 'success');
      setLoading(false);
    } catch (error) {
      showToast('Error al eliminar el archivo', 'error');
      console.error("Error al eliminar el archivo:", error);
    } finally {
      setLoading(false);
    };
  };

  //abrir modal previsualizacion
  const handleOpenPreview = (dataFile: any) => {
    setIsOpenPreview(dataFile); // Asigna la URL para previsualizarla
  };
  // funcion de cerrar de modal confirmacion de eliminacion 
  const handleCloseModalPreview = () => {
    setInitialData(null);  // Limpia los datos iniciales
    setIsOpenPreview(null);
  };
  const handleEditRotationScreen = async (row: any) => {
    setUploadedFileData({ uploadedFileId: row.id, uploadedFileUrl: row.url });
    setTime({ label: String(row?.time), value: String(row?.time) })
    setAttachment({ label: String(row?.fileName), value: String(row?.id) })
    setScreenOption(row?.originType);  // Si se selecciona crear nuevo, el valor será "specific"
    setOrientationFilter(row?.orientation)
    console.log("setInitialDataUploadFilesetInitialDataUploadFile", row);

    setInitialDataUploadFile({
      id: row?.id ?? null,
      file: row?.file ?? '',
      url: row?.url ?? '',
      time: String(row?.time) ?? '',
      orientation: row?.orientation ?? 'complete',
      rotatingScreenId: row?.rotatingScreenId ?? null,
      type: row?.type
    });
    setOrientationFilter(row?.orientation ?? 'complete')
    setIsEditAdvertising(true)
    setIsOpenModalAddAdvertising(true);
    if (row.originType === 'specific') {
      setIsCreateNew(true)
    } else {
      setIsSelectExisting(true)
    }
  };

  const resourceSchema = yup.object().shape({
    id: yup.mixed().nullable(),
    name: yup.string().required("El campo nombre es obligatorio"),
    campusId: yup.string().required("El campo campus es obligatorio"),
    // buildingIds: yup.string().required("El campo edificio es obligatorio"),

    visualizationActivityBefore: yup.string().required("El campo actividad antes (min.) es obligatorio"),
    visualizationActivityAfter: yup.string().required("El campo actividad después (min.) es obligatorio"),
    numberRows: yup.string().required("El campo número de filas es obligatorio"),
    durationTimePerPage: yup.string().required("El campo Duración por página (seg.) es obligatorio"),
  });
  useEffect(() => {
    if (isCreateNew) {
      setScreenOption('specific');  // Si se selecciona crear nuevo, el valor será "specific"
    } else if (isSelectExisting) {
      setScreenOption('general');  // Si se selecciona existente, se deja vacío
    };
  }, [isCreateNew, isSelectExisting, screenOption]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadData]);

  return (
    <div className={style.FormAdministrationRotativeScreen__page}>
      {(loadingProgress || loadingView) && <ProgressBar />}
      <div className={style.FormAdministrationRotativeScreen__page_content} style={{ marginBottom: '10px' }}>
        <h4 className={style.under__line}>{initialData?.id ? 'Editar' : 'Crear'} pantalla rotativa</h4>
        <Formik
          key={initialData?.id ?? "new"}
          initialValues={{
            id: initialData?.id ?? null,
            name: nameValue ?? '',
            campusId: campus.value,
            buildingIds: initialData?.buildingIds ?? '',
            visualizationActivityBefore: initialData?.visualizationActivityBefore ?? '',
            visualizationActivityAfter: initialData?.visualizationActivityAfter ?? '',
            numberRows: initialData?.numberRows ?? '',  // Verifica aquí
            durationTimePerPage: initialData?.durationTimePerPage ?? '',  // Verifica aquí
            showAdvertising: advertising ?? false,
            intersperseAdvertisingActivities: advertisingActivities ?? false,
            splitScreenAdvertising: initialData?.splitScreenAdvertising ?? false,
            url: initialData?.url ?? '',
            createdById: initialData?.createdById ?? dataUserStore.userId?.id,
            updatedById: initialData?.updatedById ?? null,
          }}
          validationSchema={resourceSchema}
          onSubmit={handleSubmit}
        >

          {({ isSubmitting, values, errors, touched, setFieldValue }) => (
            <Form id="rotationScreenForm" className="flex-row" style={{ width: '100%' }}>
              <div className={style.form__container_row}>
                <InputText
                  type="text"
                  classNameInput={styleForm.input__input}
                  name="name"
                  TextLabel="Nombre*"
                  placeholder="Nombre"
                  onValueChange={(value) => {
                    setNameValue(value); // Captura el valor en el estado
                  }}
                />
                <Field name="campusId">
                  {({
                    field,
                    form: { setFieldValue, validateForm, setFieldTouched, setFieldError, errors, touched, values },
                  }: FieldProps) => (
                    <>

                      <InputSearch
                        key={Math.random()}
                        name="campusId"
                        textLabel="Selecciona campus*"
                        classNameInput={styleForm.input__input}
                        serviceCall={serviceCallCampus}
                        onSelect={(selected) => {
                          // Actualiza el valor de campusId
                          setFieldValue("campusId", selected?.value ?? "");
                          setFieldValue("buildingIds", []);
                          // Actualiza roomId concatenando los valores relevantes
                          setCampus({
                            label: selected.label,
                            value: selected.value
                          });

                          // Marcar el campo como tocado
                          setFieldTouched("campusId", true, false);
                          // Forzar una validación completa del formulario
                          validateForm().then(() => {
                            // Limpia manualmente el error si el campo es válido
                            if (selected?.value) {
                              setFieldError("campusId", ""); // Limpia el error manualmente
                            }
                          });
                        }}
                        selected={campus}
                        errorMessage={touched.campusId && errors.campusId ? errors.campusId : ""}
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className={style.form__container_row}>
                <AppInputSearch
                  key={Math.random()}
                  name="buildingIds"
                  classNameInput={styleForm.input__input}
                  textLabel="Selecciona edificios*"
                  serviceCall={serviceCallBuilding}
                  onSelect={(selected) => {
                    console.log("selected", selected);
                    setFieldValue("buildingIds", selected.value);
                  }}
                  selected={values.buildingIds}
                  multiple={true}
                  showSelectAll={true}
                  errorMessage={touched.buildingIds && errors.buildingIds ? errors.buildingIds : ""}
                />
              </div>
              <div className={style.form__container_row}>
                <InputSearch
                  key="visualizationActivityBefore" // Cambia aquí
                  name="visualizationActivityBefore"
                  classNameInput={styleForm.input__input}
                  textLabel="Visualización actividad antes (min.)*"
                  data={TimeOptions}
                  onSelect={(selected) => {
                    setFieldValue("visualizationActivityBefore", selected.value);
                  }}
                  selected={{ label: values?.visualizationActivityBefore, value: values?.visualizationActivityBefore }}
                  errorMessage={touched.visualizationActivityBefore && errors.visualizationActivityBefore ? errors.visualizationActivityBefore : ""}
                />
                <InputSearch
                  key="visualizationActivityAfter" // Cambia aquí

                  name="visualizationActivityAfter"
                  classNameInput={styleForm.input__input}
                  textLabel="Visualización actividad después (min.)*"
                  data={TimeOptions}
                  onSelect={(selected) => {
                    setFieldValue("visualizationActivityAfter", selected.value);
                  }}
                  selected={{ label: values?.visualizationActivityAfter, value: values?.visualizationActivityAfter }}
                  errorMessage={touched.visualizationActivityAfter && errors.visualizationActivityAfter ? errors.visualizationActivityAfter : ""}
                />
              </div>
              <div className={style.form__container_row}>
                <InputSearch
                  key="numberRows" // Cambia aquí
                  name="numberRows"
                  classNameInput={styleForm.input__input}
                  textLabel="Número de filas*"
                  data={NumberRowsOptions()}
                  onSelect={(selected) => {
                    setFieldValue("numberRows", selected.value);
                  }}
                  selected={{ label: values?.numberRows, value: values?.numberRows }}
                  errorMessage={touched.numberRows && errors.numberRows ? errors.numberRows : ""}
                />
                <InputSearch
                  key="durationTimePerPage" // Cambia aquí
                  name="durationTimePerPage"
                  classNameInput={styleForm.input__input}
                  textLabel="Duración por página (seg.)"
                  data={TimePerPageOptions}
                  onSelect={(selected) => {
                    setFieldValue("durationTimePerPage", selected.value);
                  }}
                  selected={{ label: values?.durationTimePerPage, value: values?.durationTimePerPage }}
                  errorMessage={touched.durationTimePerPage && errors.durationTimePerPage ? errors.durationTimePerPage : ""}
                />
              </div>
              <div className={style.form__container_row} style={{ justifyContent: 'flex-start' }}>
                <div className={styleForm.containerChecks}>
                  <label className={styleForm.title__label}>Publicidad</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div className={styleForm.check}>
                      <Field name="showAdvertising">
                        {({ field, form }: any) => (
                          <input
                            key="showAdvertising" // Cambia aquí
                            {...field}
                            id="showAdvertising"
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              form.setFieldValue("showAdvertising", e.target.checked);
                              setAdvertising(e.target.checked)
                            }}
                          />
                        )}
                      </Field>
                      <label className={style.title}>Mostrar Publicidad</label>
                    </div>
                    <div className={styleForm.check}>
                      <Field name="intersperseAdvertisingActivities">
                        {({ field, form }: any) => (
                          <input
                            key="intersperseAdvertisingActivities" // Cambia aquí
                            {...field}
                            id="intersperseAdvertisingActivities"
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              form.setFieldValue("intersperseAdvertisingActivities", e.target.checked);
                              setAdvertisingActivities(e.target.checked)
                            }}
                          />
                        )}
                      </Field>
                      <label className={style.title}>Intercalar Publicidad</label>
                    </div>
                  </div>
                </div>
              </div>
              {
                values.url &&
                <div className={styleForm.flex__row} style={{ maxWidth: '240px' }}>
                  <label htmlFor="url" className={styleForm.title__label}>Url pantalla</label>
                  <div>
                    <a
                      href={values.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styleForm.clickableText}
                    >
                      <AppGetIcon name="eye" fillColor="#5A5F65" /> Previsualizar pantalla
                    </a>
                  </div>
                </div>
              }
              <div className={styleForm.containerButtoms}>
                <button
                  onClick={() => navigate(`/booking/rotationScreen`)}
                  className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
                >
                  Volver
                </button>
                <button
                  type="submit"
                  form="rotationScreenForm"
                  className={styleForm.buttomFooter}
                  disabled={isSubmitting || (errors && Object.keys(errors).length !== 0)}
                >
                  {initialData?.id ? 'Actualizar' : 'Guardar'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {idScreen &&
        <div className={style.containerTable}>
          <h4 className={style.under__line} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '0px' }}>
            Publicidad adjunta a pantalla
            <button
              style={{ marginBottom: '5px' }}
              type="button"
              onClick={() => setIsOpenModalAddAdvertising(true)}
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
            >
              <AppGetIcon
                classIcon={styleDataTable.sizeIcon}
                name="plus"
              />
              Agregar publicidad
            </button>
          </h4>
          <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={rows} strategy={verticalListSortingStrategy}>
                {
                  rows.length > 0 ?
                    <table>
                      <thead style={{ position: 'sticky', top: -1, backgroundColor: '#fff', zIndex: 2 }}>
                        <tr>
                          <th>ORDEN</th>
                          <th>NOMBRE</th>
                          <th>TIPO</th>
                          <th>PANTALLA DESTINO</th>
                          <th>TIEMPO (seg.)</th>
                          <th>ORIENTACION</th>
                          <th>OPCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((file: IFile) => (
                          <TableRow key={file.id} file={file} />
                        ))}
                      </tbody>
                    </table>
                    : <div className={styleDataTable.Container__notFound}>
                      <div className={styleDataTable.Container__notFound} ><AppGetIcon name='infoCircle' />  <span>No se encontró información.</span></div>
                    </div>
                }
              </SortableContext>
            </DndContext>
          </div>
        </div>
      }

      <Modal
        title={`Agregar publicidad`}
        isOpen={isOpenModalAddAdvertising}
        onClose={handleOpenModalCancelUploadAdvertising}
        size={{ width: '90%', maxWidth: '1000px', height: '' }}
        loadingModal={loadingPublic}
        footerContent={
          <div className={styleButton.Container__buttoms_modal}>
            {
              (isCreateNew || isSelectExisting) &&
              <>
                <button
                  className={styleButton.Buttom__modal_cancel}
                  onClick={handleOpenModalCancelUploadAdvertising}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={styleButton.Buttom__modal_save}
                  form="uploadFile"
                >
                  Guardar
                </button>
              </>
            }

          </div>
        }
      >
        <div>
          {!isCreateNew && !isSelectExisting && (
            <div className={style.contentNew}>
              <div
                className={`${style.card} ${isCreateNew ? style.active : ''}`}
                onClick={handleCrearNuevo}
              >
                <span className={style.contentIcon}>+</span>
                <div>Crear</div>
              </div>
              <div
                className={`${style.card} ${isSelectExisting ? style.active : ''}`}
                onClick={handleSeleccionarExistente}
              >
                <span className={style.contentIcon}>+</span>
                <div>Seleccionar existente</div>
              </div>
            </div>
          )}
          <Formik
            key={initialDataUploadFile?.id ?? "new"}
            initialValues={{
              id: initialDataUploadFile?.id ?? null,
              file: initialDataUploadFile?.file ?? '',
              time: timeScreen?.value ?? '',
              orientation: initialDataUploadFile?.orientation ?? 'complete',
              rotatingScreenId: initialDataUploadFile?.rotatingScreenId ?? null,
              screenOption: screenOption?? 'specific',  // Valor dinámico basado en la selección
              adType: initialDataUploadFile?.type ?? 'image',  // Valor inicial para el tipo de publicidad
              fileId: attachment?.value ?? null,
            }
            }
            // enableReinitialize={true}
            validationSchema={uploadFileSchema}
            onSubmit={handleSubmitCreateOrUpdateAddFile}
          >
            {({ isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, validateForm }) => {
              return (
                <Form id="uploadFile" className="flex-row" style={{ width: '100%' }}>

                  {(isSelectExisting || isCreateNew) && (
                    <>
                      {isCreateNew && (<>
                        {/* Pantalla de destino */}
                        <div className={styleForm.form__container_row}>
                          <div className={styleForm.containerChecks}>
                            <label className={styleForm.title__label}>Pantalla de destino</label>
                            <div className={styleForm.contentCheck}>
                              <div className={styleForm.check}>
                                {/* Checkbox para "Solo esta pantalla" */}
                                <Field name="screenOption">
                                  {({ field, form }: any) => (
                                    <>
                                      <input
                                        id="specific"
                                        type="checkbox"
                                        checked={field.value === "specific"}  // Compara si el valor es "specific"
                                        onChange={(e) => {
                                          // Si se selecciona, se asigna "specific", si se desmarca, se limpia el valor
                                          form.setFieldValue("screenOption", e.target.checked ? "specific" : "");
                                          setFieldValue("file", '');
                                          handleDelete();
                                        }}
                                        disabled={isEditAdvertising}
                                      />
                                      <label htmlFor="specific" className={style.title}>Solo esta pantalla</label>
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className={styleForm.check}>
                                {/* Checkbox para "Más pantallas" */}
                                <Field name="screenOption">
                                  {({ field, form }: any) => (
                                    <>
                                      <input
                                        id="general"
                                        type="checkbox"
                                        checked={field.value === "general"}  // Compara si el valor es "general"
                                        onChange={(e) => {
                                          // Si se selecciona, se asigna "general", si se desmarca, se limpia el valor
                                          form.setFieldValue("screenOption", e.target.checked ? "general" : "");
                                          setFieldValue("file", '');
                                          handleDelete();
                                        }}
                                        disabled={isEditAdvertising}
                                      />
                                      <label htmlFor="general" className={style.title}>Más pantallas</label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>

                            {touched.screenOption && errors.screenOption && (
                              <div className={styleForm.messageError}>{errors.screenOption}</div>
                            )}
                          </div>
                        </div>
                      </>
                      )}
                      <div className={styleForm.form__container_row}>
                        {/* Tipo de publicidad */}
                        <div className={styleForm.containerChecks}>
                          <label className={styleForm.title__label}>Tipo de Publicidad</label>
                          <div className={styleForm.contentCheck}>

                            <div className={styleForm.check}>
                              {/* Checkbox para "Video" */}
                              <Field name="adType">
                                {({ field }: any) => (
                                  <>
                                    <input
                                      {...field}
                                      id="video"
                                      type="checkbox"
                                      checked={values.adType === "video"}
                                      onChange={() => {
                                        setFieldValue("adType", "video");
                                        setTypeFilter("video");
                                        setFieldValue("file", '');
                                        setFieldValue("time", '');
                                        setTime({ label: '', value: '' });

                                        handleDelete();
                                      }}
                                      disabled={isEditAdvertising}
                                    />
                                    <label htmlFor="video" className={style.title}>Video</label>
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className={styleForm.check}>
                              {/* Checkbox para "Imagen" */}
                              <Field name="adType">
                                {({ field }: any) => (
                                  <>
                                    <input
                                      {...field}
                                      id="image"
                                      type="checkbox"
                                      checked={values.adType === "image"}
                                      onChange={() => {
                                        setFieldValue("adType", "image");
                                        setTypeFilter("image");
                                        setFieldValue("file", '');
                                        handleDelete();
                                      }}
                                      disabled={isEditAdvertising}
                                    />
                                    <label htmlFor="image" className={style.title}>Imagen</label>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          {touched.screenOption && errors.screenOption && (
                            <div className={styleForm.messageError}>{errors.adType}</div>
                          )}
                        </div>
                        <div className={styleForm.containerChecks}>
                          <label className={styleForm.title__label}>Orientación</label>
                          <div className={styleForm.contentCheck}>
                            <div className={styleForm.check}>
                              {/* Checkbox para "Dividida" */}
                              <Field name="orientation">
                                {({ field }: any) => (
                                  <>
                                    <input
                                      {...field}
                                      id="divided"
                                      type="checkbox"
                                      checked={values.orientation === "divided"}
                                      onChange={() => {
                                        setFieldValue("orientation", "divided");
                                        setOrientationFilter("divided");
                                      }}
                                    />
                                    <label htmlFor="divided" className={style.title}>Dividida</label>
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className={styleForm.check}>
                              {/* Checkbox para "Completa" */}
                              <Field name="orientation">
                                {({ field }: any) => (
                                  <>
                                    <input
                                      {...field}
                                      id="complete"
                                      type="checkbox"
                                      checked={values.orientation === "complete"}
                                      onChange={() => {
                                        setFieldValue("orientation", "complete");
                                        setOrientationFilter("complete");
                                      }}
                                    />
                                    <label htmlFor="complete" className={style.title}>Completa</label>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          {touched.orientation && errors.orientation && (
                            <div className={styleForm.messageError}>{errors.orientation}</div>
                          )}
                        </div>
                      </div>
                      <div className={styleForm.form__container_row} style={{justifyContent: 'flex-start'}}>
                      {(values.adType === "image" || !isCreateNew) ? ( 
                        <InputSearch
                          key={Math.random()}
                          name="time"
                          classNameInput={styleForm.input__input}
                          placeholder="Tiempo (seg.)"
                          textLabel="Tiempo (seg.)"
                          data={TimePerPageOptions} // Opciones para el tiempo
                          onSelect={(selected) => {
                            setFieldValue("time", selected.value);  // Actualiza el campo 'time'
                            setTime({ label: selected.label, value: selected.value });  // Actualiza el estado de 'time'
                          }}
                          selected={{ label: timeScreen.label, value: timeScreen.value }}  // Valor actual de 'time'
                          errorMessage={touched.time && errors.time ? errors.time : ""}
                        />
                        ): null}
                        {isCreateNew && (
                          <div className={styleForm.row__input} style={{ padding: '5px 0', justifyContent: 'flex-start' }} >
                            <label className={styleForm.title__label} style={{ paddingBottom: '5px ' }}>
                              {(values.adType === "image" ? "Sube una imagen - *PNG, *JPG, *GIF" : "Sube un video - *MP4, *AVI, *MKV")}
                            </label>
                            <Field name="file">
                              {({ field }: FieldProps) => {
                                return (
                                  <InputTypeFile
                                    classNameInput={styleForm.input__input}
                                    type={values.adType === "video" ? "video" : "image"}
                                    selected={values.file}
                                    onChange={async (file, fileInputRef) => {
                                      if (file) {
                                        setFieldValue("file", file); // Asignar el archivo seleccionado al campo 'file'
                                        setFieldError("file", ""); // Limpiar el error manualmente si el archivo es válido

                                        // Asegúrate de que el archivo sea un video antes de manejar la duración
                                        if (values.adType === "video") {
                                          const time = await new Promise<number>((resolve) => {
                                            const video = document.createElement('video');
                                            video.preload = 'metadata';
                                            video.onloadedmetadata = () => {
                                              window.URL.revokeObjectURL(video.src);
                                              const duration = Math.round(video.duration); // Redondear la duración
                                              resolve(duration);
                                            };
                                            video.src = URL.createObjectURL(file);
                                          });

                                          setFieldValue("time", time); // Asigna la duración del video al estado o valores de formulario
                                          setTime({ label: String(time), value: String(time) });  // Actualiza el estado de 'time'
                                          console.log("Duración del video: ", time); // Aquí puedes ver la duración

                                        } 
                                        handleSubmitCreateOrUpdateUplaodFile(values, file);
                                        console.log("Duración time: ", timeScreen); // Aquí puedes ver la duración
                                        console.log("valuess: ", values.time); // Aquí puedes ver la duración
                                        
                                        // Llama a tu función de guardado con la duración
                                      } else {
                                        // Validar todo el formulario si no hay archivo
                                        const formErrors = await validateForm();
                                        if (Object.keys(formErrors).length > 0) {
                                          console.error("Errores en el formulario: ", formErrors);
                                          Object.keys(formErrors).forEach((field) => setFieldTouched(field, true, false));
                                          if (fileInputRef && fileInputRef.current) {
                                            fileInputRef.current.value = ""; // Reiniciar el input de archivo
                                          }
                                          setFieldValue("file", '');
                                          return; // No continuar si hay errores
                                        }
                                      }
                                    }}
                                    disabled={isEditAdvertising}
                                    errorMessage={typeof errors.file === "string" ? errors.file : undefined}
                                  />

                                );
                              }}
                            </Field>
                          </div>
                        )}
                        {isSelectExisting && (
                          <div className={style.form__container_row}>
                            <Field name="fileId">
                              {({ field }: FieldProps) => {
                                return (
                                  <InputSearch
                                    key={Math.random()}
                                    name="fileId"
                                    classNameInput={styleForm.input__input}
                                    textLabel="Selecciona archivo"
                                    serviceCall={serviceGetFiles}
                                    onSelect={async (selected) => {
                                      setFieldValue("fileId", selected.value);
                                      setAttachment({ label: selected.label, value: selected.value });
                                      setUploadedFileData({ uploadedFileId: selected.value, uploadedFileUrl: selected.url })
                                      setFieldTouched("fileId", true, false);
                                      validateForm().then(() => {
                                        // Limpia manualmente el error si el campo es válido
                                        if (selected?.value) {
                                          setFieldError("fileId", ""); // Limpia el error manualmente
                                        }
                                      });

                                      // Validar todo el formulario
                                      const formErrors = await validateForm();
                                      // Si el formulario tiene errores, detener la ejecución
                                      if (Object.keys(formErrors).length > 0) {
                                        console.error("Errores en el formulario: ", formErrors);
                                        // Marcar todos los campos con errores como `touched`
                                        Object.keys(formErrors).forEach((field) => {
                                          setFieldTouched(field, true, false);
                                        });
                                        return; // No continuar si hay errores
                                      }
                                    }}
                                    disabled={isEditAdvertising}
                                    errorMessage={typeof errors.fileId === "string" ? errors.fileId : undefined}
                                    selected={{ label: attachment.label, value: attachment.value }}
                                    completedValue
                                  />
                                )
                              }
                              }
                            </Field>
                          </div>
                        )}
                      </div>

                      {isEditAdvertising && (
                        <div
                          className={styleForm.warningMessage}
                          style={{
                            backgroundColor: '#FFF3CD', // Fondo de advertencia amarillo claro
                            color: '#856404', // Color del texto de advertencia (marrón)
                            border: '1px solid #ffeeba', // Borde de advertencia
                            padding: '10px', // Espacio interno
                            borderRadius: '5px', // Bordes redondeados
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '10px', // Separación superior
                          }}
                        >
                          <AppGetIcon name="alert" fillColor="#856404" />
                          <span style={{ fontWeight: 'bold' }}>
                            ¡Recuerda! Solo puedes editar la orientación y el tiempo.
                          </span>
                        </div>
                      )}


                      {uploadedFileData?.uploadedFileUrl && (
                        <button
                          type="button"
                          style={{ marginTop: '1rem' }}
                          className={styleForm.buttomFooter}
                          onClick={() => setShowPreview(!showPreview)}  // Cambia entre previsualizar y ocultar
                        >
                          {showPreview ? "Dejar de previsualizar" : "Previsualizar archivo"}  {/* Cambia el texto del botón */}
                        </button>
                      )}
                      {/* Muestra el iframe de previsualización si showPreview es true */}
                      {showPreview && uploadedFileData?.uploadedFileUrl && (
                        <div className={style.previewContainer}>
                          <h4 className={style.under__line}>Previsualización del archivo</h4> 
                          
                          {values?.adType === 'video' ? (
                            console.log("uploadedFileData?.uploadedFileUrl",uploadedFileData?.uploadedFileUrl),
                            <video
                              src={uploadedFileData?.uploadedFileUrl}
                              width="100%"
                              height="auto"
                              controls
                              style={{
                                display: "block",
                                border: "1px solid #ccc",
                                marginTop: "20px"
                              }}
                            />
                          ) : (
                            <img
                              src={uploadedFileData?.uploadedFileUrl}
                              alt="Previsualización del archivo"
                              width="100%"
                              style={{
                                display: "block",
                                border: "1px solid #ccc",
                                marginTop: "20px"
                              }}
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>

      <Modal
        title="Confirmación de cancelar"
        isOpen={isOpenModalConfirmCancel}
        onClose={() => { setIsOpenModalConfirmCancel(false); setIsOpenModalAddAdvertising(true); }}
        size={{ width: '60%', height: '' }}
        footerContent={
          <div className={styleButton.Container__buttoms_modal}>
            {/* Botón de Cancelar */}
            <button
              className={styleButton.Buttom__modal_cancel}
              onClick={() => { setIsOpenModalConfirmCancel(false); setIsOpenModalAddAdvertising(true); }}
            >
              Cancelar
            </button>

            {/* Botón de Confirmar */}
            <button
              type="button"
              className={styleButton.Buttom__modal_save}
              onClick={handleConfirmCancel}
            >
              Si, Confirmar
            </button>
          </div>
        }
      >
        <div style={{ padding: '30px' }}>
          <center>
            {`¿Estás seguro de que deseas confirmar esta opción en la publicidad? Esta acción no se puede deshacer.`}
          </center>

        </div>
      </Modal>
      <Modal
        title={`Confirmación de eliminación`}
        isOpen={isOpenCurrentDelete}
        onClose={handleCloseModalDelete}
        size={{ width: "600px", height: "" }}
        loadingModal={loading}
        footerContent={
          <div className={styleButton.Container__buttoms_modal}>
            <button
              className={styleButton.Buttom__modal_cancel}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={styleButton.Buttom__modal_save}
              onClick={handleDeleteFile}
              disabled={loading}
            >
              Si, Eliminar
            </button>
          </div>
        }
      >
        <div style={{ padding: "30px" }}>
          <center>
            {`¿Estás seguro de que deseas eliminar la Publicidad adjunta a pantalla ${isOpenCurrentDelete?.fileName}? Esta acción no se puede deshacer.`}
          </center>
        </div>
      </Modal>
      <Modal
        title={`Previsualización del archivo`}
        isOpen={isOpenPreview}
        onClose={handleCloseModalPreview}
        size={{ width: "800px", height: "" }}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalPreview}
            >
              Cancelar
            </button>

          </div>
        }
      >
        <div className={style.previewContainer}>
          {isOpenPreview?.type === 'video' ? (
            <video
              src={isOpenPreview?.url}
              width="100%"
              height="auto"
              controls  // Muestra los controles de reproducción
              style={{
                display: loading ? "none" : "block",
                border: "1px solid #ccc",
                marginTop: "20px"
              }}
            />
          ) : (
            <img
              src={isOpenPreview?.url}
              alt="Previsualización del archivo"
              width="100%"
              height="auto"
              style={{
                display: loading ? "none" : "block",
                border: "1px solid #ccc",
                marginTop: "20px"
              }}
            />
          )}
        </div>

      </Modal>
    </div>);
}
