import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from 'zustand/middleware';

type AuthState = {
  token: string | null;
  user: any | null;
  menuItems: any[];
  login: (token: string) => void;
  logout: () => void;
  setUserData: (user: any) => void;
  setMenuItems: (items: any[]) => void;
};

export const useAuthStore = createWithEqualityFn<AuthState>()(
  persist(
    (set) => ({
      token: '',
      user: { role: 'admin' },
      menuItems: [],
      login: (token) => set({ token }),
      logout: () => {
        set({ token: null, user: null, menuItems: [] });
      },
      setUserData: (user) => set({ user }),
      setMenuItems: (items) => set({ menuItems: items }),
    }),
    {
      name: 'auth-storage', // Nombre del almacenamiento en `localStorage`
      getStorage: () => localStorage, // Definir el almacenamiento
    }
  )
);
