import { CreateOrUpdateBaseService } from '../../../../common/services/createOrUpdateBase.service';
import { services } from '../../../../constants/services';

export interface IClass {
  buildingIds: any;
}


export class CreateOrUpdateGetRoomsMultipleService extends CreateOrUpdateBaseService<IClass, any> {
	url = `${services.rooms}/getRooms`;
}
