import { useEffect, useState } from "react";
import { Field, Formik, Form } from "formik";
import * as yup from "yup";

import AppGetIcon from "../../../../common/UI/AppGetIcon";
import Modal from "../../../../common/UI/Modal";
import ConatinerFilter from "../../../../common/UI/DataTable/filterModal/containerFilter";
import DataTable from "../../../../common/UI/DataTable/dataTable";
import InputSearch from "../../../../common/UI/DataTable/inputSearch";
import InputTypeFile from "../../../../common/UI/Inputs/InputTypeFile";

import { DataSelectDto } from "../../../../common/dtos/general.dto";

import { OrientationEnum, OriginTypeEnum, TimePerPageOptions } from "../../constants/administationRotationScreen.const";

import style from "./MultimediaFiles.module.css";
import styleForm from "../../../../common/theme/styles/form.module.css";
import styleDataTable from "../../../../common/UI/DataTable/dataTable.module.css";

import { GetFilesWithPaginationService } from "../../services/getFilesWithPagination.service";
import { CreateOrUpdateUplaodFileService, IUploadFile } from "../../services/AdministrationRotationScreen/createOrUpdateUploadFile.service";
import { DeleteFileService } from "../../services/AdministrationRotationScreen/deleteFile.service";

const getFilesWithPaginationService = new GetFilesWithPaginationService();
const createOrUpdateUplaodFileService = new CreateOrUpdateUplaodFileService();
const deleteFileService = new DeleteFileService();

export default function MultimediaFiles() {
  const typeFilters: DataSelectDto[] = [
    {
      label: 'Imagen',
      value: 'image',
    }, {
      label: 'Video',
      value: 'video',
    }
  ];
  const originTypeFilter: DataSelectDto[] = [
    {
      label: 'General',
      value: 'general',
    }, {
      label: 'Especifico',
      value: 'specific',
    }
  ];

  const [typeId, setTypeId] = useState<DataSelectDto>({ label: "", value: "" });
  const [originTypeId, setOriginTypeId] = useState<DataSelectDto>({ label: "", value: "" });
  const [time, setTime] = useState<DataSelectDto>({ label: "", value: "" });

  const [isReloadData, setIsReloadData] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [isOpenPreview, setIsOpenPreview] = useState<any>(null);

  const [uploadedFileData, setUploadedFileData] = useState<any>({});
  const [isOpenModalAddAdvertising, setIsOpenModalAddAdvertising] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isOpenCurrentDelete, setIsOpenCurrentDelete] = useState<any>(null);

  const [isHoveredDelete, setIsHoveredDelete] = useState<number | null>(null);
  const [initialData, setInitialData] = useState<IUploadFile | null>(null);
  const [isHoveredEye, setIsHoveredEye] = useState<number | null>(null);

  const columns = [
    {
      header: "NOMBRE ARCHIVO",
      field: "fileName",
    },
    {
      header: "PANTALLAS",
      field: "URL",
      render: (row: any) => {
        const screensData = row.screens ? row.screens.map((screen: any) => screen.name).join(", ") : [];
        return <div>{screensData ? screensData : <div>No se encontraron pantallas</div>}</div>
      }
    },
    {
      header: "TIPO",
      field: "originType",
      render: (row:any) => (<div>{OriginTypeEnum[row.originType]}</div>)
    },
    {
      header: "ORIENTACION",
      field: "orientation",
      render: (row:any) => (<div>{OrientationEnum[row.orientation]}</div>)

    },
    {
      header: "OPCIONES",
      field: "",
      render: (row: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignContent: "center", gap: '5px' }} >
            <button
              className={styleDataTable.buttom_option}
              onMouseEnter={() => { setIsHoveredEye(row.id); }}
              onClick={() => handleOpenPreview(row)}
              onMouseLeave={() => { setIsHoveredEye(null); }}
            >
              <AppGetIcon
                name="eye"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredEye === row.id ? "#FFF" : ""}
              />
              Ver
            </button>

            <button
              className={styleDataTable.buttom_option}
              onClick={() => { setIsOpenCurrentDelete(row); }}
              onMouseEnter={() => { setIsHoveredDelete(row?.id); }}
              onMouseLeave={() => { setIsHoveredDelete(null); }}
            >
              <AppGetIcon
                name="trash"
                classIcon={styleDataTable.sizeIcon}
                strokeColor="#962330"
                hoverColor={isHoveredDelete === row?.id ? "#FFF" : ""}
              />
              Eliminar
            </button>
          </div>
        );
      },
    },
  ];
  const reservationLimitSchema = yup.object().shape({
    file: !initialData?.id ? yup.string().required('El archivo es obligatorio.') : yup.string().notRequired(),
    orientation: yup.string().required("Orientación es obligatoria."),
    type: yup.string().required("Tipo de publicidad es obligatorio."),
    time: yup.number().required('Tiempo es obligatorio.'),
  });
  const handleOpenPreview = (dataFile: any) => {
    setIsOpenPreview(dataFile);
  };
  
  const handleCloseModalPreview = () => {
    setIsOpenPreview(null);
  };

  const handleSubmitCreateOrUpdateUplaodFile = async (values: any, filedData?: any) => {
    setLoading(true);
    let dataCreated: any = {
      id: values.id ?? null,
      file: filedData,
      orientation: values.orientation,
      time: values.time,
      url: values.url
    };
    try {
      const response = await createOrUpdateUplaodFileService.run(dataCreated);
      if (filedData) {
        setUploadedFileData({ uploadedFileId: response.id, uploadedFileUrl: response.url });
      };
      setLoading(false);
      return { uploadedFileId: response.id, uploadedFileUrl: response.url }
    } catch (error) {
      setLoading(false);
      console.error("Error guardando el recurso", error);
    }
  };
  const handleSubmit = async (values: IUploadFile, actions: any) => {
    if (actions.errors && Object.keys(actions.errors).length !== 0) {
      actions.setSubmitting(false);
      return;
    }

    let dataCreated: any = {
      id: uploadedFileData.uploadedFileId ? uploadedFileData.uploadedFileId : initialData?.id,
      file: null,
      orientation: values.orientation,
      time: values.time,
      url: uploadedFileData.uploadedFileUrl ? uploadedFileData.uploadedFileUrl : initialData?.url
    };
    try {
      await createOrUpdateUplaodFileService.run(dataCreated);
      setInitialData(null);
      setIsReloadData(!isReloadData);
      handleCloseModalAdvertising();
    } catch (error) {
      console.error("Error guardando el recurso", error);
    };
  };


  const handleCloseModalAdvertising = () => {
    setInitialData(null);
    setUploadedFileData({});
    setIsOpenModalAddAdvertising(false);
    setShowPreview(true);
  };


  const handleDelete = async () => {
    let params: any = {
      fileId: isOpenCurrentDelete?.id || uploadedFileData.uploadedFileId
    };
    if (isOpenCurrentDelete?.id || uploadedFileData?.uploadedFileId) {
      setUploadedFileData({});
      setLoading(true);
      try {
        await deleteFileService.run(params);
        setIsReloadData(!isReloadData);
        setIsOpenCurrentDelete(null);
        setLoading(false);
      } catch (error) {
        console.error("Error al eliminar el archivo:", error);
        setLoading(false);
      };
    };
  };
  const handleCloseModalDelete = () => {
    setInitialData(null);
    setIsOpenCurrentDelete(null);
  };


  useEffect(() => {
    setIsReloadData(!isReloadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, originTypeId]);

  return (
    <div className={style.containerQr}>
      <DataTable
        title="Archivos multimedia"
        columns={columns}
        service={getFilesWithPaginationService}
        params={{
          type: typeId.value,
          originType: originTypeId.value,
        }}
        reloadData={isReloadData}
        addButton={() => setIsOpenModalAddAdvertising(true)}
      >
        <ConatinerFilter
          nameIcon="filter"
          isOpen={showFilterModal}
          onClick={() => setShowFilterModal(true)}
          onClose={() => setShowFilterModal(false)}
        >
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="type"
              classNameInput={styleForm.input__input}
              textLabel="Tipo de publicidad"
              data={typeFilters}
              onSelect={(selected) => {
                setTypeId(selected);
              }}
              selected={typeId}
            />
          </div>
          <div
            className={style.container__select}
            style={{ minWidth: "280px" }}
          >
            <InputSearch
              key={Math.random()}
              name="originType"
              classNameInput={styleForm.input__input}
              textLabel="Pantalla destino"
              data={originTypeFilter}
              onSelect={(selected) => {
                setOriginTypeId(selected);
              }}
              selected={originTypeId}
            />
          </div>

        </ConatinerFilter>
      </DataTable>

      <Modal
        title={initialData?.id ? 'Editar archivo multimedia' : `Crear archivo multimedia`}
        isOpen={isOpenModalAddAdvertising}
        onClose={handleCloseModalAdvertising}
        size={{ width: '800px', height: '' }}
        loadingModal={loading}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalAdvertising}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={styleForm.buttomFooter}
              form="multimediaFilesForm"
              disabled={loading}
            >
              Guardar
            </button>
          </div>
        }
      >
        <Formik
          key={initialData?.id ?? "new"}
          initialValues={{
            id: initialData?.id ?? null,
            file: initialData?.file ?? '',
            url: initialData?.url ?? '',
            orientation: initialData?.orientation ?? '',
            rotatingScreenId: initialData?.rotatingScreenId ?? '',
            time: initialData?.time ?? null,
            type: initialData?.type ?? null,
          }}
          validationSchema={reservationLimitSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, values, setFieldValue, touched, validateField, setFieldError, setFieldTouched, validateForm }) => (
            <Form id="multimediaFilesForm" className="flex-row" style={{ width: '100%' }}>
              <div className={styleForm.containerChecks}>
                <label className={styleForm.title__label}>Tipo de Publicidad</label>
                <div className={styleForm.contentCheck}>
                  <div className={styleForm.check}>
                    {/* Checkbox para "Video" */}
                    <Field name="type">
                      {({ field }: any) => (
                        <>
                          <input
                            {...field}
                            id="video"
                            type="checkbox"
                            checked={values.type === "video"}
                            onChange={() => {
                              setFieldValue("type", "video");
                              setFieldValue("file", '');
                              handleDelete();
                            }}
                          />
                          <label htmlFor="video" className={style.title}>Video</label>
                        </>
                      )}
                    </Field>
                  </div>
                  <div className={styleForm.check}>
                    <Field name="type">
                      {({ field }: any) => (
                        <>
                          <input
                            {...field}
                            id="image"
                            type="checkbox"
                            checked={values.type === "image"}
                            onChange={() => {
                              setFieldValue("type", "image");
                              setFieldValue("file", '');
                              handleDelete();
                            }}
                          />
                          <label htmlFor="image" className={style.title}>Imagen</label>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                {touched.type && errors.type && (
                  <div className={styleForm.messageError}>{errors.type}</div>
                )}
              </div>
              <div className={styleForm.containerChecks}>
                <label className={styleForm.title__label}>Orientación</label>
                <div className={styleForm.contentCheck}>
                  <div className={styleForm.check}>
                    {/* Checkbox para "Dividida" */}
                    <Field name="orientation">
                      {({ field }: any) => (
                        <>
                          <input
                            {...field}
                            id="divided"
                            type="checkbox"
                            checked={values.orientation === "divided"}
                            onChange={() => {
                              setFieldValue("orientation", "divided");
                            }}
                          />
                          <label htmlFor="divided" className={style.title}>Dividida</label>
                        </>
                      )}
                    </Field>
                  </div>
                  <div className={styleForm.check}>
                    {/* Checkbox para "Completa" */}
                    <Field name="orientation">
                      {({ field }: any) => (
                        <>
                          <input
                            {...field}
                            id="complete"
                            type="checkbox"
                            checked={values.orientation === "complete"}
                            onChange={() => {
                              setFieldValue("orientation", "complete");
                            }}
                          />
                          <label htmlFor="complete" className={style.title}>Completa</label>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                {touched.orientation && errors.orientation && (
                  <div className={styleForm.messageError}>{errors.orientation}</div>
                )}
              </div>
              <div className={styleForm.form__container_row}>
                <Field name="time">
                  {() => (
                    <InputSearch
                      key={Math.random()}
                      name="time"
                      classNameInput={styleForm.input__input}
                      placeholder="Tiempo (seg.)"
                      textLabel="Tiempo (seg.)"
                      data={TimePerPageOptions} // Opciones para el tiempo
                      onSelect={(selected) => {
                        setFieldValue("time", selected.value);  // Actualiza el campo 'time'
                        setTime({ label: selected.label, value: selected.value });  // Actualiza el estado de 'time'
                        setFieldTouched("time", true, false);  // Marca el campo como tocado
                        // Validar solo el campo 'time'
                        validateField("time").then(() => {
                          if (selected?.value) {
                            setFieldError("time", ""); // Limpia el error manualmente si hay un valor
                          }
                        });
                      }}
                      errorMessage={touched.time && errors.time ? errors.time : ""}
                      selected={time}  // Valor actual de 'time'
                    />
                  )}
                </Field>
                <div className={styleForm.row__input} style={{ padding: '5px 0', justifyContent: 'flex-start', marginBottom: '20px' }} >

                  <label className={styleForm.title__label} style={{ paddingBottom: '5px ' }}>
                    {(values.type === "image" ? "Sube una imagen - *PNG, *JPG, *GIF" : "Sube un video - *MP4, *AVI, *MKV")}
                  </label>
                  <Field name="file">
                    {() => {
                      return (
                        <InputTypeFile
                          classNameInput={styleForm.input__input}
                          type={values.type === "video" ? "video" : "image"}
                          selected={values.file}
                          onChange={async (file, fileInputRef) => {

                            if (file) {
                              setFieldValue("file", file);  // Asignar el archivo seleccionado al campo 'file'
                              setFieldError("file", "");  // Limpiar el error manualmente si el archivo es válido
                              handleSubmitCreateOrUpdateUplaodFile(values, file);  // Llamar a la función de guardado con los valores actuales
                            } else {
                              // Validar todo el formulario
                              const formErrors = await validateForm();
                              // Si el formulario tiene errores, detener la ejecución
                              if (Object.keys(formErrors).length > 0) {
                                console.error("Errores en el formulario: ", formErrors);
                                // Marcar todos los campos con errores como `touched`
                                Object.keys(formErrors).forEach((field) => {
                                  setFieldTouched(field, true, false);
                                });
                                // Reiniciar el campo de archivo si es necesario
                                if (fileInputRef && fileInputRef.current) {
                                  fileInputRef.current.value = "";  // Reiniciar el input de archivo
                                }
                                setFieldValue("file", '');
                                return; // No continuar si hay errores
                              }
                            }
                          }}
                          // Asignar el mensaje de error si existe
                          errorMessage={typeof errors.file === "string" ? errors.file : undefined}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>

              {uploadedFileData?.uploadedFileUrl && (
                <button
                  type="button"
                  style={{ marginTop: '1rem' }}
                  className={styleForm.buttomFooter}
                  onClick={() => setShowPreview(!showPreview)}  // Cambia entre previsualizar y ocultar
                >
                  {showPreview ? "Dejar de previsualizar" : "Previsualizar archivo"}  {/* Cambia el texto del botón */}
                </button>
              )}
              {/* Muestra el iframe de previsualización si showPreview es true */}
              {showPreview && uploadedFileData?.uploadedFileUrl && (
                <div className={style.previewContainer}>
                  <h4 className={style.under__line}>Previsualización del archivo</h4>
                  {values?.type === 'video' ? (
                    <video
                      src={uploadedFileData?.uploadedFileUrl}
                      width="100%"
                      height="auto"
                      controls
                      style={{
                        display: "block",
                        border: "1px solid #ccc",
                        marginTop: "20px"
                      }}
                    />
                  ) : (
                    <img
                      src={uploadedFileData?.uploadedFileUrl}
                      alt="Previsualización del archivo"
                      width="100%"
                      style={{
                        display: "block",
                        border: "1px solid #ccc",
                        marginTop: "20px"
                      }}
                    />
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        title={`Confirmación de eliminación`}
        isOpen={isOpenCurrentDelete}
        onClose={handleCloseModalDelete}
        size={{ width: "600px", height: "" }}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={styleForm.buttomFooter}
              onClick={handleDelete}
              disabled={loading}
            >
              Si, Eliminar
            </button>
          </div>
        }
      >
        <div style={{ padding: "30px" }}>
          <center>
            {`¿Estás seguro de que deseas eliminar el archivo multimedia ${isOpenCurrentDelete?.fileName}? Esta acción no se puede deshacer.`}
          </center>
        </div>
      </Modal>
      <Modal
        title={`Previsualización del archivo`}
        isOpen={isOpenPreview}
        onClose={handleCloseModalPreview}
        size={{ width: "800px", height: "" }}
        footerContent={
          <div className={style.containerButtoms}>
            <button
              className={styleForm.buttomFooter + ' ' + styleForm.buttom_cancelar}
              onClick={handleCloseModalPreview}
            >
              Cancelar
            </button>

          </div>
        }
      >
        <div className={style.previewContainer}>
          {isOpenPreview?.type === 'video' ? (
            <video
              src={isOpenPreview?.url}
              width="100%"
              height="auto"
              controls  // Muestra los controles de reproducción
              style={{
                display: loading ? "none" : "block",
                border: "1px solid #ccc",
                marginTop: "20px"
              }}
            />
          ) : (
            <img
              src={isOpenPreview?.url}
              alt="Previsualización del archivo"
              width="100%"
              height="auto"
              style={{
                display: loading ? "none" : "block",
                border: "1px solid #ccc",
                marginTop: "20px"
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
