import { useNavigate } from "react-router";
import './notAuthorized.css'

import styleForm from "../../common/theme/styles/form.module.css";

import AppGetIcon from "../../common/UI/AppGetIcon";
export default function Unauthorized() {

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="notAuthorized">
      <div className="card">
        <div className="messageAuthorized">
          <AppGetIcon name="error" classIcon="messageAuthorized-icon" />
          <div className="title" >Usuario no autorizado</div>
          <button className=  {styleForm.buttomFooter} onClick={() => handleBackClick()}>Volver atras</button>
        </div>
      </div>
    </div>
  )
}


