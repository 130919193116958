import { CreateOrUpdateBaseService } from "../../../../common/services/createOrUpdateBase.service";
import { services } from "../../../../constants/services";


export interface IResource {
  id: number | null | string;
  roomId: string;
  roomCode:string;
  roomDescription:string;
  buildingId:string;
  buildingDescription:string;
  campusId:string;
  campusDescription:string;
  capacity:number;
  floorId: number | null;
  floorCode:number | null;
  floorDescription: string | null;
  nm_high:number;
  nm_long: number | null;
  nm_width:number;
  createdAt:string | null;
  updatedAt:string | null;
  status:number;
};

export class CreateOrUpdateResourceService extends CreateOrUpdateBaseService<IResource, any> {
  url = `${services.resources}/createOrUpdateResource`;
};