import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { IParamsReservationsLimit, ResponseQ } from "../../../../common/dtos/paginate.dto";

export class GetCampusReservationLimitWithPaginationService {
  async run(params: IParamsReservationsLimit) {
    const response = await getByQuery<ResponseQ<any>>(`${services.campusReservationLimit}/getCampusReservationLimit`, params);
    return response;
  };
}

