import React, { useEffect, useRef, useState } from "react";
import style from '../inputs.module.css';

interface InputTypeFileProps {
  type?: "image" | "video";
  classNameInput?: string;
  selected?: any;
  onChange?: (file: File | null, ref: React.RefObject<HTMLInputElement>) => void;
  onSelect?: (file: { label: string; value: File, duration?: number }) => void; // Añadido duration
  errorMessage?: string;
  disabled?: boolean;
}

const InputTypeFile: React.FC<InputTypeFileProps> = ({ type = "image", classNameInput, onChange, onSelect, selected, errorMessage, disabled = false }) => {
  const [error, setError] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const allowedFileTypes =
    type === "image"
      ? "image/png, image/jpeg, image/gif"
      : "video/mp4, video/avi, video/mkv";

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) {
        setError("El archivo no debe exceder los 50MB.");
        if (onChange) {
          onChange(null, fileInputRef);
        }
        return;
      }

      setError("");

      if (type === "video") {
        // Crear un objeto video temporal para obtener la duración
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src); // Liberar memoria
          const duration = Math.round(video.duration); // Obtener la duración en segundos
          console.log("durationduration", duration);
          
          // Llamar a onSelect con el archivo y su duración
          if (onSelect) {
            onSelect({ label: file.name, value: file, duration });
          }
        };

        video.src = URL.createObjectURL(file); // Asignar el archivo al video
      } else {
        // Si es una imagen, solo llamamos a onSelect
        if (onSelect) {
          onSelect({ label: file.name, value: file });
        }
      }

      if (onChange) {
        onChange(file, fileInputRef);
      }
    }
  };

  useEffect(() => {
    if (!selected) {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <div className={`${classNameInput}`} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <input
          ref={fileInputRef}
          style={{ cursor: 'pointer' }}
          name="file-upload"
          type="file"
          accept={allowedFileTypes}
          placeholder="Sin archivos"
          className="classNameInput"
          onChange={handleFileChange}
          disabled={disabled}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'pointer' }}>
        {error && <p className={style.messageError}>{error}</p>}
        {errorMessage && <p className={style.messageError}>{errorMessage}</p>}
      </div>
    </>
  );
};

export default InputTypeFile;
