import { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'; // Importa ReactDOM
import style from './appInputSearchFilter.module.css';
import { DataSelectDto, InputComponentProps } from "../../../dtos/general.dto";
import useDebounce from "../../../hooks/useDebounce";
import AppGetIcon from "../../AppGetIcon";

const SELECT_ALL_VALUE = "ALL";

const AppInputSearchFilter: React.FC<InputComponentProps> = ({
  name = '', classNameInput, textLabel, serviceCall, onSelect, selected, addString, multiple = false, disabled, uploadExcel, labelExcel, showSelectAll = false, data = []
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState<DataSelectDto[]>(data);
  const [selectedValues, setSelectedValues] = useState<DataSelectDto[]>(Array.isArray(selected) ? selected : []);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!multiple && selected) {
      setInputValue(selected.label);
    } else if (multiple && Array.isArray(selected)) {
      setSelectedValues(selected);
    }
  }, [selected, multiple]);

  useEffect(() => {
    if (isExpanded) {
      fetchSearchData(debouncedInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  const fetchInitialData = () => {
    if (serviceCall) {
      setIsLoading(true);
      serviceCall('')
        .then((data: any) => {
          setSelectValue(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setSelectValue(data);
    }
    setIsExpanded(true);
  };

  const fetchSearchData = (value: string) => {
    if (serviceCall) {
      setIsLoading(true);
      serviceCall(value)
        .then((data: any) => {
          setSelectValue(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      const filteredData = data.filter((item: any) => item.label.toLowerCase().includes(value.toLowerCase()));
      setSelectValue(filteredData);
    }
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (selected: DataSelectDto) => {
    let updatedValues: DataSelectDto[] = [];

    if (multiple) {
      const isSelected = selectedValues.some(item => item.value === selected.value);

      if (selected.value === SELECT_ALL_VALUE) {
        // Si ya están todos seleccionados, deseleccionarlos
        const allSelected = selectedValues.length === selectValue.length;
        updatedValues = allSelected ? [] : selectValue.map(item => ({ label: item.label, value: item.value }));
      } else {
        // Agregar o quitar elementos seleccionados
        updatedValues = isSelected
          ? selectedValues.filter(item => item.value !== selected.value)
          : [...selectedValues, selected];
      }

      setSelectedValues(updatedValues);
      setInputValue('');

      // Actualizar onSelect en la selección múltiple
      if (onSelect) onSelect(updatedValues);
    } else {
      setInputValue(selected.label);
      if (onSelect) onSelect(selected);
      setIsExpanded(false);
    }

    if (!selected.value && inputRef.current) {
      inputRef.current.focus();
    }

    calculatePosition(); // Recalcular la posición después de seleccionar una opción
  };


  const handleRemoveChip = (removedValue: string) => {
    // Filtrar el valor que se eliminó
    const newSelectedValues = selectedValues.filter(item => item.value !== removedValue);

    // Actualizar el estado de los valores seleccionados
    setSelectedValues(newSelectedValues);

    // Si no quedan más elementos seleccionados, limpiar el input
    if (newSelectedValues.length === 0) {
      setInputValue(''); // Limpiar el valor del input si no hay elementos seleccionados
    }

    // Si tienes una función `onSelect` que se debe llamar después de quitar una opción, actualízala también
    if (onSelect) onSelect(newSelectedValues);

    // Recalcular la posición de la lista de selección
    calculatePosition();
  };


  const changeIsExpandFalse = () => {
    setIsExpanded(false);
  };

  const toggleExpansion = () => {
    if (!isExpanded) {
      fetchInitialData();
    } else {
      setIsExpanded(false);
    }
  };

  const handleIconClick = () => {
    // Solo limpia el valor del input, sin alterar los valores seleccionados
    setInputValue('');
    setIsExpanded(true);

    // Mantiene el foco en el input después de limpiarlo
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadExcel && event.target.files) {
      uploadExcel(event.target.files[0], selectedValues).then((data: any) => {
        setSelectedValues(data);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';  // Restablecer el valor del input de archivo
        }
      });
    }
  };

  const calculatePosition = () => {
    if (isExpanded && inputRef.current && selectRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const gap = 4; // Espacio entre el input y el select
      const viewportHeight = window.innerHeight;

      // Comprobar si estamos en un modal
      const modal = inputRef.current.closest('#Modal');
      let top = inputRect.bottom + gap;
      let positionUpwards = false;

      // Si el dropdown se sale de la pantalla, cambiar la posición hacia arriba
      if (top + selectRect.height > viewportHeight) {
        top = inputRect.top - selectRect.height - gap;
        positionUpwards = true;
      }

      // Cálculo de la altura adicional por los chips seleccionados
      let chipsHeight = 0;
      const chipsContainer = document.querySelector(`.${style.chipContainer}`); // El contenedor de los chips
      if (multiple && chipsContainer) {
        chipsHeight = chipsContainer.getBoundingClientRect().height;
      }

      // Si estamos dentro de un modal
      if (modal) {
        const modalRect = modal.getBoundingClientRect();
        top = positionUpwards
          ? inputRect.top - selectRect.height - modalRect.top - gap
          : inputRect.bottom - modalRect.top + gap;

        const left = inputRect.left - modalRect.left;

        // Ajustar los estilos del dropdown
        selectRef.current.style.position = 'fixed';
        selectRef.current.style.top = `${top + chipsHeight}px`; // Ajustar por la altura de los chips
        selectRef.current.style.left = `${left}px`;
        selectRef.current.style.width = `${inputRect.width}px`;
        selectRef.current.style.zIndex = '1000'; // Asegurar que esté por encima del modal
      } else {
        // Si no estamos en un modal, calcular la posición normal
        top = positionUpwards
          ? inputRect.top - selectRect.height - gap
          : inputRect.bottom + gap + chipsHeight;

        // Ajustar los estilos del dropdown en la página principal
        selectRef.current.style.position = 'absolute';
        selectRef.current.style.top = `${top}px`; // Ajustar por la altura de los chips
        selectRef.current.style.left = `${inputRect.left}px`;
        selectRef.current.style.width = `${inputRect.width}px`;
        selectRef.current.style.zIndex = '1000';
      }
    }
  };


  useEffect(() => {
    const handleOutsideClickOrScroll = (event: Event) => {
      if (
        selectRef.current && !selectRef.current.contains(event.target as Node) &&
        inputRef.current && !inputRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      calculatePosition(); // Asegura que la posición se calcule al expandir
      document.addEventListener("mousedown", handleOutsideClickOrScroll);
      document.addEventListener("scroll", handleOutsideClickOrScroll, true); // Captura el evento de scroll
      window.addEventListener("resize", calculatePosition, true);
    } else {
      document.removeEventListener("mousedown", handleOutsideClickOrScroll);
      document.removeEventListener("scroll", handleOutsideClickOrScroll, true);
      window.removeEventListener("resize", calculatePosition, true);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickOrScroll);
      document.removeEventListener("scroll", handleOutsideClickOrScroll, true);
      window.removeEventListener("resize", calculatePosition, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, selectedValues]);


  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    window.addEventListener('scroll', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
      window.removeEventListener('scroll', calculatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Renderizar el dropdown usando ReactDOM.createPortal
  const renderDropdown = () => {
    if (isExpanded) {
      return ReactDOM.createPortal(
        <div ref={selectRef} className={`${style.select} ${isExpanded ? style.expanded : ""}`}>
          {isExpanded && (
            isLoading ? <div className={style.loading}>Cargando...</div> : <div className={style.options}>
              {showSelectAll && selectValue.length > 0 && (
                <div
                  className={`${style.option} ${style.select_all} ${selectedValues.some(selected => selected.value === SELECT_ALL_VALUE) ? style.selected : ''}`}
                  onClick={() => handleOptionClick({ label: "Seleccionar todos", value: SELECT_ALL_VALUE })}
                >
                  Seleccionar todos
                </div>
              )}

              {selectValue.length > 0 ? (
                selectValue.map((item: DataSelectDto, index: number) => (
                  <div key={index} className={`${style.option} ${selectedValues.some(selected => selected.value === item.value) ? style.selected : ''}`} onClick={() => handleOptionClick(item)}>
                    {item.label}
                  </div>
                ))
              ) : (
                <div className={style.void_items}>
                  <strong>No hay elementos disponibles</strong>

                </div>
              )}
            </div>
          )}
        </div>,
        document.body // Renderiza el dropdown en el body
      );
    }
    return null;
  };
  return (
    <>
      {isExpanded && <div className={style.overlay} onClick={() => changeIsExpandFalse()}></div>}
      <div className={style.container__input}>
        {name && (
          <label htmlFor={name} className={style.input__label}>
            {textLabel}
            {uploadExcel && (
              <>
                <span
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {labelExcel}
                  <AppGetIcon name="upload" strokeColor="#5B5F62" />
                </span>
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  accept=".xlsx,.xls"
                />
              </>
            )}
          </label>
        )}
        <div className={`${style.app_input_container} ${multiple ? style.multiple : ''} ${disabled ? style.disabled : ''}`} style={{ border: ' 1px solid #8a8c8d', borderRadius: '8px' }}>
          <div style={{ maxHeight: '200px', flexWrap: 'wrap', display: 'flex', overflow: 'auto' }}>
            {multiple && selectedValues.map((item, index) => (
              <div key={index} className={style.chip}>
                {item.label}
                <span onClick={() => handleRemoveChip(item.value)} className={style.chipClose}>&times;</span>
              </div>
            ))}
          </div>
          <input
            ref={inputRef}
            type="text"
            autoComplete="off"
            className={classNameInput ? classNameInput : style.input__input}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={selectedValues.length > 0 ? 'Buscar...' : 'Seleccionar opción'}
            style={{ paddingRight: '10px', border: 'none' }}
            onClick={toggleExpansion}
            disabled={disabled}
          />

          <div
            onMouseEnter={() => setIsHoveredTrash(true)}
            onMouseLeave={() => setIsHoveredTrash(false)}
            style={{ position: 'absolute', right: '0px', bottom: '-15px', transform: 'translateY(-50%)', zIndex: 1 }}
          >
            <AppGetIcon
              name={inputValue?.length > 0 ? 'trash' : "arrow-down"}
              className={style.selectIcon}
              onClick={inputValue?.length > 0 ? handleIconClick : toggleExpansion}
              strokeColor="#5A5F65"
              hoverColor={inputValue?.length > 0 && isHoveredTrash ? "#D43C3C" : undefined}
            />
          </div>
          {renderDropdown()} {/* Llama a la función para renderizar el dropdown */}

        </div>
        <div className={style.messageError}>
          {''}
        </div>
      </div>
    </>
  );
};

export default AppInputSearchFilter;
