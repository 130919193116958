import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from "zustand/middleware";
import { createJSONStorage } from 'zustand/middleware';

// Función para crear una cookie
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; domain=.cyk.com.co; SameSite=None; Secure`;
};

type HeaderState = {
  module: { name: string; url: string };
  moduleItem: { name: string; url: string };
  itemChildren: { name: string; url: string };
  actionFilter: boolean;
  qrSession: boolean;
  setModule: (name: string, url: string) => void;
  setModuleItem: (name: string, url: string, domainUrl?: string) => void;
  setItemChildren: (name: string, url: string) => void;
  setActionFilter: (actionFilter: boolean) => void;
  setQrSession: (action: boolean) => void;
  clearHeader: () => void;
};

// Crear un almacenamiento personalizado
const storage = createJSONStorage(() => localStorage);

export const useHeaderStore = createWithEqualityFn<HeaderState>()(
  persist(
    (set) => {
      return ({
        module: { name: '', url: '' },
        moduleItem: { name: '', url: '' },
        itemChildren: { name: '', url: '' },
        actionFilter: false,
        qrSession: false,
        setModule: (name: string, url: string) => set({ module: { name, url } }),
        setModuleItem: ( name: string, url: string, domainUrl?: string) => {
          // Aquí puedes guardar la cookie cuando se actualiza el moduleItem
          const maxAge = 12 * 60 * 60; // 12 horas en segundos
          setCookie('selectedModuleItem', JSON.stringify({ name, url, domainUrl }), maxAge); // La cookie dura 12 horas en segundos
          set({ moduleItem: { name, url } });
        },
        setItemChildren: (name: string, url: string) => set({ itemChildren: { name, url } }),
        setActionFilter: (actionFilter: boolean) => set({ actionFilter }),
        setQrSession: (action: boolean) => set({ qrSession: action }),
        clearHeader: () => set({
          module: { name: '', url: '' },
          moduleItem: { name: '', url: '' },
          itemChildren: { name: '', url: '' },
          actionFilter: false,
          qrSession: false,
        })
      });
    },
    {
      name: 'header-storage',
      storage
    }
  )
);
