import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { IParamsReservationsLimit } from "../../../../common/dtos/paginate.dto";

export class GetRotationScreenViewWithPaginationService {
  async run(params: IParamsReservationsLimit) {
    const response = await getByQuery<any>(`${services.rotationScreen}/getFullScreens`, params);
    return response;
  };
}

