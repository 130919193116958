import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from "zustand/middleware";
import { createJSONStorage } from 'zustand/middleware';
import { ItemDto } from '../common/dtos/initData.dto';

type ItemsState = {
  items: ItemDto[];
  setItems: (items: ItemDto[]) => void;
  clearItems: () => void;
};

// Crear un almacenamiento personalizado
const storage = createJSONStorage(() => localStorage);

export const useItemsStore = createWithEqualityFn<ItemsState>()(
  persist(
    (set) => ({
      items: [], // Inicialización vacía del estado de items
      setItems: (items: ItemDto[]) => set({ items }), // Función para establecer items
      clearItems: () => set({ items: [] }) // Función para limpiar items
    }),
    {
      name: 'items-storage', // Nombre de almacenamiento en localStorage
      storage, // Uso del almacenamiento personalizado
    }
  )
);
