import React from 'react';
import style from './modal.module.css';
import AppGetIcon from '../AppGetIcon';
import ProgressBar from '../ProgressBar';

interface ModalProps {
  title: string;
  isOpen: boolean;
  size?: {
    width: string;
    maxWidth?: string;
    height: string;
    minHeight?: string;
  };
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  loadingModal?: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, isOpen, size, onClose, children, footerContent, loadingModal }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className={style.Modal_overlay}></div>
      <div id='Modal' className={style.Modal} style={{ width: size?.width, maxWidth: size?.maxWidth, height: size?.height, minHeight: size?.minHeight }}>
        <div className={style.Title_modal}>
          <div>{title}</div>
          <button className={style.Modal_close} onClick={onClose}>
            <AppGetIcon name='close' classIcon={style.WidthIconClose} strokeColor='#FFFFFF' />
          </button>
        </div>
        <div className={style.Modal__content}>
          {
            loadingModal && <ProgressBar background={''} zIndex="10000" />
          }
          <div style={{ padding: '10px' }}>
            {children}
          </div>

        </div>
        {footerContent && <div className={style.Modal__footer}>{footerContent}</div>}
      </div>
    </>
  );
};

export default Modal;
