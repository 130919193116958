import { services } from "../../../../constants/services";
import { getByQuery } from "../../../../utils/api";
import { IParamsReservationsLimit, ResponseQ } from "../../../../common/dtos/paginate.dto";

export class GetGeneralReservationLimitWithPaginationService {
  async run(params: IParamsReservationsLimit) {
    const response = await getByQuery<ResponseQ<any>>(`${services.generalReservationLimit}/getGeneralReservationLimit`, params);
    return response;
  };
}

