const setCSSVariables = (theme: any) => {
  // Asignar colores
  for (const color in theme.palette) {
    for (const shade in theme.palette[color]) {
      document.documentElement.style.setProperty(`--color-${color}-${shade}`, theme.palette[color][shade]);
    }
  }

  // Asignar tipografía
  for (const prop in theme.typography) {
    document.documentElement.style.setProperty(`--typography-${prop}`, theme.typography[prop]);
  }

  // Asignar transiciones
  if (theme.transitions) {
    // Asumiendo que transitions es un objeto de estilos
    for (const key in theme.transitions) {
      document.documentElement.style.setProperty(
        `--transitions-${key}`, theme.transitions[key]
      );
    }
  }

  // Asignar sombras (shadows)
  if (theme.shadows) {
    // Si shadows es un array o un objeto
    theme.shadows.forEach((value: any, index: number) => {
      document.documentElement.style.setProperty(
        `--shadows-${index}`, value
      );
    });
  }
  // Asignar otras propiedades del tema...
};

export default setCSSVariables;